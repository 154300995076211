import { salarySacraficeOptions } from '../state/calculatorState';
import moment from 'moment'


export const getPixelRatio = context => {
    const devicePixelRatio = window.devicePixelRatio || 1;
    const backingStoreRatio =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;

    const ratio = (devicePixelRatio / backingStoreRatio) || 1;
    const scaled = (devicePixelRatio !== backingStoreRatio)

    return { ratio, scaled }
};

export const resizeCanvas = (canvas, context, minHeight) => {

    const { ratio, scaled } = getPixelRatio(context);

    canvas.style.width = '100%';
    canvas.style.height = '100%';

    let w = getComputedStyle(canvas)
        .getPropertyValue('width')
        .slice(0, -2);
    let h = getComputedStyle(canvas)
        .getPropertyValue('height')
        .slice(0, -2);

    w = Number(w);
    h = Number(h);

    if (minHeight) {
        // min  height
        h = Math.max(minHeight, h);
    }

    if (scaled) {
        // set the 'real' canvas size to the higher width/height
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        // ...then scale it back down with CSS
        canvas.style.width = w + 'px';
        canvas.style.height = h + 'px';
    }
    else {
        // this is a normal 1:1 device; just scale it simply
        canvas.width = w;
        canvas.height = h;
        canvas.style.width = '';
        canvas.style.height = '';
    }

    return { ratio, scaled, width: w, height: h }
}


export const formatMoney = (number, decPlaces, decSep, thouSep) => {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    let sign = number < 0 ? "-" : "";
    let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    let j = (i.length > 3) ? i.length - 3 : 0;

    let str = sign;
    str += (j ? i.substr(0, j) + thouSep : "")
    str += i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep)
    str += (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");

    return str;
}


export const compileOptions = (data) => {
    const {
        notForProfit,
        includesSuperannuation,
        superannuationRate,
        adjustSuperannuationRate,
        adjustSuperannuation,
        additionalSuper,
        over65,
        adjustSalaryScaracfice,
        salaryScaracficeAmount,
        salarySacraficeOption,
        medicareExemption,
        medicareExemptionValue,
        medicareAdjustment,
        adjustDeductions,
        taxableDeductions,
        fringeBenefits,
        nonResident,
        haveTFN,
        backpacker,
        noTaxFree,
        HELP,
        SFSS,
        SAPTO,
        spouseSeparated,
        hasPrivateHealthcare,
        medicareSurcharge,
        dependants,
        dependantsCount,
        spouse,
        spouseIncome,
    } = data;

    // options text
    let options = []
    if (includesSuperannuation) options.push([`Includes Superannuation`]);
    if (adjustSuperannuation) {
        if (additionalSuper > 0)
            if (over65) options.push([`Extra Superannuation`, additionalSuper, "over 65"]);
            else options.push([`Extra Superannuation`, additionalSuper]);
    }
    if(adjustSuperannuationRate){
        options.push([`Super rate ${superannuationRate}%`]);
    }
    if (adjustSalaryScaracfice) {
        if (salaryScaracficeAmount > 0) options.push([`Salary Sacrifice Super`, salaryScaracficeAmount, " per " + salarySacraficeOptions[salarySacraficeOption]]);
    }
    if (adjustDeductions) {
        if (taxableDeductions > 0) options.push([`Tax deductions`, taxableDeductions]);
        if (fringeBenefits > 0) options.push([`Fringe benefits`, fringeBenefits]);
    }
    if (nonResident) {
        if (haveTFN) options.push([`Non-resident`]);
        else options.push([`Non-resident`, `no tax file number`]);
    }

    if (notForProfit) {
        options.push([`NFP`]);
    }

    if (backpacker) options.push([`Working holiday maker`]);
    if (!nonResident && !backpacker) {
        if (noTaxFree) options.push([`No Tax-free threshold`]);
        if (HELP) options.push([`Student Loan`]);
        if (SFSS) options.push([`SFSS`]);
        if (medicareExemption) options.push([`Medicare exemption`, (medicareExemptionValue === 1 ? "Full" : "Half")]);
        if (medicareAdjustment.a !== 0) options.push([`Medicare adjustment`, medicareAdjustment.a]);
    }
    if (dependants && dependantsCount > 0) {
        options.push([`Dependants`, dependantsCount]);
    }
    if (SAPTO) {
        if (spouseSeparated) options.push([`SAPTO`, 'Spouse separated due to illness']);
        else options.push([`SAPTO`, dependantsCount]);
    }
    if (spouse && spouseIncome === 0) options.push([`Spouse`]);
    if (spouse && spouseIncome > 0) options.push([`Spouse income`, spouseIncome]);
    if (medicareSurcharge > 0 && !hasPrivateHealthcare) options.push(['No private healthcare']);

    return options;
}



export const compileNotes = (calculator) => {
    const notes = []
    const { includesSuperannuation, income, notForProfit, listo, medicareSurcharge, adjustedTaxableIncome, lito, lamito, sapto, rebateIncome, warnings, payments, payOption } = calculator;


    // only warning if mobile && option is 2 or 3 ?
    if (warnings.extraPayment) {
        let note = `* Based on your pay day, there are ${payments.w} weekly or ${payments.f} fortnightly payments this year. `;
        switch (payOption) {
            case 0:
                note += `Given that your pay is annual, fortnightly and weekly amounts are reduced`;
                break;
            default:
                note += `You will pay more tax on your PAYG income if you are paid weekly of fortnightly.`;
                break;
        }

        notes.push(note);
    }

    if (notForProfit) notes.push('Employees of Not-For-Profit organisations are not liable for income tax.');


    if (includesSuperannuation) {
        notes.push(`Superannuation is paid by your employer into your fund in addition to your wage.`);
    } else {
        notes.push(`Superannuation is part of your wage (salary package) and paid into your Superannuation fund.`);
    }
    notes.push(`Your Adjusted Taxable Income is $${formatMoney(adjustedTaxableIncome, 0)}`);
    
    if (medicareSurcharge > 0) {
        notes.push(`You may be liable to a Medicare surcharge of $${formatMoney(medicareSurcharge, 0)} per year if you do not have suitable private health insurance. This is based on an adjusted taxable income of $${formatMoney(adjustedTaxableIncome, 0)}`);
    }

    if (sapto > 0) {
        notes.push(`You're entitled to a Senior Australian Pensioner Tax Offset (SAPTO) of up to $${formatMoney(sapto, 0)} based on a rebate income of $${formatMoney(rebateIncome, 0)}`)
    }

    if (lito > 0 && lamito > 0) {
        notes.push(`You're entitled to a Low Income Tax Offset (LITO) of up to $${formatMoney(lito, 0)} and a Low And Middle Income Tax Offset (LAMITO) of up to $${formatMoney(lamito, 0)}`)
    } else {
        if (lito > 0) {
            notes.push(`You're entitled to a Low Income Tax Offset (LITO) of up to $${formatMoney(lito, 0)}`)
        }
        if (lamito > 0) {
            notes.push(`You're entitled to a Low And Middle Income Tax Offset (LAMITO) of up to $${formatMoney(lamito, 0)}`)
        }
    }


    if (listo) {
        notes.push(`You're entitled to a Low Income Superannuation Tax Offset (LISTO). This reduces your tax liability on your superannuation by up to $${formatMoney(listo, 0)}`)
    }
    notes.push(`This calculator is an estimate.`);

    return notes;
}







export const roundToNearestCent = (value) => {
    return Math.round(value * 100) / 100;
}

export const roundToNearestDollar = (value) => {
    return Math.round(value * 1);
}


export const roundToNearestDollerPlus99 = (value) => {
    return Math.round(value * 1) + 0.99;
}

export const matchPath = (a, b) => {
    const a_ = a.split("/").join("");
    const b_ = b.split("/").join("");
    return a_ === b_;
}

// move payDate so that is is between start and End date, move by weeks
export const resetDate = (date, start, end,) => {

    let skipDays = 7;

    // move date forward
    while (date <= start) {
        date.setDate(date.getDate() + skipDays);
    }

    // move date back
    if (date > end) {
        while (date >= start) {
            date.setDate(date.getDate() - skipDays);
        }
        date.setDate(date.getDate() + skipDays);
    }
    return date;

}


export const calculatePayments = (payDay, year, payOption) => {
    const firstDay = new Date(`${Number(year) - 1}-07-01`);

    const lastDay = new Date(`${Number(year)}-07-01`)
    lastDay.setDate(lastDay.getDate());
    let today = new Date();
    // if the EOFY is in a year prior to today then use the EOFY ( previous years will be YTD complete)
    today = (today > lastDay) ? lastDay : today;

    // Rule: if this is not in the first month and is annual or montly pay options, then assume that this is a part year, otherwise, back date to start of year
    const backdate = payOption <= 1; // annually or monthly

    const weeks = churnDates(payDay, firstDay, lastDay, 7, false, backdate);
    const weeksYTD = churnDates(payDay, firstDay, today, 7, false, backdate);

    const fortnights = churnDates(payDay, firstDay, lastDay, 14, false, backdate);
    const fortnightsYTD = churnDates(payDay, firstDay, today, 14, false, backdate);

    const months = monthsBetweenDates(payDay, lastDay);
    const monthsYTD = churnDates(payDay, firstDay, today, 1, true, false);

    return { payments: { w: weeks, f: fortnights, m: months, a: 1 }, YTD: { w: weeksYTD, f: fortnightsYTD, m: monthsYTD, a: 1 } };
}


export const monthsBetweenDates = (a, b) => {
    let months = 0;
    months = (b.getFullYear() - a.getFullYear()) * 12;
    months -= a.getMonth();
    months += b.getMonth();
    return months <= 0 ? 0 : months;
}



export const churnDates = (date, firstDay, dateLimit, span, months, backdate) => {
    // step start date to be first of the year
    let startDate = new Date(date.getTime());
    if (startDate > dateLimit) {
        return 0;
    }

    if (backdate && startDate.getMonth() === firstDay.getMonth()) {
        // Hack to make weekly and fortnightly payments look right id monthly or annually is selected 
        while (startDate >= firstDay) {
            startDate.setDate(startDate.getDate() - span);
        }
        startDate.setDate(startDate.getDate() + span);
    }

    let pays = 0;
    while (startDate <= dateLimit) {
        pays++;
        if (months) {
            startDate.setMonth(startDate.getMonth() + span);
        } else {
            startDate.setDate(startDate.getDate() + span);
        }
    }

    return pays;
}


export const date2str = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}



export const formatDate = (date) => {
    const momentDate = date ? moment(date) : moment();
    return momentDate.format("dddd, MMMM Do YYYY");
}