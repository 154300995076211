export let NAV_HEIGHT_SMALL = 40;
export let NAV_HEIGHT = 82;
export let HEADER_HEIGHT = 500;
export let HEADER_MOBILE_HEIGHT = 600;
export const PANEL_HEADER_HEIGHT = 40;

export const MAX_WIDTH = 3000;

export const ZERO_VALUE = 0.000001;

export const EMAIL_ADDRESS = 'mailto:info@paycalculator.com.au';

export const OLD_CALCULATOR_LINK = '/classic';
export const CALCULATOR_LINK = '/';
export const TAX_INFO_LINK = '/tax-info';
export const STUDENT_LOAN_LINK = '/student-loan';
export const TAX_DISTRIBUTION_LINK = '/tax-distribution';
export const PRIVACY_POLICY_LINK = '/privacy';
export const ACCEPTABLE_USE_LINK = '/acceptable-use';

export const COOKIES_LINK = "/cookies";
export const SURVEY_LINK = "https://www.surveymonkey.com/r/TRCHNWC";


// reference links
export const HELP_REPAYMENT_SOURCE = 'https://www.ato.gov.au/Rates/HELP,-TSL-and-SFSS-repayment-thresholds-and-rates/';
export const HECS_HELP_INFO = 'https://www.studyassist.gov.au/sites/default/files/ed19-0226_hecs-help_booklet_acc2.pdf?v=1584566164';
export const FEE_HELP_INFO = 'https://www.studyassist.gov.au/help-loans/fee-help';
export const OS_HELP_INFO = 'https://www.studyassist.gov.au/help-loans/os-help-and-overseas-study';
export const SA_HELP_INFO = 'https://www.studyassist.gov.au/help-loans/sa-help';
export const SSL_INFO = 'https://www.servicesaustralia.gov.au/individuals/services/centrelink/student-start-loan';
export const TSL_INFO = 'https://austapprent.govcms.gov.au/sites/default/files/2019-06/AISS%20Final.pdf';
export const CPS_INFO = 'https://www.studyassist.gov.au/sites/studyassist/files/ed19-0005_-_he_-_2019_help_publications_-_handbook_acc.pdf?v=1554852826';
export const MYSKILLS_INFO = 'https://www.myskills.gov.au/';
export const VET_INFO = 'https://www.employment.gov.au/vet-student-loans';
export const APPRENTISHIP_INFO = 'https://www.australianapprenticeships.gov.au/aus-apprenticeships-incentives';
export const OVERSEAS_REPAYMENT_INFO = 'https://www.ato.gov.au/Individuals/Study-and-training-support-loans/Overseas-repayments/#OverseasTravelNotification';
export const WORLDWIDE_INCOME_INFO = 'https://www.ato.gov.au/Individuals/Study-and-training-support-loans/Overseas-repayments/#WhatdoIneedtolodge" title="report your worldwide income';
export const NON_LODGEMENT_INFO = 'https://www.ato.gov.au/Individuals/Study-and-training-support-loans/Overseas-repayments/#Assessmentmethodsfornonresidentforeignso';
export const RESIDENCY_INFO = 'https://www.ato.gov.au/Individuals/International-tax-for-individuals/In-detail/Residency/Residency---the-resides-test/';
export const BUDGET_2020_INFO = 'https://budget.gov.au/2019-20/content/bp1/index.htm';
export const AWARD_RATES = 'https://www.fairwork.gov.au/pay/minimum-wages/pay-guides';

export const EXTRA_PAYMENTS = 'https://www.ato.gov.au/Calculators-and-tools/Tax-withheld-calculator/?page=3';
export const ATO_FORMULAS = 'https://www.ato.gov.au/Rates/Schedule-1---Statement-of-formulas-for-calculating-amounts-to-be-withheld/?page=1#Using_this_schedule';
export const ATO_WEEKLY_TABLES = 'https://www.ato.gov.au/Rates/Weekly-tax-table/';
export const ATO_FORTNIGHTLY_TABLES = 'https://www.ato.gov.au/Rates/Fortnightly-tax-table/';
export const ATO_MONTHLY_TABLES = 'https://www.ato.gov.au/Rates/Monthly-tax-table/';




export const SUB_PAGES = [
  CALCULATOR_LINK,
  TAX_INFO_LINK,
  STUDENT_LOAN_LINK,
  TAX_DISTRIBUTION_LINK,
  PRIVACY_POLICY_LINK,
  ACCEPTABLE_USE_LINK,
  COOKIES_LINK,
];

export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];