import React, { useState } from "react";
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    FormHelperText,
    makeStyles,
    useTheme,
    Typography,
} from '@material-ui/core';

import { useCalculator } from '../../state/calculatorState';
import { StyledTextField, StyledTableRow } from '../../components/common/CustomComponents'

import { PriceFormat, PriceIntegerFormat } from '../common/customtFormatters';
import { taxData } from '../../core/calculatorData';
import { getBracket } from "../../core/Calculator";

import { ZERO_VALUE, MONTH_NAMES } from '../../constants';


const useStyles = makeStyles(theme => {
    return {
        image: {
            width: '100%',
            paddingBottom: 20,
        },
        helperText:{
            color: theme.palette.primary.contrastText,
        }

    }
});

function createData(label, description) {
    return { label, description };
}

export const RepaymentCalculator = () => {
    const theme = useTheme();
    const classes = useStyles();

    let [{ income: salary },] = useCalculator();

    const currentYearIndex = taxData.findIndex(t => t.current === "true");
    const taxObject = taxData[currentYearIndex].help;
    console.log("taxObject:", taxObject)

    const [income, setIncome] = useState(salary.a);
    const [debt, setDebt] = useState(25000);

    const data = getBracket(income, taxObject.brackets);


    const monthlyIncome = income / 12;
    const rate = data.value;
    const monthlyRepayments = monthlyIncome * (rate / 100);
    let monthsRemaining = Math.ceil(debt / monthlyRepayments);

    const years = Math.floor(monthsRemaining / 12);
    const yearsPlusMonths = monthsRemaining % 12;

    const now = new Date();
    const repaymentDate = new Date(now.setMonth(now.getMonth() + monthsRemaining));

    let monthsRemainingDescription = `${monthsRemaining} month${monthsRemaining > 1 ? 's' : ''}`
    let remainingDescription = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
    if(yearsPlusMonths > 0){
        if(years > 0){
            remainingDescription += ` and `;
        }
        remainingDescription += `${yearsPlusMonths} month${yearsPlusMonths > 1 ? 's' : ''}`;
     
    }
    let remainingDateDescription = MONTH_NAMES[repaymentDate.getMonth()] + " " + repaymentDate.getFullYear();

    if (rate === 0) {
        monthsRemainingDescription = "Indefinite";
        remainingDescription = "Below repayment threshold";
        remainingDateDescription = "Unforeseeable future";
    }


    const REPAYMENT_TABLE = [
        createData(
            'Monthly repayments', PriceIntegerFormat({ value: monthlyRepayments, displayType: "text" })
        ),
        createData(
            'Remaining payments', monthsRemainingDescription,
        ),
        createData(
            'Time until repayment', remainingDescription
        ),
        createData(
            'Final payment date', remainingDateDescription
        ),
    ];



    const handleChangeDebt = () => event => {
        if (document.activeElement.className !== event.target.className) return;
        let newValue = event.target.value;
        newValue = newValue.toLocaleString('fullwide', { useGrouping: false })
        if (debt === ZERO_VALUE) {
            newValue = (newValue - ZERO_VALUE) / 10;
        }
        const value = newValue > 0 ? newValue : ZERO_VALUE;
        setDebt(value);
    }


    const handleChangeSalary = () => event => {
        console.log("handleChangeSalary", document.activeElement.className, '!==', event.target.className)
        if (document.activeElement.className !== event.target.className) return;
        let newValue = event.target.value;
        newValue = newValue.toLocaleString('fullwide', { useGrouping: false })
        if (income === ZERO_VALUE) {
            newValue = (newValue - ZERO_VALUE) / 10;
        }
        const value = newValue > 0 ? newValue : ZERO_VALUE;
        setIncome(value);
    }



    return (
        <Grid container >
            <Grid item xs={12} >
                <Box>
                    <Grid container spacing={4} >
                        <Grid item xs={6}>
                            <StyledTextField
                                label={"Debt"}
                                fullWidth
                                value={Number(debt)}
                                variant="filled"
                                InputProps={{
                                    inputComponent: PriceFormat,
                                }}
                                onChange={handleChangeDebt()}
                                id="debt"
                            />
                            <FormHelperText className={classes.helperText}>{`Remaining student loan debt`} </FormHelperText>
                        </Grid>
                        <Grid item xs={6}>

                            <StyledTextField
                                label={"Annual Salary"}
                                fullWidth
                                value={Number(income)}
                                variant="filled"
                                InputProps={{
                                    inputComponent: PriceFormat,
                                }}
                                onChange={handleChangeSalary()}
                                id="salary"
                            />
                            <FormHelperText className={classes.helperText}>{`How much do you earn per year`} </FormHelperText>

                        </Grid>
                    </Grid>
                    <Box style={{ paddingTop: 40, position: "relative" }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="Tax table">
                                <TableBody>
                                    {REPAYMENT_TABLE.map((row, index) => (
                                        <StyledTableRow key={row.label}>
                                            <TableCell size="small" align="left" style={{ fontSize: 16,  }}>
                                            <Typography variant="h2">{row.label}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                            <Typography component="span" variant="h4" style={{ fontSize: 16, color: (index === 0 ? theme.palette.secondary.main : theme.palette.primary.main) }}>
                                            {row.description}
                                            </Typography>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

