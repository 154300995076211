import React, { useRef, useEffect, useState, useContext } from "react";
import {
    Box,
    makeStyles,
    useTheme,
    fade,
    Typography,
} from '@material-ui/core';

import { useWindowSize } from '../../utils/hooks';
import { useCalculator } from '../../state/calculatorState';
import placeholder from '../../assets/placeholder/TaxChart.png';
import { TaxChartSlider } from '../../components/common/CustomComponents'
import { PriceIntegerFormat } from '../common/customtFormatters';
import { taxData } from '../../core/calculatorData';
import { colorSequence } from '../calculator/distribution/DistributionChart'
import { calculateBracket } from "../../core/Calculator";
import { IncomeTaxContext } from  "./IncomeTax";
import { CollectionsOutlined } from "@material-ui/icons";


const getPixelRatio = context => {
    let backingStore =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;

    return (window.devicePixelRatio || 1) / backingStore;
};

const useStyles = makeStyles(theme => {
    return {
        image: {
            width: '100%',
            paddingBottom: 20,
        },

    }
});


export const TaxChart = () => {
    const ref = useRef();
    const theme = useTheme();
    let [{ income: salary },] = useCalculator();
    const {year, category} = useContext(IncomeTaxContext);
    const currentTaxData = taxData.find( c => c.year === year );
    let taxObject;
    for (const key of Object.keys(currentTaxData)) {
        if(currentTaxData[key].label && currentTaxData[key].label === category){
            taxObject = currentTaxData[key];
        }
    }

    const taxRates = taxObject.brackets;
    const MAX_INCOME = Number(Number(taxRates[taxRates.length - 1].from) * 1.2).toPrecision(2);
    const [income, setIncome] = useState(salary.a > MAX_INCOME * 0.95 ? MAX_INCOME * 0.95 : salary.a);

    useWindowSize();

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');

        // resize the canvas to the parent node
        canvas.style.width = '100%';
        canvas.style.height = '100%';

        let ratio = getPixelRatio(context);
        let w = getComputedStyle(canvas)
            .getPropertyValue('width')
            .slice(0, -2);
        let h = getComputedStyle(canvas)
            .getPropertyValue('height')
            .slice(0, -2);

        // reset the new values
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        const value = Math.min(income / MAX_INCOME, 1);
        drawIncome(context, canvas, theme, ratio, value, income, MAX_INCOME, taxRates);
    });

    const modifyIncome = (event, value) => {
        setIncome(value);
    }

    // const marks = taxRates.map(b => {
    //     return { value: b.from, label: '' }
    // })

    let height = 280;

    let tax = calculateBracket(income, taxRates, 1);

    const bgTint = fade(theme.palette.background.overlay, 0.3)

    return (
        <Box style={{ borderRadius: 10 }}>
            {/* <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ borderTopLeftRadius: 4, borderTopRightRadius: 3, height: 60, backgroundColor: theme.palette.primary.main }}>
                <Typography variant="h5" style={{ width: '100%', textAlign: "center", color: theme.palette.primary.contrastText }}>
                    Income Tax for Australian Residents
            </Typography>
            </Box> */}
            <Box style={{ padding: 0, paddingBottom: 20, position: "relative", backgroundColor: bgTint }}>

                <Box display="flex" flexDirection="column" flexGrow={1} style={{ height: `${height}px`, width: '100%' }}>
                    <canvas ref={ref} width={'100%'} height={`${height}px`} />
                </Box>
                <Box style={{ padding: 12, paddingTop: 10 }}>
                    <TaxChartSlider
                        valueLabelDisplay="off"
                        min={0}
                        max={Number(MAX_INCOME)}
                        step={1000}
                        aria-label="Adjust income"
                        value={income}
                        onChange={modifyIncome}
                    />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" style={{paddingLeft:20}}>
                    <Typography variant="h4"  style={{ fontSize: 16}} >
                        Salary:
                <Typography component="span" variant="h4" style={{ fontSize: 16, color: theme.palette.primary.contrastText }}> {PriceIntegerFormat({ value: income, displayType: "text" })} </Typography>
                    </Typography>

                    <Typography variant="h4" style={{ fontSize: 16}} >
                        Income Tax:
                <Typography component="span" variant="h4" style={{ fontSize: 16, color: theme.palette.primary.main }}> {PriceIntegerFormat({ value: tax, displayType: "text" })} </Typography>

                    </Typography>
                    <Typography component="span" variant="body2" style={{ opacity: 0.8 }}>Does not include other taxes, levies and offsets</Typography>
                </Box>
            </Box>
        </Box>
    );
}


export const TaxChartPlaceholder = () => {
    const classes = useStyles();

    return (
        <img src={placeholder} alt="placeholder" className={classes.image} />
    )

}


const drawIncome = (ctx, canvas, theme, ratio, value, income, MAX_INCOME, taxRates) => {
    let w = canvas.width;
    let h = canvas.height

    let scaleX = w / MAX_INCOME;

    const tax = calculateBracket(MAX_INCOME * 1.1, taxRates, 1);
    let scaleY = h / tax;

    const bg = fade(theme.palette.background.overlay, 0.3)

    ctx.beginPath();
    ctx.fillStyle = `${bg}`;
    ctx.fillRect(0, 0, w, h);

    // const bgTint = fade(theme.palette.background.overlay, 0.9)
    // ctx.beginPath();
    // ctx.fillStyle = `${bgTint}`;
    // ctx.fillRect(0, 0, w * value, h);

    const plot = [];
    const thickness = 10;

    taxRates.map((b, index) => {
        ctx.beginPath();
        const c = colorSequence[3 % colorSequence.length].split(".");
        ctx.fillStyle = theme[c[0]][c[1]][c[2]];


        const from = b.from;
        const to = Number(b.to) === 0 ? Number(MAX_INCOME) : Number(b.to);

        let taxFrom = calculateBracket(from, taxRates, 1);
        let taxTo = calculateBracket(to, taxRates, 1);

        ctx.moveTo(from * scaleX, h);
        ctx.lineTo(from * scaleX, h - taxFrom * scaleY);
        ctx.lineTo(to * scaleX, h - taxTo * scaleY);
        ctx.lineTo(to * scaleX, h);
        ctx.lineTo(from * scaleX, h);

        //const tint = fade(theme.palette.primary.main, (taxTo* scaleY)/h);

        const tint = theme.palette.primary[index+'00'];
        ctx.fillStyle = tint;
        ctx.fill();


        ctx.font = `${theme.typography.body1.fontSize * ratio}px ${theme.typography.h4.fontFamily[0]}`;

        if (income > from) {
            if (income < to) {
                // part way
                let t = calculateBracket(income, taxRates, 1);
                plot.push({ x: income * scaleX, y: h - t * scaleY })
            } else {
                plot.push({ x: to * scaleX, y: h - taxTo * scaleY })
            }
            ctx.fillStyle = theme.palette.primary.contrastText;
        } else {
            ctx.fillStyle = fade(theme.palette.primary.contrastText, 0.25);
        }

        // barcket value label
        ctx.textAlign = "center";
        const textX = (to + 0.5 * (from - to)) * scaleX;
        const textY = h - (taxFrom + 0.75 * (taxTo - taxFrom)) * scaleY
        ctx.fillText(`${b.value}%`, textX, Math.min(h - 40, Math.max(textY, 20)));
        return true;
    })

    // plot overlay
    const minY = h-(thickness * 0.5);
    ctx.beginPath();
    ctx.moveTo(0, minY);
    
    plot.map(pos => ctx.lineTo(pos.x, Math.min(pos.y, minY) ));
    //plot.map(pos => ctx.lineTo(pos.x, pos.y ));
    ctx.lineWidth = thickness;
    ctx.strokeStyle = theme.palette.primary.contrastText;
    ctx.stroke();


    // progress foreground tint
    // const bgTintAlpha = fade(theme.palette.primary.contrastText, 0.05)
    // ctx.beginPath();
    // ctx.fillStyle = `${bgTintAlpha}`;
    // ctx.fillRect(0, 0, w * value, h);



        // var glossGradient = ctx.createLinearGradient(from * scaleX, h,from * scaleX, h - taxTo * scaleY);
        // glossGradient.addColorStop("0", `rgba(255,255,255,0.2)`);
        // glossGradient.addColorStop("1.0", `rgba(255,255,255,0.00)`);
        // ctx.fillStyle = glossGradient;
        // ctx.moveTo(from * scaleX, h);
        // ctx.lineTo(from * scaleX, h - taxFrom * scaleY);
        // ctx.lineTo(to * scaleX, h - taxTo * scaleY);
        // ctx.lineTo(to * scaleX, h);
        // ctx.lineTo(from * scaleX, h);
        // ctx.fill();



       let glossGradient = ctx.createLinearGradient(w * value - w, 0,w * value, 0);
        // let glossGradient = ctx.createLinearGradient(0, 0,w * value, 0);
        glossGradient.addColorStop("0", `rgba(255,255,255,0.0)`);
        glossGradient.addColorStop("0.75", `rgba(255,255,255,0.05)`);
        glossGradient.addColorStop("1.0", `rgba(255,255,255,0.1)`);
        ctx.beginPath();
        ctx.fillStyle = glossGradient;
        ctx.fillRect(0, 0, w * value, h);



}
