import React, { useState } from 'react'
import {
    Box,
    useTheme,
} from '@material-ui/core';

import { taxData } from '../../core/calculatorData';
import { TaxInputOptions } from './TaxInputOptions'
import { TaxChart } from './TaxChart';
import { TaxTable } from './TaxTable';

export const IncomeTaxContext = React.createContext();

export const IncomeTaxComponent = () => {
    const theme = useTheme();

    const currentYearIndex = taxData.findIndex(t => t.current === "true");
    const currentYear = taxData[currentYearIndex].year;

    const taxCategories = taxData.map(obj => {
        const categorykey = Object.keys(obj).filter(key => key.indexOf("tax") === 0 && obj[key].label && obj[key].brackets);
        return { year: obj.year, categories: categorykey.map(k => obj[k].label) };
    })
    const currentYearCategories = taxCategories.find(cat => cat.year === currentYear);

    const [year, setYear] = useState(currentYear);
    const [category, setCategory] = useState(currentYearCategories.categories[0]);

    const setCurrentYear = (year) => {
        setYear(year);
        // is the current Category valid?
        const currentYearCategories = taxCategories.find(cat => cat.year === year);
        if (!currentYearCategories.categories.find(c => c === category)) {
            // default back to first category
            setCategory(currentYearCategories.categories[0])
        }
    }

    return (
        <Box style={{ padding: '0px 20px', backgroundColor: theme.palette.background.paper }} >
            <IncomeTaxContext.Provider value={{ year, setCurrentYear, taxCategories, category, setCategory }}>
                <Box style={{ paddingTop: 30 }}>
                    <Box style={{ paddingBottom: 30 }} >
                        <TaxInputOptions />
                    </Box>
                    <Box style={{ paddingBottom: 30 }} >
                        <TaxTable />
                    </Box>

                    <TaxChart />

                </Box>
            </IncomeTaxContext.Provider>
        </Box>

    )
}


