import React, { } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { StyledLink } from '../common/CustomComponents';
import packageJson from '../../../package.json';

import { EMAIL_ADDRESS, PRIVACY_POLICY_LINK, ACCEPTABLE_USE_LINK } from '../../constants';

const useStyles = makeStyles( theme => (
    {
        root: {
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            backgroundColor:theme.palette.background.default,
            padding:40,
            height:40,
            flex:1,
        },
    }
));



const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <StyledLink href={PRIVACY_POLICY_LINK} >
                <Typography variant="body2">
                    Privacy Policy
                </Typography>
            </StyledLink>

            <StyledLink href={ACCEPTABLE_USE_LINK} >
                <Typography variant="body2">
                    Acceptable Use
                </Typography>
            </StyledLink>

            <Typography variant="body2">
                © Rob Shearing 2020
            </Typography>

            <StyledLink href={EMAIL_ADDRESS} target="_blank" rel="noreferrer">
                <Typography variant="body2">
                    Contact Us
                </Typography>
            </StyledLink>

            <Typography variant="body2">
                    {packageJson.version}
                </Typography>
        </div>
    )

}

export default Footer;
