import React, { useRef, useEffect } from "react";
import {
    Box,
    useTheme,
    useMediaQuery,
    fade,
} from '@material-ui/core';

import { useWindowSize, useImage } from '../../../utils/hooks';
import { useCalculator } from '../../../state/calculatorState';
import { DARK_MODE } from '../../../state/themeState';
import marker_white from '../../../assets/marker2_white.png';
import marker_grey from '../../../assets/marker2_grey.png';
import { taxData } from '../../../core/calculatorData';
import { formatMoney, resizeCanvas } from '../../../utils/utils';


export const SuperannuationBand = () => {
    const [, darkIcon] = useImage(marker_white)
    const [, lightIcon] = useImage(marker_grey)
    const ref = useRef();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('xs'));

    let [{ superannuation, totalSuperannuation, superannuationConcessional, superannationNonConcessional, superannationExcess, adjustSuperannuation, over65, adjustSalaryScaracfice, superannuationSacrafice, additionalSuper, yearIndex },] = useCalculator();
    useWindowSize();

    let icon = (theme.palette.type === DARK_MODE) ? darkIcon : lightIcon;
    if (!icon) icon = null;

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');

        // resize the canvas to the parent node
        const {ratio} = resizeCanvas(canvas, context, 120);

        const superannuationData = {};

        const superannuationTaxData = taxData[yearIndex].superannuation;
        const fundContributionCapMultiplier = adjustSuperannuation && over65 ? 1 : 3;
        const fundContributionCap = superannuationTaxData.nonConcesssionalCap * fundContributionCapMultiplier



        superannuationData.concessionalCap = superannuationTaxData.concessionalCap;
        superannuationData.nonConcessionalCap = fundContributionCap;
        superannuationData.cap = superannuationTaxData.cap;
        superannuationData.concessional = superannuationConcessional;
        superannuationData.superannationNonConcessional = superannationNonConcessional;
        superannuationData.excess = superannationExcess;


        superannuationData.totalSuperannuation = totalSuperannuation.a;
        superannuationData.salaryScaracfice = adjustSalaryScaracfice ? superannuationSacrafice.a : 0;
        superannuationData.additionalSuper = adjustSuperannuation ? additionalSuper : 0;
        superannuationData.superannuation = superannuation.a;


        drawTaxBar(context, canvas, theme, ratio, superannuationData, icon);
    });

    let height = 110;
    if(small) height = 90;
    const padding= small ? 20 : 10;

    return (
        <Box style={{ paddingTop: padding, paddingBottom:padding }}>
            <Box display="flex" flexGrow={1} style={{ height: `${height}px`, width: '100%' }}>
                <canvas ref={ref} width={'100%'} height={`${height}px`} />
            </Box>
        </Box>
    );
}



const drawTaxBar = (ctx, canvas, theme, ratio, superannuationData, icon) => {

    let margin = 12.5 * ratio;
    let barWidth = (canvas.width/1) - (2 * margin);
    let barHeight = 30 * ratio;
    let barY = margin * 5;
    let barX = margin;


    let labelY = margin*3.0;


    const shadowColor = fade(theme.palette.grey[900], 0.1)
    // const bgColor = theme.palette.background.default;
    const bgColor = theme.palette.grey[900];

    // shadow
    ctx.beginPath();
    ctx.rect(barX - 1, barY - 1, barWidth + 4, barHeight + 4);
    ctx.fillStyle = shadowColor;
    ctx.fill();

    // bar
    ctx.beginPath();
    // ctx.rect(x1, y1, x2, y2);
    ctx.rect(barX, barY, barWidth, barHeight);
    ctx.fillStyle = bgColor;
    ctx.fill();


    let valueRatio = 0;
    let excess = barWidth / 3;
    let span = barWidth - excess;
    let concessionalCap = 0;
    let nonConcessionalCap = 0;
    let nonConcessionalRange = false;


    // active
    ctx.beginPath();
    // ctx.rect(x1, y1, x2, y2);
    ctx.rect(barX, barY, span, barHeight);
    ctx.fillStyle = theme.palette.background.overlay;
    ctx.fill();


    if (superannuationData.superannationNonConcessional > 0) {
        valueRatio = span / superannuationData.nonConcessionalCap;
        concessionalCap = superannuationData.concessionalCap * valueRatio;
        nonConcessionalCap = superannuationData.nonConcessionalCap * valueRatio;
        nonConcessionalRange = true;

    } else {
        span = barWidth - excess
        valueRatio = span / superannuationData.concessionalCap;
        concessionalCap = superannuationData.concessionalCap * valueRatio;
        nonConcessionalCap = barWidth;
    }

    let colourindex = 100;
    let barColor = `${theme.palette.secondary[colourindex]}`
    let spanFrom = margin;
    let spanTo = margin;





    // // Concessional
    spanFrom = margin;
    spanTo = spanFrom + (concessionalCap);
    barColor = `${theme.palette.tertiary[100]}`
    ctx.beginPath();
    ctx.rect(spanFrom, barY, (spanTo - spanFrom), barHeight);
    ctx.fillStyle = barColor;
    ctx.fill();


    // // NonConcessional
    spanFrom = spanTo;
    spanTo = spanFrom + (nonConcessionalCap - concessionalCap);
    barColor = `${theme.palette.tertiary[200]}`
    ctx.beginPath();
    ctx.rect(spanFrom, barY, (spanTo - spanFrom), barHeight);
    ctx.fillStyle = barColor;
    ctx.fill();


    // // Excess
    spanFrom = spanTo;
    spanTo = barWidth + margin;
    barColor = `${theme.palette.tertiary[300]}`
    ctx.beginPath();
    ctx.rect(spanFrom, barY, (spanTo - spanFrom), barHeight);
    ctx.fillStyle = barColor;
    ctx.fill();


    ctx.beginPath();
    ctx.rect(concessionalCap + margin, barY - barHeight * 1, 1, barHeight * 2);
    ctx.fillStyle = `${theme.palette.text.primary}`;
    ctx.fill();


    // dimmer
    ctx.beginPath();
    const barH = barHeight/1.0;
    let px = (superannuationData.totalSuperannuation*valueRatio);

    px = Math.min( barWidth, px);
    ctx.rect(barX + px ,barY + (barHeight - barH), barWidth-px, barH);
    ctx.fillStyle =`${fade(theme.palette.background.paper, 0.75)}`;
    ctx.fill();

    //pointer
    px += margin;
    
    if (icon) {
        px -= icon.width * 0.5;
        px += 3;
        ctx.drawImage(icon, px, barY + barHeight * 1.0, 25, 60);
    }


    let labelYpos = labelY;

    ctx.font = `${theme.typography.body1.fontSize * ratio*0.85}px ${theme.typography.body1.fontFamily[0]}`;
    ctx.fillStyle = `${theme.palette.text.primary}`;
    ctx.fillText(`Concessional Cap`, concessionalCap + margin + (5*ratio), labelYpos - (2.5*ratio));

    ctx.font = `${theme.typography.body1.fontSize * ratio*0.75}px ${theme.typography.body1.fontFamily[0]}`;
    ctx.fillStyle = `${barColor}`;
    ctx.fillText(`$${formatMoney(superannuationData.concessionalCap, 0, ".", ",")}`, concessionalCap + margin + (5*ratio), labelYpos+(12*ratio));


    if (nonConcessionalRange) {
        ctx.beginPath();
        ctx.rect(nonConcessionalCap + margin, barY - barHeight * 1, 1, barHeight * 2);
        ctx.fillStyle = `${theme.palette.text.primary}`;
        ctx.fill();


        ctx.font = `${theme.typography.body1.fontSize * ratio*0.85}px ${theme.typography.body1.fontFamily[0]}`;
        ctx.fillStyle = `${theme.palette.text.primary}`;
        ctx.fillText(`Non-concessional Cap`, nonConcessionalCap + margin + (5*ratio), labelYpos - (2.5*ratio));
    
        ctx.font = `${theme.typography.body1.fontSize * ratio*0.75}px ${theme.typography.body1.fontFamily[0]}`;
        ctx.fillStyle = `${barColor}`;
        ctx.fillText(`$${formatMoney(superannuationData.nonConcessionalCap, 0, ".", ",")}`, nonConcessionalCap + margin + (5*ratio), labelYpos+(12*ratio));


        // excess
        let pos = nonConcessionalCap;
        // indicate tax beyond limit
        let gap = 5*ratio;
        while ((pos) < (barWidth - gap)) {
            pos += gap;
            ctx.beginPath();
            ctx.rect(pos + margin, barY, 2, barHeight);
            ctx.fillStyle = `${fade(theme.palette.background.overlay, 1.0)}`
            ctx.fill();

        }

    }




    


    ctx.font = `${theme.typography.h2.fontSize * ratio}px ${theme.typography.h2.fontFamily[0]}`;
    ctx.fillStyle = `${theme.palette.text.primary}`;
    ctx.textAlign = "center";
    ctx.fillText(`Superannuation Band`, margin + barWidth * 0.5, margin + 0);


}



