import React, { } from 'react'
import {
    Box,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { ButtonLink } from '../../common/CustomComponents';

import { OLD_CALCULATOR_LINK, EMAIL_ADDRESS } from '../../../constants';

export const SalaryIntro = () => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const fontSize = (small ? 12 : 14);

    return (

        <Box style={{ padding: (small ? 10 : 10), paddingRight: (small ? 0 : 10) }}>
            <Typography gutterBottom variant="body1" component="h2" style={{ fontSize:fontSize }}>
                Use this calculator to quickly find out how much tax you will need to pay on your income. This calculator is always up to date and conforms to official Australian Tax Office rates and formulas.
                </Typography>

            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ paddingTop: 0, paddingBottom: 10 }}>
                <Typography variant="body1" style={{ fontSize:fontSize }}>
                    Is there a problem with the new site? <Link href={EMAIL_ADDRESS} title="feedback">Let me know</Link> about any problems, and continue with the old site.
                </Typography>
                <Box style={{paddingRight:small?0:20, minWidth:112}}>
                <ButtonLink href={OLD_CALCULATOR_LINK}>ORIGINAL&nbsp;SITE</ButtonLink>
                </Box>
            </Box>
            <Typography variant="body1" style={{fontSize: fontSize}}>Enter your income here and see the results in the summary below. Click 'More Options' to make adjustments specific to your situation. </Typography>
            
        </Box>
    )
}
