import React from 'react'
import { Typography } from '@material-ui/core'

const Logo = () => {

    return (
        <div style={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant='h1' style={{ fontSize: 30, display: 'inline-block', color:'#FAFAFA' }}>
                pay
            </Typography>
            <Typography variant='body1' style={{ fontSize: 30, display: 'inline-block', color:'#FAFAFA' }}>
                calculator
            </Typography>
        </div>
    )

}


export default Logo;