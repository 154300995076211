import React from 'react';
import {
    Box,
} from '@material-ui/core';


import { SummaryDownload } from './SummaryDownload';
import { SummaryYTD } from './SummaryYTD';

export const SummaryFooter = () => {

    return (
        <Box display="flex" flex={1} justifyContent="space-between" alignItems="flex-start" flexDirection="row" style={{paddingTop:10}}>
            <SummaryDownload />
            <div style={{paddingLeft:40, maxWidth:240}}>
            <SummaryYTD/>
            </div>
        </Box>

    );
}