import React, { useContext } from 'react'
import {
    Grid,
    makeStyles,
    useTheme,
    Typography,
    Collapse,
    Tooltip,
    Box,
    Hidden,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { PriceFormat } from '../../common/customtFormatters';
import { useLayout, UPDATE_LAYOUT } from '../../../state/layoutState';
import { payOptions } from '../../../state/calculatorState';
import { ValueItem } from './SummaryTableExpanded';
import { SummaryContext } from './Summary';

const useStyles = makeStyles((theme) => {
    return {
        mainNavBar: {
            cursor: 'pointer',
            color: theme.palette.primary.contrastText,
            display: 'flex',
            justifyContent: 'space-between',
        },
        navMarker: {
            borderRadius: 10,
            width: 20,
            height: 20
        },
        subNavMarker: {
            alignItems: "center",
            borderRadius: 5,
            width: 10,
            height: 10
        },

        subNavBar: {
            marginTop: 10, marginBottom: 10, marginLeft: 18, width: 4, borderRadius: 2.4,
            color: theme.palette.primary.contrastText,
        },
        subNavInfo: {
            marginLeft: -20,
            color: theme.palette.primary.contrastText,
        },
        superscript:{
            opacity: 0.7,
            fontSize:11,
            paddingLeft:5,
        },
    }
});


const SummaryTableContracted = () => {
    const summaryData = useContext(SummaryContext);
    let [{ resultsRange },] = useLayout();

    return (
        <Grid container style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
                {SummaryPay(summaryData, resultsRange)}
                {summaryData.data.map(data => Summary(data, summaryData.ytd))}
                {/* { SummaryReturn(summaryData.return, resultsRange)} */}
            </Grid>
        </Grid>
    )
}

const SummaryPay = (data, resultsRange) => {
    const classes = useStyles();
    const {summary, ytd, YTD, extraPayment, payments} = data;
    const isYTD = resultsRange > 0 && ytd;

    let extra = ``;
    if(isYTD){
        if (extraPayment) {
            extra = <Typography className={classes.superscript} variant="h4">{`(${YTD} / ${payments})*`}</Typography>
        }else{
            extra = <Typography className={classes.superscript} variant="h4">{`(${YTD} / ${payments})`}</Typography>
        }

    }else if (extraPayment) {
        extra = <Typography className={classes.superscript} variant="h4">{`(${payments})*`}</Typography>
    }

    
    const bgStyle = { backgroundImage: `linear-gradient(to bottom right, ${summary.colorB}, ${summary.color})` };
    const value = isYTD ? summary.data.currentYTD : summary.data.current;
    const label = isYTD ? `Year-to-date (${payOptions[resultsRange].label })` : `${payOptions[resultsRange].label } ${summary.label}`;

    return (
        <div style={{ paddingBottom: 2 }}>
            <Box className={classes.mainNavBar} display="flex" alignItems="center" style={bgStyle} >
                <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} style={{ margin: 20, overflow: "hidden" }} >
                    <Box display="flex" style={{ overflow: "hidden" }} >
                        <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="flex-start" style={{ paddingRight: 10, overflow: "hidden" }}>
                            <Typography variant="h1" style={{ fontSize: 16, letterSpacing: -0.25 }}>
                                {label}
                            </Typography>
                            {extra}
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Typography variant="h4" style={{ fontSize: 18, letterSpacing: -0.25 }}>
                            {PriceFormat({ value, displayType: "text" })}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

const Summary = (data, ytd) => {

    const theme = useTheme();
    const classes = useStyles();
    let [layout, layoutDispatch] = useLayout();

    const target = data.id;
    let expanded = layout[target];

    const toggleVisibility = () => {
        data = {};
        data[target] = !expanded
        layoutDispatch({ type: UPDATE_LAYOUT, data })
    }

    const txtColor = theme.palette.primary.contrastText;

    return (
        <div style={{ paddingBottom: 2 }} key={"Summary" + data.label} >
            {SummaryMainItem(data, ytd, expanded, toggleVisibility)}
            <Collapse in={expanded} unmountOnExit>
                <Box display="flex">
                    <Box className={classes.subNavBar} style={{ backgroundColor: data.color }} />
                    <Box display="flex" flexDirection="column" flexGrow={1} >
                        {data.sub.map(item => SummarySubItem(item, ytd, txtColor))}
                    </Box>
                </Box>
            </Collapse>
        </div>
    )
}



const SummaryMainItem = (data, ytd, isOpen, toggleVisibility) => {
    const classes = useStyles();
    const theme = useTheme();
    const bgColor = theme.palette.background.overlay;
    const markerColor = data.color;

    const fontStyle = "h4";
    let value = ytd ? data.data.currentYTD : data.data.current;
    value = data.negative ? -1 * value : value;

    return (
        <Tooltip title={isOpen ? "" : "Click to show more"} placement="top" arrow >
            <Box className={classes.mainNavBar} display="flex" alignItems="center" style={{ backgroundColor: bgColor }} onClick={toggleVisibility}>
                <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} style={{ margin: 10, marginTop:7, marginBottom:7, overflow: "hidden" }} >
                    <Box display="flex" style={{ overflow: "hidden" }} >
                        <Box className={classes.navMarker} style={{ backgroundColor: markerColor }} />
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        <Box style={{ paddingRight: 10, overflow: "hidden" }}>
                            <Hidden smUp>
                                <Typography variant={fontStyle} style={{ fontSize: 16, letterSpacing: -0.25 }}>
                                    {data.label}
                                </Typography>
                            </Hidden>
                            <Hidden xsDown>
                                <Typography variant={fontStyle} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                    {data.label}
                                </Typography>
                            </Hidden>

                        </Box>
                    </Box>
                    <Box display="flex">
                        <Hidden smUp>
                            <ValueItem value={value} addBrackets={data.addBrackets} addSquareBrackets={data.addSquareBrackets} showZero />

                        </Hidden>
                        <Hidden xsDown>
                            <ValueItem value={value} addBrackets={data.addBrackets} addSquareBrackets={data.addSquareBrackets} showZero />

                        </Hidden>
                    </Box>
                </Box>
            </Box>
        </Tooltip>
    )
}


const SummarySubItem = (data, ytd, textColor) => {

    const subNavMarker = {
        alignItems: "center",
        borderRadius: 5,
        width: 10,
        minWidth: 10,
        height: 10,
        backgroundColor: data.color
    };

    if (data.hidden) return false;

    let value = ytd ? data.data.currentYTD : data.data.current;
    value = data.negative ? -1 * value : value;

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ padding: 5, paddingLeft: 10 }} key={"summary_item" + data.label}>
            <Box display="flex" style={{ overflow: "hidden", alignItems: "center" }} >
                <Box style={subNavMarker} />
                <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ paddingLeft: 10, paddingRight: 10, color: textColor }}>

                        <Typography variant="h5" style={{ fontSize: 14, lineHeight:1.0}}>
                            {data.label}
                        </Typography>
                    <Typography variant="body2" style={{ opacity: 0.8, lineHeight:1.0 }}>
                        {data.comment}
                    </Typography>
                </Box>
            </Box>
            <Hidden smUp>
                <ValueItem value={value} addBrackets={data.roundBrackets} addSquareBrackets={data.quareBrackets} showZero />
            </Hidden>
            <Hidden xsDown>
                <ValueItem value={value} addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} showZero />
            </Hidden>

        </Box>
    )
}


const SummaryReturn = (data, resultsRange) => {
    const theme = useTheme();

    if( Number(resultsRange) > 0) return false;

    const bgColor = data.color
    const title = data.label
    const value = data.data.complete;

    return (
        <div style={{ paddingBottom: 2 }} >
            <Box display="flex" justifyContent="flex-end" style={{ backgroundColor: bgColor }} >
                <Grid container style={{ margin: 10, alignItems: "center", color: theme.palette.primary.contrastText }}>
                    <Grid item xs={10}>
                        <Box display="flex" flexDirection="row" flexGrow={1}>
                            <Typography variant="h4" style={{paddingLeft: 40,  fontSize:15, color: theme.palette.primary.contrastText }} >
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} > <ValueItem value={value.a} integerDollars /> </Grid>

                </Grid>
            </Box>
        </div>
    )
}

export default SummaryTableContracted;
