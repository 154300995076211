import { createMuiTheme } from '@material-ui/core/styles';


const fontHeading = {
    fontFamily: [
       // 'Ubuntu',
       // 'Noto Sans JP',
        'Arial',
        'sans-serif',
    ],
    textShadow: '1px 1px 1px rgba(0,0,0,0.4)',
}


const fontBody = {
    fontFamily: [
       // 'Noto Sans JP',
       // 'Ubuntu',
        'Arial',
        'sans-serif',
    ],
}


const palette = {
primary: {
    light: '#89ad41',//'#98b64d',
    main: '#71972c',//'#719039',
    dark: '#5c7a27',//'#455d27',
    contrastText:"#FAFAFA",

     // used for colour keys on summary data
    "100":'#98b64d',
    "200":'#81a240',
    "300":'#678735',
    "400":'#52712e',
    "500":'#455d27',
    "600":'#36451d',
    '700':'#273415',
},
secondary: {
    light: '#f2ba2e',
    main: '#ea9f21',
    dark: "#d87608", //'#ee9337',
    contrastText:"#333333",
     '100':'#f2ba2e',
     '200':'#f2af2d',
     '300':'#ea9f21',
     '400':'#e28a15',
     '500':'#d87608',
     '600':'#cb6500',
     '700':'#bc5700',
},

tertiary: {
    light: '#4DBFE4',
    main: '#33A2C6',
    dark: '#24738C',
    contrastText:"#FAFAFA",

     // used for colour keys on summary data
     "100":'#38b7e0',
     "200":'#24a3cb',
     "300":'#1c8baf',
     "400":'#18708c',
     "500":'#0d576d',
     "600":'#033e50',
     "700":'#002e3d',
},

background: {
    default: 'rgb(30,30,30)',//''#000000',
    paper: 'rgb(36,36,36)',//'#121212',
    overlay: 'rgb(56,56,56)',//'#2a2a2a',
}
}


// setup themes
export default createMuiTheme({
    // spacing: 0,

    shape: {
        borderRadius: 3
    },

    props:{
        MuiButtonBase: {
            TouchRippleProps: {
                 style: {color: palette.primary.main}
                
            }
        },
    },

    typography: {
        root:{
            opacity:1.0,
        },
        button: {
            ...fontHeading,
            fontWeight: '700',
            fontSize: 14,
          },
        h1:{
            ...fontHeading,
            fontSize: "24",
            fontWeight: 700,
            letterSpacing: "-0.5px",
            textShadow: '1px 1px 1px rgba(0,0,0,0.4)',
        },
        h2:{
            ...fontHeading,
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: "-0.25px"
        },
        h3:{
            ...fontHeading,
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: "-0.25px"
        },

        h4:{
            ...fontHeading,
            fontSize: 18,
            fontWeight: 700,
            letterSpacing: "-0.75px"
        },
        h5:{
            ...fontBody,
            fontSize: 18,
            opacity:1.0,
            fontWeight: 400,
            letterSpacing: "-0.25px",
            textShadow: '2px 2px 2px rgba(0,0,0,0.1)',
        },

        subtitle1:{
            ...fontHeading,
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: "-0.25px"
        },
        
        body1:{
            ...fontBody,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "-0.1px",
            color: "#bbbbbb",
            // opacity:0.75,
        },
        body2:{
            ...fontBody,
            fontSize: 10,
            fontWeight: 400,
            letterSpacing: "-0.1px",
            color: "#999999",
        },
        body3:{
            ...fontBody,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "-0.25px",
        },
    },
    palette: {
        ...palette,

        type: 'dark',

        grey:{
            "300":"#2f2f2f",
            "900":"#000000",
        },

        text: {
            primary:"#FAFAFA",
            secondary:"#121212",
            link:"#b1ff35",
            alert:palette.primary.light,
        },
    },
    transitions: {
        duration: {
            standard: 500,
        }
    }
});