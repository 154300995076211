
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

import { MAX_WIDTH } from '../constants';
import Footer from '../components/footer/Footer';

import {
    PC_right_sticky,
    PC_footer_sticky,
  } from '../components/ads/FuseAds';


const useStyles = makeStyles((theme) => {

    return {
        contentWrapper: {
            backgroundColor: theme.palette.background.default,
            padding: 0,
            paddingTop:30,
        },
    }
});


const AuxilleryPage = (props) => {
    const theme = useTheme();
    const classes = useStyles();

    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const mobile = xs;
    const tablet = sm || md;
    const desktop = lg;
  
    let padding = 20;
    let bannerWidth = 0;
    if( tablet ) bannerWidth = 160 + padding * 1;
    if( desktop ) bannerWidth = 300 + padding * 1;
  
    const paddingH = (mobile || tablet) ? 0 : 20;


    return (
        <React.Fragment>
            <Box display="flex" justifyContent="center">

                <div className={classes.contentWrapper} style={{ width: `calc(100vw - ${bannerWidth}px)`, maxWidth: MAX_WIDTH, paddingLeft:paddingH, paddingRight:paddingH }}>
                    {props.children}
                </div>
                
                {(tablet || desktop) && (
                    <PC_right_sticky />
                )}

            </Box>
            {mobile && (
                <PC_footer_sticky />
            )}
            <Footer />
        </React.Fragment>
    )

}


export default AuxilleryPage;