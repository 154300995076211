export const taxData = [

  // {
  //   "year": "2012","current":"false",
  //   "tax": {
  // "label": "Australian Resident",
  // 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":6000, 	"type":"percent", "nearest":1, "value":0},
  // 	  {"from":6001, 	"to":37000, 	"type":"percent", "nearest":1, "value":15},
  // 	  {"from":37001, 	"to":80000, 	"type":"percent", "nearest":1, "value":30},
  // 	  {"from":80001, 	"to":180000, 	"type":"percent", "nearest":1, "value":37},
  // 	  {"from":180001, 	"to":0, 		"type":"percent", "nearest":1, "value":45}]
  //   },
  //   "taxNoFreeThreshold": {
  // "label": "No Tax-Free Threshold",// 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":37000, 	"type":"percent", "nearest":1, "value":15},
  // 	  {"from":37000, 	"to":80000, 	"type":"percent", "nearest":1, "value":30},
  // 	  {"from":80000, 	"to":180000, 	"type":"percent", "nearest":1, "value":37},
  // 	  {"from":180000, 	"to":0, 		"type":"percent", "nearest":1, "value":45}]
  //   },

  //   "taxNonResident": {
  // "label": "Non Resident",
  // 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":80000, 	"type":"percent", "nearest":1, "value":30},
  // 	  {"from":80000, 	"to":180000, 	"type":"percent", "nearest":1, "value":37},
  // 	  {"from":180000, 	"to":0, 		"type":"percent", "nearest":1, "value":45}]
  //   },
  //   "lito":{
  // 	  "incremental":1,
  // 	  "paygLoading":0.7,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":29999, 	"type":"fixed", 	"nearest":1, "value":"-1500"},
  // 	  {"from":29999, 	"to":67500, 	"type":"percent", 	"nearest":1, "value":4}
  // 	  ]
  //   },

  //   "lito":{
  // 	  "incremental":0,
  // 	  "paygLoading":1.0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":29999, 	"type":"fixed", 	"nearest":1, "value":"1500"},
  //     {"from":29999, 	"to":67500, 	"type":"rate", 	"nearest":1, "start":1500, "end":0, "value":-4},
  //     {"from":67500, 	"to":0, 	"type":"fixed", 	"nearest":1, "value":0}
  // 	  ]
  //   },


  //   "mawto":{
  // 	  "incremental":0,
  // 	  "paygLoading":1.0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":0, 	"type":"fixed", 	"nearest":1, "value":0}
  // 	  ]
  //   },
  //   "medicare":{
  // 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":19404, 	"type":"percent", 	"nearest":1, "value":0},
  // 	  {"from":19404, 	"to":22828, 	"type":"percent", 	"nearest":1, "value":10.0},
  // 	  {"from":22828, 	"to":0, 		"type":"percent", 	"nearest":1, "value":1.5, "incremental":"false"}
  // 	  ]
  //   },
  //   "medicareSurcharge":{
  // 	  "incremental":0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":70000, 	"type":"percent", 	"nearest":1, "value":0},
  // 	  {"from":70000, 	"to":0, 		"type":"percent", 	"nearest":1, "value":1.25}
  // 	  ]
  //   },
  //   "superannuation":{
  // 	  "incremental":0,
  // 	  "cap":16651.60,
  // 	  "cap_50":50000,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":0, 	"type":"percent", 	"nearest":1, "value":9}
  // 	  ]
  //   },
  //   "division293":{
  // 	  "incremental":1,
  // "threshold":300000,
  //     "rate":15,
  // 	  "brackets":[
  //       {"from":0, 		"to":300000, 	"type":"percent", 	"nearest":1, "value":0.0},
  //       {"from":300000, 		"to":0, 	"type":"percent", 	"nearest":1, "value":15.0}
  // 	  ]
  //   },
  //   "other":[{
  // 		  "name":"Flood Levy",
  // 		  "incremental":1,
  // 		  "brackets":[
  // 		  {"from":0, 		"to":50000, 	"type":"fixed", 	"nearest":1, "value":0},
  // 		  {"from":50000, 	"to":1000000, 	"type":"percent", 	"nearest":1, "value":0.5},
  // 		  {"from":100000, 	"to":0, 		"type":"percent", 	"nearest":1, "value":1.0}
  // 		  ]
  // 	}
  //   ],
  //   "help":{
  // 	  "incremental":0,
  // 	  "brackets":[
  // 		{"from":0, 		"to":47196, 	"type":"fixed", 	"nearest":1, 	"value":0},
  // 		{"from":47196, 	"to":52572, 	"type":"percent", 	"nearest":1, 	"value":4},
  // 		{"from":52572, 	"to":57947, 	"type":"percent", 	"nearest":1, 	"value":4.5},
  // 		{"from":57947, 	"to":60993, 	"type":"percent", 	"nearest":1, 	"value":5},
  // 		{"from":60993, 	"to":65563, 	"type":"percent", 	"nearest":1, 	"value":5.5},
  // 		{"from":65563, 	"to":71006, 	"type":"percent", 	"nearest":1, 	"value":6},
  // 		{"from":71006, 	"to":74743, 	"type":"percent", 	"nearest":1, 	"value":6.5},
  // 		{"from":74743, 	"to":82253, 	"type":"percent", 	"nearest":1,	"value":7},
  // 		{"from":82253, 	"to":87649, 	"type":"percent", 	"nearest":1, 	"value":7.5},
  // 		{"from":87649, 	"to":0, 		"type":"percent", 	"nearest":1, 	"value":8}
  // 	  ]
  //   },
  //   "sfss":{
  // 	  "incremental":0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":47196, 	"type":"fixed", 	"nearest":1, 	"value":0},
  // 	  {"from":47196, 	"to":57947, 	"type":"percent", 	"nearest":1, 	"value":2},
  // 	  {"from":57947, 	"to":82253, 	"type":"percent", 	"nearest":1, 	"value":3},
  // 	  {"from":82253, 	"to":0, 		"type":"percent", 	"nearest":1, 	"value":4}
  // 	  ]
  //   }
  // },


  /*
    {
      "year": "2013", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }]
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }]
      },
  
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }]
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 10000, "type": "percent", "nearest": 1, "value": -5, "incremental": "false" },
          { "from": 10000, "to": 53000, "type": "fixed", "nearest": 1, "value": -500, "incremental": "true" },
          { "from": 53000, "to": 63000, "type": "percent", "nearest": 1, "value": 5, "incremental": "true" },
          { "from": 63000, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 20541, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 20541, "to": 24167, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 24167, "to": 0, "type": "percent", "nearest": 1, "value": 1.5, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 84000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 84001, "to": 97000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 97001, "to": 130000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 130001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [],
      "superannuation": {
        "incremental": 0,
        "cap": 183000,
        "cap_50": 25000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 49096, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 49096, "to": 54688, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 54688, "to": 60279, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 60279, "to": 63448, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 63448, "to": 68202, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 68202, "to": 73864, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 73864, "to": 77751, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 77751, "to": 85564, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 85564, "to": 91177, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 91177, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 49096, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 49096, "to": 60279, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 60279, "to": 85564, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 85564, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
    {
      "year": "2014", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
        "payg": [
          { "income": 355, "a": 0, "b": 0 },
          { "income": 395, "a": 0.19, "b": 67.4635 },
          { "income": 464, "a": 0.29, "b": 106.9673 },
          { "income": 711, "a": 0.205, "b": 67.4636 },
          { "income": 1282, "a": 0.3427, "b": 165.4424 },
          { "income": 1538, "a": 0.34, "b": 161.9809 },
          { "income": 3461, "a": 0.385, "b": 231.2116 },
          { "income": 0, "a": 0.465, "b": 508.1347 }]
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 45, "a": 0.19, "b": 0.19 },
          { "income": 361, "a": 0.2216, "b": 1.4232 },
          { "income": 932, "a": 0.3427, "b": 45.2055 },
          { "income": 1188, "a": 0.34, "b": 42.6890 },
          { "income": 3111, "a": 0.385, "b": 96.1698 },
          { "income": 0, "a": 0.465, "b": 345.0928 }
        ]
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 1538, "a": 0.325, "b": 0.325 },
          { "income": 3461, "a": 0.37, "b": 69.2308 },
          { "income": 0, "a": 0.45, "b": 346.1538 }]
  
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 10000, "type": "percent", "nearest": 1, "value": -5, "incremental": "false" },
          { "from": 10000, "to": 53000, "type": "fixed", "nearest": 1, "value": -500, "incremental": "true" },
          { "from": 53000, "to": 63000, "type": "percent", "nearest": 1, "value": 5, "incremental": "true" },
          { "from": 63000, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 20541, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 20542, "to": 24167, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 24168, "to": 0, "type": "percent", "nearest": 1, "value": 1.5, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 88000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 88001, "to": 102000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 102001, "to": 136000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 136001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [],
      "superannuation": {
        "incremental": 0,
        "cap": 192160,
        "cap_50": 35000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.25 }
        ]
      },
      "division293": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 300000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 300000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 51309, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 51309, "to": 57153, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 57153, "to": 62997, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 62997, "to": 66308, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 66308, "to": 71277, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 71277, "to": 77194, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 77194, "to": 81256, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 81256, "to": 89421, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 89421, "to": 95287, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 95287, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 49096, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 49096, "to": 60279, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 60279, "to": 85564, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 85564, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
    {
      "year": "2015", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 355, "a": 0, "b": 0 },
          { "income": 395, "a": 0.19, "b": 67.4635 },
          { "income": 464, "a": 0.29, "b": 106.9673 },
          { "income": 711, "a": 0.21, "b": 67.4642 },
          { "income": 1282, "a": 0.3477, "b": 165.4431 },
          { "income": 1538, "a": 0.3450, "b": 161.9815 },
          { "income": 3461, "a": 0.3900, "b": 231.2123 },
          { "income": 0, "a": 0.49, "b": 577.3662 }
        ]
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 45, "a": 0.19, "b": 0.19 },
          { "income": 361, "a": 0.2321, "b": 1.8961 },
          { "income": 932, "a": 0.3477, "b": 43.69 },
          { "income": 1188, "a": 0.3450, "b": 41.1734 },
          { "income": 3111, "a": 0.3900, "b": 94.6542 },
          { "income": 0, "a": 0.49, "b": 405.8080 }
        ]
  
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 1538, "a": 0.325, "b": 0.325 },
          { "income": 3461, "a": 0.37, "b": 69.2308 },
          { "income": 0, "a": 0.45, "b": 346.1538 }
        ]
  
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 20896, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 20896, "to": 26120, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 26120, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [
        {
          "name": "Temporary Budget Repair levy",
          "incremental": 1,
          "brackets": [
            { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
            { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 2.0 }
          ]
        }
  
      ],
      "superannuation": {
        "incremental": 0,
        "cap": 197720,
        "cap_max": 30000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
        ]
      },
      "division293": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 300000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 300000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 53345, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 53345, "to": 59421, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 59421, "to": 65497, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 65497, "to": 68939, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 68939, "to": 74105, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 74105, "to": 80257, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 80257, "to": 84481, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 84481, "to": 92970, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 92970, "to": 99069, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 99069, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 53345, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 53345, "to": 65497, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 65497, "to": 92970, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 92970, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
    {
      "year": "2016", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 355, "a": 0, "b": 0 },
          { "income": 395, "a": 0.19, "b": 67.4635 },
          { "income": 464, "a": 0.29, "b": 106.9673 },
          { "income": 711, "a": 0.21, "b": 67.4642 },
          { "income": 1282, "a": 0.3477, "b": 165.4431 },
          { "income": 1538, "a": 0.3450, "b": 161.9815 },
          { "income": 3461, "a": 0.3900, "b": 231.2123 },
          { "income": 0, "a": 0.49, "b": 577.3662 }
        ]
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
        "payg": [
          { "income": 45, "a": 0.19, "b": 0.19 },
          { "income": 361, "a": 0.2321, "b": 1.8961 },
          { "income": 932, "a": 0.3477, "b": 43.69 },
          { "income": 1188, "a": 0.3450, "b": 41.1734 },
          { "income": 3111, "a": 0.3900, "b": 94.6542 },
          { "income": 0, "a": 0.49, "b": 405.8080 }
        ]
  
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 1538, "a": 0.325, "b": 0.325 },
          { "income": 3461, "a": 0.37, "b": 69.2308 },
          { "income": 0, "a": 0.47, "b": 415.3846 }
        ]
  
  
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 21335, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 21335, "to": 26668, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 26668, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [
        {
          "name": "Temporary Budget Repair levy",
          "incremental": 1,
          "brackets": [
            { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
            { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 2.0 }
          ]
        }
  
      ],
      "superannuation": {
        "incremental": 0,
        "cap": 203240,
        "cap_50": 35000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
        ]
      },
      "division293": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 300000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 300000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 54126, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 54126, "to": 60293, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 60293, "to": 66457, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 66457, "to": 69950, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 69950, "to": 75191, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 75191, "to": 81433, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 81433, "to": 85719, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 85719, "to": 94332, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 94332, "to": 100520, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 100520, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "help_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 35926, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 35926, "to": 42093, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 42093, "to": 48257, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 48257, "to": 51750, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 51750, "to": 56991, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 56991, "to": 63233, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 63233, "to": 67519, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 67519, "to": 76132, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 76132, "to": 82320, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 82320, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 54126, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 54126, "to": 66456, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 66456, "to": 94331, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 94331, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      },
      "sfss_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 35926, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 35926, "to": 48257, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 48257, "to": 76132, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 76132, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
  
  {
    "year": "2017", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0, "b": 0 },
        { "income": 410, "a": 0.19, "b": 67.4635 },
        { "income": 512, "a": 0.29, "b": 108.4923 },
        { "income": 711, "a": 0.21, "b": 67.4646 },
        { "income": 1282, "a": 0.3477, "b": 165.4435 },
        { "income": 1673, "a": 0.3450, "b": 161.9819 },
        { "income": 3461, "a": 0.3900, "b": 237.2704 },
        { "income": 0, "a": 0.49, "b": 583.4242 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      "payg": [
        { "income": 60, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2332, "b": 2.6045 },
        { "income": 932, "a": 0.3477, "b": 44.0006 },
        { "income": 1323, "a": 0.3450, "b": 41.4841 },
        { "income": 3111, "a": 0.3900, "b": 101.0225 },
        { "income": 0, "a": 0.49, "b": 412.1764 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1673, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 75.2885 },
        { "income": 0, "a": 0.47, "b": 421.4423 }
      ]


    },
    "taxBackpacker": {
      "label": "Backpaker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },


    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly":{
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1699, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3499, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly":{
        "brackets": [
          { "from": 0, "to": 1399, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3399, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6899, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },




    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32280, "to": 50119, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28975, "to": 83580, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 83580, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31280, "to": 95198, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 95199, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 21335, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 21335, "to": 26668, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 26668, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41265, "to": 51581, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "spouseOnly": 13886,
      "dependantOne": 17357,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44272, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180001, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210001, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },




    "other": [
      {
        "name": "Temporary Budget Repair levy",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 2.0 }
        ]
      }

    ],
    "superannuation": {
      "incremental": 0,
      "concessionalCap": 30000,
      "nonConcesssionalCap": 180000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },
    "division293": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 54869, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 54869, "to": 61120, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 61120, "to": 67369, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 67369, "to": 70910, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 70910, "to": 76223, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 76223, "to": 82551, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 82551, "to": 86895, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 86895, "to": 95627, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 95627, "to": 101900, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 101900, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
      ]
    },
    "help_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 36669, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 36669, "to": 42920, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 42920, "to": 49169, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 49169, "to": 52710, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 52710, "to": 58023, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 58023, "to": 64351, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 64351, "to": 68695, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 68695, "to": 77427, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 77427, "to": 83700, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 83700, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
      ]
    },
    "sfss": {
      "label": "Student Financial Supplement Scheme",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 54869, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 54869, "to": 67368, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 67368, "to": 95626, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 95626, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    },
    "sfss_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 36669, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 36669, "to": 49169, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 49169, "to": 77427, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77427, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    }
  },

*/
  {
    "year": "2018", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0.0, "b": 0.0 },
        { "income": 416, "a": 0.19, "b": 67.4635 },
        { "income": 520, "a": 0.29, "b": 109.1077 },
        { "income": 711, "a": 0.21, "b": 67.4646 },
        { "income": 1282, "a": 0.3477, "b": 165.4435 },
        { "income": 1673, "a": 0.3450, "b": 161.9819 },
        { "income": 3461, "a": 0.39, "b": 237.2704 },
        { "income": 0, "a": 0.47, "b": 514.1935 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      "payg": [
        { "income": 66, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2337, "b": 2.9035 },
        { "income": 932, "a": 0.3477, "b": 44.1189 },
        { "income": 1323, "a": 0.3450, "b": 41.6024 },
        { "income": 3111, "a": 0.3900, "b": 101.1408 },
        { "income": 0, "a": 0.47, "b": 350.0639 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1673, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 75.2885 },
        { "income": 0, "a": 0.45, "b": 352.2115 }
      ]


    },
    "taxBackpacker": {
      "label": "Backpaker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },


    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1699, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3499, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1399, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3399, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6899, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },



    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32280, "to": 50119, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28975, "to": 83580, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 83580, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31280, "to": 95198, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 95199, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 21980, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 21980, "to": 27475, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 27475, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41265, "to": 51581, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "spouseOnly": 13886,
      "dependantOne": 17357,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44272, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180001, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210001, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },


    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate":9.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 38564,
      "maxIncome": 53564,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "division293": {
      "incremental": 1,
      "threshold":250000,
      "rate":15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 55873, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 55873, "to": 62238, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 62238, "to": 68602, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 68602, "to": 72207, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 72207, "to": 77618, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 77618, "to": 84062, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 84062, "to": 88486, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 88486, "to": 97377, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 97377, "to": 103765, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 103765, "to": 0, "type": "percent", "nearest": 1, "value": 8.0 }
      ]
    },
    "help_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 36674, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 36674, "to": 44038, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 44038, "to": 50402, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 50402, "to": 54007, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 54007, "to": 59418, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 59418, "to": 65862, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 65862, "to": 70286, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 70286, "to": 79177, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 79177, "to": 85565, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 85565, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
      ]
    },
    "sfss": {
      "label": "Student Financial Supplement Scheme",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 55874, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 55874, "to": 68602, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 68602, "to": 97377, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 97377, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    },
    "sfss_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37674, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 37674, "to": 50402, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 50402, "to": 79177, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 79177, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    }
  },



  {
    "year": "2019", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18201, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0.0, "b": 0.0 },
        { "income": 422, "a": 0.19, "b": 67.4635 },
        { "income": 528, "a": 0.29, "b": 109.7327 },
        { "income": 711, "a": 0.21, "b": 67.4635 },
        { "income": 1282, "a": 0.3477, "b": 165.4423 },
        { "income": 1730, "a": 0.3450, "b": 161.9808 },
        { "income": 3461, "a": 0.39, "b": 239.8654 },
        { "income": 0, "a": 0.47, "b": 516.7885 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 72, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2342, "b": 3.2130 },
        { "income": 932, "a": 0.3477, "b": 44.2476 },
        { "income": 1380, "a": 0.345, "b": 41.7311 },
        { "income": 3111, "a": 0.39, "b": 103.8657 },
        { "income": 0, "a": 0.47, "b": 352.7888 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1730, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 77.8846 },
        { "income": 0, "a": 0.45, "b": 354.8077 }
      ]

    },
    "taxBackpacker": {
      "label": "Backpaker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1699, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3499, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1399, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3399, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6899, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },

    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37001, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66668, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37001, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48001, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90001, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126001, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32280, "to": 50119, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28975, "to": 83580, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 83580, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31280, "to": 95198, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 95199, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },
    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 22398, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 22398, "to": 27997, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 27998, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41265, "to": 51581, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "spouseOnly": 13886,
      "dependantOne": 17357,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44272, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180001, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210001, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },


    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate":9.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },

    "division293": {
      "incremental": 1,
      "threshold":250000,
      "rate":15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 51957, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 51957, "to": 57729, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57729, "to": 64306, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 64306, "to": 70881, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 70881, "to": 74607, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 74607, "to": 80197, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 80197, "to": 86855, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 86855, "to": 91425, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 91425, "to": 100613, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 100613, "to": 107213, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 107213, "to": 0, "type": "percent", "nearest": 1, "value": 8.0 }
      ],
    },
    "help_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 33757, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 33757, "to": 39529, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 39529, "to": 46106, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 46106, "to": 52681, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 52681, "to": 56407, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 56407, "to": 61997, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 61997, "to": 68655, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 68655, "to": 73225, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 73225, "to": 82413, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 82413, "to": 89013, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 89013, "to": 0, "type": "percent", "nearest": 1, "value": 8.0 }
      ],

    },
    "sfss": {
      "label": "Student Financial Supplement Scheme",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 51957, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 51958, "to": 64306, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 64306, "to": 91425, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 91425, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    },
    "sfss_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 33757, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 33757, "to": 46106, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 46106, "to": 72225, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 72225, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    }
  },

  {
    "year": "2020", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18201, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0.0, "b": 0.0 },
        { "income": 422, "a": 0.19, "b": 67.4635 },
        { "income": 528, "a": 0.29, "b": 109.7327 },
        { "income": 711, "a": 0.21, "b": 67.4635 },
        { "income": 1282, "a": 0.3477, "b": 165.4423 },
        { "income": 1730, "a": 0.3450, "b": 161.9808 },
        { "income": 3461, "a": 0.39, "b": 239.8654 },
        { "income": 0, "a": 0.47, "b": 516.7885 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 72, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2342, "b": 3.2130 },
        { "income": 932, "a": 0.3477, "b": 44.2476 },
        { "income": 1380, "a": 0.345, "b": 41.7311 },
        { "income": 3111, "a": 0.39, "b": 103.8657 },
        { "income": 0, "a": 0.47, "b": 352.7888 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1730, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 77.8846 },
        { "income": 0, "a": 0.45, "b": 354.8077 }
      ]

    },
    "taxBackpacker": {
      "label": "Backpaker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1699, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3499, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1399, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3399, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6899, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37001, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66668, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37001, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48001, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90001, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126001, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32280, "to": 50119, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28975, "to": 83580, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 83580, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31280, "to": 95198, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 95199, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },
    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 22398, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 22398, "to": 27997, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 27998, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41265, "to": 51581, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "spouseOnly": 13886,
      "dependantOne": 17357,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44272, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180001, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210001, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },


    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "cap": 221080,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate":9.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 38564,
      "maxIncome": 53564,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },


    "division293": {
      "incremental": 1,
      "threshold":250000,
      "rate":15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 45881, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 45881, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },

    "help_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 27680, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 27680, "to": 34774, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 34774, "to": 37952, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 37952, "to": 41322, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 41322, "to": 44893, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 44893, "to": 48678, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 48678, "to": 52691, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 52691, "to": 56945, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 56945, "to": 61453, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 61453, "to": 66233, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 66233, "to": 71299, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 71299, "to": 76669, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 76669, "to": 82361, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 82361, "to": 88394, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 88394, "to": 94790, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 94790, "to": 101570, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 101570, "to": 108756, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 108756, "to": 116373, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 116373, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss": {
      "brackets": [
        { "from": 0, "to": 45881, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 45881, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 27680, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 27680, "to": 34774, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 34774, "to": 37952, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 37952, "to": 41322, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 41322, "to": 44893, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 44893, "to": 48678, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 48678, "to": 52691, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 52691, "to": 56945, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 56945, "to": 61453, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 61453, "to": 66233, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 66233, "to": 71299, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 71299, "to": 76669, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 76669, "to": 82361, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 82361, "to": 88394, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 88394, "to": 94790, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 94790, "to": 101570, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 101570, "to": 108756, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 108756, "to": 116373, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 116373, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    }
  },



  {
    "year": "2021", "current": "true",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18201, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0.0, "b": 0.0 },
        { "income": 422, "a": 0.19, "b": 67.4635 },
        { "income": 528, "a": 0.29, "b": 109.7327 },
        { "income": 711, "a": 0.21, "b": 67.4635 },
        { "income": 1282, "a": 0.3477, "b": 165.4423 },
        { "income": 1730, "a": 0.3450, "b": 161.9808 },
        { "income": 3461, "a": 0.39, "b": 239.8654 },
        { "income": 0, "a": 0.47, "b": 516.7885 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 72, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2342, "b": 3.2130 },
        { "income": 932, "a": 0.3477, "b": 44.2476 },
        { "income": 1380, "a": 0.345, "b": 41.7311 },
        { "income": 3111, "a": 0.39, "b": 103.8657 },
        { "income": 0, "a": 0.47, "b": 352.7888 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1730, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 77.8846 },
        { "income": 0, "a": 0.45, "b": 354.8077 }
      ]

    },
    "taxBackpacker": {
      "label": "Backpaker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37001, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90001, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180001, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1699, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3499, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1399, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3399, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6899, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },



    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37001, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66668, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37001, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48001, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90001, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126001, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32280, "to": 50119, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28975, "to": 83580, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 83580, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31280, "to": 95198, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 95199, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },


    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 22398, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 22398, "to": 27997, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 27998, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41265, "to": 51581, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "spouseOnly": 13886,
      "dependantOne": 17357,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44272, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180001, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210001, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate":9.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },



    "superannuationCocontribution": {
      "minIncome": 38564,
      "maxIncome": 53564,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },



    "division293": {
      "incremental": 1,
      "threshold":250000,
      "rate":15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 46620, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 46620, "to": 53826, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 53827, "to": 57055, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57056, "to": 60479, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60480, "to": 64108, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64109, "to": 67954, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 67955, "to": 72031, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72032, "to": 76354, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 76355, "to": 80935, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 80936, "to": 85792, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 85793, "to": 90939, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 90940, "to": 96396, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 96397, "to": 102179, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 102180, "to": 108309, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108310, "to": 114707, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 114708, "to": 121698, "type": "percent", "nearest": 1, "value": 8.6 },
        { "from": 121699, "to": 128999, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 129000, "to": 136739, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 136740, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28420, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28420, "to": 35626, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 35627, "to": 38855, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 38856, "to": 42279, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42280, "to": 45908, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 45909, "to": 49754, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 49755, "to": 53821, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 53822, "to": 58154, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58155, "to": 62735, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 62736, "to": 67592, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 67593, "to": 72739, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 72740, "to": 78196, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 78197, "to": 83979, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 83980, "to": 90109, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 90110, "to": 96507, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 96508, "to": 103498, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 103499, "to": 110799, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 110800, "to": 118539, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 118540, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 46620, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 46620, "to": 53826, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 53827, "to": 57055, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57056, "to": 60479, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60480, "to": 64108, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64109, "to": 67954, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 67955, "to": 72031, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72032, "to": 76354, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 76355, "to": 80935, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 80936, "to": 85792, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 85793, "to": 90939, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 90940, "to": 96396, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 96397, "to": 102179, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 102180, "to": 108309, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108310, "to": 114707, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 114708, "to": 121698, "type": "percent", "nearest": 1, "value": 8.6 },
        { "from": 121699, "to": 128999, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 129000, "to": 136739, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 136740, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28420, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28420, "to": 35626, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 35627, "to": 38855, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 38856, "to": 42279, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42280, "to": 45908, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 45909, "to": 49754, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 49755, "to": 53821, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 53822, "to": 58154, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58155, "to": 62735, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 62736, "to": 67592, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 67593, "to": 72739, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 72740, "to": 78196, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 78197, "to": 83979, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 83980, "to": 90109, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 90110, "to": 96507, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 96508, "to": 103498, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 103499, "to": 110799, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 110800, "to": 118539, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 118540, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    }
  },


  {
    "year": "2022", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0.0, "b": 0.0 },
        { "income": 422, "a": 0.19, "b": 67.4635 },
        { "income": 528, "a": 0.29, "b": 109.7327 },
        { "income": 711, "a": 0.21, "b": 67.4635 },
        { "income": 1282, "a": 0.3477, "b": 165.4423 },
        { "income": 1730, "a": 0.3450, "b": 161.9808 },
        { "income": 3461, "a": 0.39, "b": 239.8654 },
        { "income": 0, "a": 0.47, "b": 516.7885 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 72, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2342, "b": 3.2130 },
        { "income": 932, "a": 0.3477, "b": 44.2476 },
        { "income": 1380, "a": 0.345, "b": 41.7311 },
        { "income": 3111, "a": 0.39, "b": 103.8657 },
        { "income": 0, "a": 0.47, "b": 352.7888 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1730, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 77.8846 },
        { "income": 0, "a": 0.45, "b": 354.8077 }
      ]

    },
    "taxBackpacker": {
      "label": "Backpaker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1699, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3499, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1399, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3399, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6899, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37000, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48000, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90000, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32280, "to": 50119, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28975, "to": 83580, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 83580, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31280, "to": 95198, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 95199, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 22398, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 22398, "to": 27997, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 27997, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },
    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },


    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41265, "to": 51581, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "spouseOnly": 13886,
      "dependantOne": 17357,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44272, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareSurchargeFamily": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180001, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210001, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },



    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate":10.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 38564,
      "maxIncome": 53564,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },


    "division293": {
      "incremental": 1,
      "threshold":250000,
      "rate":15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 46620, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 46620, "to": 53826, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 53827, "to": 57055, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57056, "to": 60479, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60480, "to": 64108, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64109, "to": 67954, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 67955, "to": 72031, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72032, "to": 76354, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 76355, "to": 80935, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 80936, "to": 85792, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 85793, "to": 90939, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 90940, "to": 96396, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 96397, "to": 102179, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 102180, "to": 108309, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108310, "to": 114707, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 114708, "to": 121698, "type": "percent", "nearest": 1, "value": 8.6 },
        { "from": 121699, "to": 128999, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 129000, "to": 136739, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 136740, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28420, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28420, "to": 35626, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 35627, "to": 38855, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 38856, "to": 42279, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42280, "to": 45908, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 45909, "to": 49754, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 49755, "to": 53821, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 53822, "to": 58154, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58155, "to": 62735, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 62736, "to": 67592, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 67593, "to": 72739, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 72740, "to": 78196, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 78197, "to": 83979, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 83980, "to": 90109, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 90110, "to": 96507, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 96508, "to": 103498, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 103499, "to": 110799, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 110800, "to": 118539, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 118540, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 46620, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 46620, "to": 53826, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 53827, "to": 57055, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57056, "to": 60479, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60480, "to": 64108, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64109, "to": 67954, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 67955, "to": 72031, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72032, "to": 76354, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 76355, "to": 80935, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 80936, "to": 85792, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 85793, "to": 90939, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 90940, "to": 96396, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 96397, "to": 102179, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 102180, "to": 108309, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108310, "to": 114707, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 114708, "to": 121698, "type": "percent", "nearest": 1, "value": 8.6 },
        { "from": 121699, "to": 128999, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 129000, "to": 136739, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 136740, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28420, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28420, "to": 35626, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 35627, "to": 38855, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 38856, "to": 42279, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42280, "to": 45908, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 45909, "to": 49754, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 49755, "to": 53821, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 53822, "to": 58154, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58155, "to": 62735, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 62736, "to": 67592, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 67593, "to": 72739, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 72740, "to": 78196, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 78197, "to": 83979, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 83980, "to": 90109, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 90110, "to": 96507, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 96508, "to": 103498, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 103499, "to": 110799, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 110800, "to": 118539, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 118540, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    }
  },
  /*
    {
      "year": "2023", "current": "false",
  
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 41000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 41000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 41000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 41000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
      "taxBackpacker": {
        "label": "Backpaker",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 41000, "type": "percent", "nearest": 1, "value": 15 },
          { "from": 41000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
  
  
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "645" },
          { "from": 37000, "to": 41000, "type": "rate", "nearest": 1, "start": 645, "end": 0, "value": -6.5 },
          { "from": 41000, "to": 66667, "type": "rate", "nearest": 1, "start": 385, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
  
  
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 22398, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 22398, "to": 27997, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 27997, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [
      ],
      "superannuation": {
        "incremental": 0,
        "concessionalCap": 25000,
        "nonConcesssionalCap": 100000,
        "nonConcessionalExcessTax": 0.47,
        "concessionalTax": 0.15,
        "rate":10.5,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 10.5 }
        ]
      },
  
      "division293": {
        "incremental": 1,
        "threshold":250000,
        "rate":15,
        "brackets": [
          { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label": "HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 45880, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 45880, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
          { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
          { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
          { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
          { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
          { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
          { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
          { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
          { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
          { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
          { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
          { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
          { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
        ],
      },
      "help_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 27680, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 27680, "to": 34774, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 34774, "to": 37952, "type": "percent", "nearest": 1, "value": 2.0 },
          { "from": 37952, "to": 41322, "type": "percent", "nearest": 1, "value": 2.5 },
          { "from": 41322, "to": 44893, "type": "percent", "nearest": 1, "value": 3.0 },
          { "from": 44893, "to": 48678, "type": "percent", "nearest": 1, "value": 3.5 },
          { "from": 48678, "to": 52691, "type": "percent", "nearest": 1, "value": 4.0 },
          { "from": 52691, "to": 56945, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 56945, "to": 61453, "type": "percent", "nearest": 1, "value": 5.0 },
          { "from": 61453, "to": 66233, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 66233, "to": 71299, "type": "percent", "nearest": 1, "value": 6.0 },
          { "from": 71299, "to": 76669, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 76669, "to": 82361, "type": "percent", "nearest": 1, "value": 7.0 },
          { "from": 82361, "to": 88394, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 88394, "to": 94790, "type": "percent", "nearest": 1, "value": 8.0 },
          { "from": 94790, "to": 101570, "type": "percent", "nearest": 1, "value": 8.5 },
          { "from": 101570, "to": 108756, "type": "percent", "nearest": 1, "value": 9.0 },
          { "from": 108756, "to": 116373, "type": "percent", "nearest": 1, "value": 9.5 },
          { "from": 116373, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
        ],
      },
      "sfss": {
        "label": "SFSS",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 45881, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 45881, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
          { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
          { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
          { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
          { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
          { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
          { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
          { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
          { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
          { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
          { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
          { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
          { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
        ],
      },
      "sfss_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 33757, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 33757, "to": 46106, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 46106, "to": 72225, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 72225, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
  
  
    {
      "year": "2024", "current": "false",
  
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 41000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 41000, "to": 200000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 200000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 41000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 41000, "to": 200000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 200000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 200000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 200000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
      "taxBackpacker": {
        "label": "Backpaker",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
          { "from": 37000, "to": 200000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 200000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
      },
  
  
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "645" },
          { "from": 37000, "to": 41000, "type": "rate", "nearest": 1, "start": 645, "end": 0, "value": -6.5 },
          { "from": 41000, "to": 66667, "type": "rate", "nearest": 1, "start": 385, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
  
  
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 22398, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 22398, "to": 27997, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 27997, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
        ]
      },
  
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [
      ],
      "superannuation": {
        "incremental": 0,
        "concessionalCap": 25000,
        "nonConcesssionalCap": 100000,
        "nonConcessionalExcessTax": 0.47,
        "concessionalTax": 0.15,
        "rate":11.0,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 11.0 }
        ]
      },
      "division293": {
        "incremental": 1,
        "threshold":250000,
      "rate":15,
        "brackets": [
          { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label": "HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 45880, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 45880, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
          { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
          { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
          { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
          { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
          { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
          { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
          { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
          { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
          { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
          { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
          { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
          { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
        ],
      },
      "help_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 27680, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 27680, "to": 34774, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 34774, "to": 37952, "type": "percent", "nearest": 1, "value": 2.0 },
          { "from": 37952, "to": 41322, "type": "percent", "nearest": 1, "value": 2.5 },
          { "from": 41322, "to": 44893, "type": "percent", "nearest": 1, "value": 3.0 },
          { "from": 44893, "to": 48678, "type": "percent", "nearest": 1, "value": 3.5 },
          { "from": 48678, "to": 52691, "type": "percent", "nearest": 1, "value": 4.0 },
          { "from": 52691, "to": 56945, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 56945, "to": 61453, "type": "percent", "nearest": 1, "value": 5.0 },
          { "from": 61453, "to": 66233, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 66233, "to": 71299, "type": "percent", "nearest": 1, "value": 6.0 },
          { "from": 71299, "to": 76669, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 76669, "to": 82361, "type": "percent", "nearest": 1, "value": 7.0 },
          { "from": 82361, "to": 88394, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 88394, "to": 94790, "type": "percent", "nearest": 1, "value": 8.0 },
          { "from": 94790, "to": 101570, "type": "percent", "nearest": 1, "value": 8.5 },
          { "from": 101570, "to": 108756, "type": "percent", "nearest": 1, "value": 9.0 },
          { "from": 108756, "to": 116373, "type": "percent", "nearest": 1, "value": 9.5 },
          { "from": 116373, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
        ],
      },
      "sfss": {
        "label": "SFSS",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 45881, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 45881, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
          { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
          { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
          { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
          { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
          { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
          { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
          { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
          { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
          { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
          { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
          { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
          { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
        ],
      },
      "sfss_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 33757, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 33757, "to": 46106, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 46106, "to": 72225, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 72225, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    }*/

]
