import React from 'react';
import {
    Box,
    Typography,
    makeStyles,
    List,
    ListItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        contentWrapper: {
            backgroundColor: theme.palette.background.default,
            padding: 20,
        },
        intro: {
            padding: '10px 40px',
            textAlign: "center",
            paddingBottom: 20,
            opacity: 0.66,
        },
        heading: {
            padding: 20,
            paddingTop: 100,
            paddingBottom: 20,
            textalign: "center"
        },
        subHeading: {
            padding: 20,
            paddingTop: 40,
            paddingBottom: 0,
            textalign: "left",
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
        },

        subSubHeading: {
            padding: 20,
            paddingBottom: 0,
            textalign: "left",
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.background.paper,
        },
        content: {
            padding: 20,
            textalign: "left",
            backgroundColor: theme.palette.background.paper,
            marginBottom: 0,
        },

        list:{
            padding: 0,
            textalign: "left",
            lineHeight:1,
            backgroundColor: theme.palette.background.paper,
        },
        underline: {
            height: 1,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
            margin: '5px 0px 0 0',
        },

        table: {
            padding: '0 20px 20px 20px',
            minWidth: 100,
        },
    }
});

export const Intro = (props) => {
    const classes = useStyles();
    return (
      <Box display='flex' flexGrow={1} className={classes.intro} justifyContent="center" >
        <Typography variant="subtitle1">{props.children}</Typography>
      </Box>
    )
  }

export const Heading = (props) => {
    const classes = useStyles();
    return (
      <Box display='flex' flexGrow={1} className={classes.heading} justifyContent="center" >
        <Typography variant="h1">{props.children}</Typography>
      </Box>
    )
  }
  
  export const SubHeading = (props) => {
    const classes = useStyles();

    return (
      <Box display='flex' flexGrow={1} justifyContent="flex-start" flexDirection="column" className={classes.subHeading} >
        <Typography variant="h4">{props.children}</Typography>
        <Box display='flex' flexGrow={1} className={classes.underline} />
      </Box>
    )
  }

  export const SubSubHeading = (props) => {
    const classes = useStyles();
    return (
        <Box display='flex' flexGrow={1} className={classes.subSubHeading} justifyContent="flex-start" flexDirection="column">
            <Typography variant="h2">{props.children}</Typography>
        </Box>
    )
}
  
  
  export const Content = (props) => {
    const classes = useStyles();

    return (
      <div className={classes.content} >
        <Typography variant="body1">{props.children}</Typography>
      </div>
    )
  }


  export  const Listing = (props) => {
    const classes = useStyles();
    return (
      <div className={classes.list} justifyContent="flex-start" >
        <List dense>
        <Typography variant="body1">
            {props.children}
         </Typography>
        </List>
      </div>
    )
  }

  export const ListingItem = (props) => {
    return (
        <ListItem>
                  {/* <ListItemIcon>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemIcon> */}
                  <Typography variant="h4">·&nbsp;&nbsp;</Typography>
                   {props.children}
        </ListItem>
    )
  }
