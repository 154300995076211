import React, { useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Grid,
  Select,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  fade,
} from '@material-ui/core';

import { StyledFormControl, StyledLink } from '../common/CustomComponents'
import { PANEL_HEADER_HEIGHT, HELP_REPAYMENT_SOURCE } from '../../constants';
import { StudentLoanContext } from './StudentLoanComponent';
import { taxData } from '../../core/calculatorData';
import { formatMoney } from '../../utils/utils';


const StyledTableCell = withStyles(theme => ({
  head: {

    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.dark,
    borderBottom: 0,
    height: PANEL_HEADER_HEIGHT,

    '&:nth-child(2)': {
      textAlign: "center",
    },
    '&:nth-child(3)': {
      whiteSpace: 'nowrap',
    },
  },
  body: {
    padding: '12px 20px',
    borderBottom: 0,
    fontSize: 13,
    '&:nth-child(2)': {
      textAlign: "center",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => {

  const bg = fade(theme.palette.background.overlay, 0.4);
  const bg_alt = fade(theme.palette.background.overlay, 0.2);
  return {
    root: {

      backgroundColor: bg,
      '&:nth-of-type(odd)': {
        backgroundColor: bg_alt,
      },
    },
  }
})(TableRow);


function createData(bracket, rate) {
  return { bracket, rate };
}

// const rows = [
//   createData('$0 -\u00A0$18,200', '$0', '0%'),
//   createData('$18,201 –\u00A0$37,000', '19¢ for each $1 over $18,200	', '19%'),
//   createData('$37,001 –\u00A0$90,000	', '$3,572 plus 32.5¢ for each $1 over $37,000', '32.5%'),
//   createData('$90,001 -\u00A0$180,000', '$20,797 plus 37¢ for each $1 over $90,000	', '37%'),
//   createData('Over\u00A0$180,000', '$54,097 plus 45¢ for each $1 over $180,000	', '45%'),
// ];

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

export const StudentLoanTable = () => {
  const classes = useStyles();

  const { year, setCurrentYear, dataCategories, category } = useContext(StudentLoanContext);

  const handleChangeYear = () => event => {
    setCurrentYear(event.target.value)
  }

  const currentTaxData = taxData.find(c => c.year === year);
  let taxObject;

  for (const key of Object.keys(currentTaxData)) {
    if (currentTaxData[key].label && currentTaxData[key].label === category) {
      taxObject = currentTaxData[key];
    }
  }

  const taxRates = taxObject.brackets;

  const rows = taxRates.map(b => {

    // price formatting!!!
    // label
    let label = `$${formatMoney(b.from, 0, ".", ",")} - $${formatMoney(b.to, 0, ".", ",")}`
    if (b.to === 0) label = `Over $${formatMoney(b.from - 1, 0, ".", ",")}`;

    // rate
    const rate = `${b.value}%`;

    return createData(label, rate);

  })

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={4} style={{ paddingBottom: 20 }}>
        <StyledFormControl variant="filled" fullWidth >
          <InputLabel htmlFor="taxYear">Tax year</InputLabel>
          <Select
            native
            variant="filled"
            id="year"
            onChange={handleChangeYear()}
            value={year}
          >
            {dataCategories.map((option, index) => (
              <option
                key={option.year + " " + index}
                data={option.year}
                value={option.year}
              >
                {`${option.year - 1} - ${Number(option.year) - 2000}`}
              </option>
            ))}
          </Select>
        </StyledFormControl>

      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Tax table">
            <TableHead>
              <TableRow>
                <StyledTableCell><Typography variant="h5">Annual Salary</Typography></StyledTableCell>
                <StyledTableCell align="right"><Typography variant="h5">Repayment Rate</Typography></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <StyledTableRow key={row.bracket}>
                  <StyledTableCell component="th" scope="row">{row.bracket}</StyledTableCell>
                  <StyledTableCell align="right">{row.rate}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Box style={{ padding: 20 }}>
          <StyledLink href={HELP_REPAYMENT_SOURCE}>Source</StyledLink>
        </Box>
      </Grid>
    </Grid>
  );
}