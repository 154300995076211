import React, { useRef, useEffect, useContext } from "react";
import {
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
    fade,
} from '@material-ui/core';

import { useWindowSize } from '../../../utils/hooks';
import placeholder from '../../../assets/placeholder/TaxShareChart.png';
import { formatMoney } from '../../../utils/utils';
import { taxDistribution } from '../../../core/taxDistribution';
import { DistributionContext } from './Distribution';


const getPixelRatio = context => {
    let backingStore =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;

    return (window.devicePixelRatio || 1) / backingStore;
};

const useStyles = makeStyles(theme => {
    return {
        image: {
            width: '100%',
            paddingBottom: 20,
        },

    }
});

export const colorSequence = [
    "palette.primary.100",
    "palette.primary.200",
    "palette.primary.300",
    "palette.primary.400",
    "palette.primary.500",
    "palette.secondary.100",
    "palette.secondary.200",
    "palette.secondary.300",
    "palette.secondary.400",
    "palette.secondary.500",
    "palette.tertiary.100",
    "palette.tertiary.200",
    "palette.tertiary.300",
    "palette.tertiary.400",
    "palette.tertiary.500",
    "palette.grey.700",
    "palette.grey.600",
    "palette.grey.500",
    "palette.grey.400",
]

export const DistributionChart = ( props ) => {
    const ref = useRef();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));

    const { tax } = useContext(DistributionContext);
    
    useWindowSize();

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');

        // resize the canvas to the parent node
        canvas.style.width = '100%';
        canvas.style.height = '100%';

        let ratio = getPixelRatio(context);
        let w = getComputedStyle(canvas)
            .getPropertyValue('width')
            .slice(0, -2);
        let h = getComputedStyle(canvas)
            .getPropertyValue('height')
            .slice(0, -2);

        // reset the new values
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        drawIncome(context, canvas, theme, ratio, tax);
    });


    let height = 500;
    height = xs ? window.innerWidth * 1.0 : height;
    height = sm ? window.innerWidth * 0.7 : height;


    return (
        <Box >
            <Box display="flex" flexDirection="column" flexGrow={1} style={{ height: `${height}px`, width: '100%' }}>
                <canvas ref={ref} width={'100%'} height={`${height}px`} />
            </Box>
        </Box>
    );
}


export const DistributionChartPlaceholder = () => {
    const classes = useStyles();

    return (
        <img src={placeholder} alt="placeholder" className={classes.image} />
    )

}


const drawIncome = (ctx, canvas, theme, ratio, tax) => {
    let cx = canvas.width / 2;
    let cy = canvas.width / 2.2;
    let r = canvas.width / 2.85;
    let t = canvas.width * 0.18;

    var primaryGradient = ctx.createLinearGradient(cx - r, cy - r, cx + r, cy + r);
    primaryGradient.addColorStop("0", `${theme.palette.primary[400]}`);
    primaryGradient.addColorStop("0.4", `${theme.palette.primary[200]}`);
    primaryGradient.addColorStop("1.0", `${theme.palette.primary[100]}`);

    var glossGradient = ctx.createLinearGradient(cx - r, cy - r, cx + r, cy + r);
    glossGradient.addColorStop("0", `rgba(255,255,255,0.25)`);
    glossGradient.addColorStop("1.0", `rgba(255,255,255,0.02)`);

    const textColor = theme.palette.text.primary
    const textSubColor = fade(theme.palette.text.primary, 0.6)
    const shadowColor = fade(theme.palette.grey[900], 0.15)

    // const income = calculator.income.a - calculator.superannuation.a;
    // const taxRatio = calculator.incomeTax.a / income
    // const medicareRatio = calculator.medicare.a / income
    // const studentLoanRatio = (calculator.help.a + calculator.sfss.a) / income
    // const offsets = calculator.offsets.a / income

    // shadow
    const shadowT = t + 20;
    ctx.beginPath();
    ctx.arc(cx + 4, cy + 4, r, 0, 2 * Math.PI);
    ctx.strokeStyle = shadowColor;
    ctx.lineWidth = shadowT;
    ctx.stroke();

    let fromAngle = 0 - Math.PI*0.25
    let toAngle = fromAngle;
    const RADIANS = 2 * Math.PI;

    // for every function
    taxDistribution.functions.map((fn, index) => {
        // draw an arc
        // draw a divider line
        const segmentPercent = fn.allocation / taxDistribution.total;
        toAngle = fromAngle + (segmentPercent * RADIANS);
        const c = colorSequence[index % colorSequence.length].split(".");

        ctx.beginPath();
        ctx.arc(cx, cy, r, fromAngle, toAngle);
        ctx.strokeStyle = theme[c[0]][c[1]][c[2]];

        ctx.lineWidth = t;
        ctx.stroke();
        fromAngle = toAngle;
        return true;
    })


    // gloss
    const glossT = t * 0.5;
    const glossR = r + glossT * 0.5;
    ctx.beginPath();
    ctx.arc(cx, cy, glossR, 0, 2 * Math.PI);
    ctx.strokeStyle = glossGradient;
    ctx.lineWidth = glossT;
    ctx.stroke();


    // labels
    const formattedValue = "$" + formatMoney(tax, 0, ".", ",");

    //{PriceFormat({ value: allocation, displayType: "text" })}

    ctx.font = `${theme.typography.body1.fontSize * ratio}px ${theme.typography.body1.fontFamily[0]}`;
    ctx.fillStyle = textSubColor;
    ctx.textAlign = "center";
    ctx.fillText("Total Tax", cx, cy - r * 0.18);

    ctx.font = `${theme.typography.h1.fontSize * ratio}px ${theme.typography.h1.fontFamily[0]}`;
    ctx.fillStyle = textColor;
    ctx.textAlign = "center";
    ctx.fillText(formattedValue, cx, cy);

    ctx.font = `${theme.typography.body1.fontSize * ratio}px ${theme.typography.body1.fontFamily[0]}`;
    ctx.fillStyle = textSubColor;
    ctx.textAlign = "center";
    ctx.fillText("per year", cx, cy + r * 0.15);

}
