import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    fade,
} from '@material-ui/core';

import { StudentLoanComponent } from '../components/studentLoan/StudentLoanComponent'
import { RepaymentCalculator } from '../components/studentLoan/RepaymentCalculator'
import {
    HECS_HELP_INFO,
    FEE_HELP_INFO,
    OS_HELP_INFO,
    SA_HELP_INFO,
    SSL_INFO,
    TSL_INFO,
    CPS_INFO,
    MYSKILLS_INFO,
    VET_INFO,
    APPRENTISHIP_INFO,
    OVERSEAS_REPAYMENT_INFO,
    WORLDWIDE_INCOME_INFO,
    NON_LODGEMENT_INFO,
 } from '../constants';

import AuxilleryPage from './AuxilleryPageLayout';
import { Heading, Content, SubHeading, SubSubHeading, Intro } from '../components/common/LayoutComponents';

const useStyles = makeStyles((theme) => {

    return {
        contentWrapper: {
            backgroundColor: theme.palette.background.default,
            padding: 0,
          },
          
        table: {
            padding: 20,
            minWidth: 100,
            backgroundColor: theme.palette.background.paper,
        },

        feature: {
            padding: 20,
            backgroundColor: theme.palette.background.paper,
        },
    }
});


const StyledTableRow = withStyles(theme => {

    const bg = fade(theme.palette.background.overlay, 0.4);
    const bg_alt = fade(theme.palette.background.overlay, 0.2);
    return {
        root: {
            borderBottom: 0,
            backgroundColor: bg,

            '&:nth-of-type(odd)': {
                backgroundColor: bg_alt,
            },
            ' & td': {
                fontSize: 14,
                borderBottom: 0,
            }
        },
    }
})(TableRow);


const StudentLoansPage = () => {
    const classes = useStyles();
    
    function createData(type, link, description) {
        return { type, link, description };
    }

    const HELP_TABLE = [
        createData(
            'HECS-HELP',
            HECS_HELP_INFO, 'Higher education loans for students enrolled in institutions that are subsidised by the Australian Government, also known as a Commonwealth Supported Place (CPS)'),

        createData(
            'FEE-HELP',
            FEE_HELP_INFO, 'Subsidised loans for students enrolled in full-fee courses'),

        createData(
            'OS-HELP',
            OS_HELP_INFO, 'Available to students enrolled in a CPS course and wish to study overseas'),

        createData(
            'SA-HELP',
            SA_HELP_INFO, 'Supplementary loan used to cover student services and amenities such as sporting activities and child care'),

    ];


    return (
        <AuxilleryPage >
            <Heading>Student Loan repayments</Heading>
            <Intro>
                Calculate how your government student loan programs are repaid
      </Intro>

            <SubHeading>Loan Repayments</SubHeading>
            <Content>
                Repayment thresholds and rates for all student loans including HELP, HECS, VET, SSL, ABSTUDY, TSL and SFSS are updated annually. Since July 2019 all types of student loan and the Student Financial Supplement Scheme (SFSS) have been consolidated under the same repayment threshold. Use the table and chart below to see how your salary effects you loan repayments.
                </Content>
                <Box className = {classes.feature}>
            <StudentLoanComponent />
            </Box>
            <SubHeading>Loan Repayment Calculator</SubHeading>
            <Content>
                Use this tool to calculate how long it will take to repay your loan. Input your current loan amount as it is today.
                </Content>
                <Box className = {classes.feature}>
            <RepaymentCalculator />
            </Box>


            <SubHeading>Types of Student Loans</SubHeading>
                <Content>
                There are currently a number of different types of loans that the Australian Government makes available to support the completion of training and study. It is compulsory to repay the loans and repayments are calculated on your taxable income.
                
             </Content>

            <SubSubHeading>Higher Education Loan Program (HELP) </SubSubHeading>
            <Content>
                HELP loans are only available from tertiary education institutions such as Universities or other registered training organisations. The scheme has specific categories based on considerations based on the educational institution and the students course.
                </Content>

                <Box className={classes.table}>
                <TableContainer component={Paper}>
                    <Table aria-label="Tax table">
                        <TableBody>
                            {HELP_TABLE.map(row => (
                                <StyledTableRow key={row.type}>
                                    <TableCell align="center" style={{minWidth:160}}>
                                        <Link href={`${row.link}`} variant="body1">
                                            {row.type}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left">{row.description}</TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Box>

                <Content>
               A CPS is an Australian Government approved higher education provider such as a University. Prior to 2005 a CPS was known as a HECS place. The <Link href={CPS_INFO}>Commonwealth supported places (CSP) and Higher Education Loan Program (HELP) handbook</Link> is a useful resource about CPSs and HELP loans.
                </Content>

        <SubSubHeading>Student Financial Supplement Scheme (SFSS)</SubSubHeading>
            <Content>
            The Student Financial Supplement Scheme (SFSS) was a loan scheme to help tertiary students with their expenses while they were studying. The scheme has been abandoned and no new loans have been issued since 1 January 2004. All existing SFSS debts continue to be collected through the tax system.

            Since 1 July 2019, all study and training loans have been combined under a single repayment rate.
        </Content>


        <SubSubHeading>Vocational Education & Training Student Loan (VSL)</SubSubHeading>
            <Content>
            VET Student Loans began on 1 January 2017 and replaced VET FEE-HELP.
            This loan is available for students enrolled in vocational and training courses at a diploma level (Certificate V and above). The providers are limited and can be searched on the <Link href={MYSKILLS_INFO} title="My skills" >MySkills</Link> site. More information on specific information on loan applications and loan caps can be found on the <Link href={VET_INFO} title="My skills" >VET Student loans</Link> site.
        </Content>

        <SubSubHeading>Student Start-up Loan (SSL) & ABSTUDY Student Start-up Loan (ABSTUDY SSL)</SubSubHeading>
            <Content>
            A <Link href={SSL_INFO} title="Student Start-up Loan" >Student Start-up Loan</Link> is available to University students who receive the Youth Allowance, Austudy, or ABSTUDY Living Allowance. It's a loan of $1094 paid twice a year. The payment is added to you HECS-HELP loan debt. It is not available to VET or TAFE courses and has replaced the Student Start-up Scholarship payment.
        </Content>

        <SubSubHeading>Trade Support Loan (TSL)</SubSubHeading>
            <Content>
                TSL offers financial support to eligible Australian apprentices. The loan is available for 4 years and is discounted by 20% on the completion of an apprenticeship. These income-contingent loans offer up to $21,078 in financial support. There are a number of conditions on the loans and the occupations are limited. Further information can be found on the <Link href={APPRENTISHIP_INFO} title="Australian Apprenticeships">Australian Apprenticeships</Link> site.
                <br /><br/>
                Under the Australian Apprenticeships Incentives Program, employers of apprentices may also be eligible to receive $2,000 after the first year and $2,000 on completion for each apprentice.
                <br /><br/>
                Additional Identified Skills Shortage (<Link href={TSL_INFO} title="AISS">AISS</Link>) is an additional payment offered to the apprentice and their employer to encourage apprenticeships in 10 key occupations. 
        </Content>


        <SubHeading>Overseas obligations</SubHeading>                     

        <Content>
            If you have an existing study or training loan including, HELP, HECS, VSL, TSL, and you move overseas to live and work for more than 183 days a year, you must inform the government. You will be required to submit an <Link href={OVERSEAS_REPAYMENT_INFO} tite="Overseas travel notification">Overseas travel notification</Link> within 7 days of your departure. At the end of the financial year you will also be required to either <Link href={WORLDWIDE_INCOME_INFO}>report your worldwide income</Link> or submit <Link href={NON_LODGEMENT_INFO}title="non-lodgement advice">non-lodgement advice</Link>. This can be done through the myGov site or with an Australian registered tax agent.

            <br /><br />
        </Content>
    </AuxilleryPage>
    )
}


export default StudentLoansPage;
