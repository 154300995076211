import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ComponentHeader, {SectionHeader} from './HeaderBar';

const useStyles = makeStyles(theme => (
    {
        bg: {
            backgroundColor: theme.palette.background.paper,
            marginBottom:20,
            borderRadius: 10,
        },

    }
));

const ComponentWrapper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.bg}>
            <ComponentHeader title={props.title} />
            {props.children}
        </div>
    )
}

export const SectionWrapper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.bg}>
            <SectionHeader title={props.title} />
            {props.children}
        </div>
    )
}

export default ComponentWrapper;
