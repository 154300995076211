import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

import { CustomTinyButton, AdjustSalarySlider, SliderTooltip } from '../../common/CustomComponents';
import { useCalculator, payOptions, DISPATCH_ADJUSTMENT } from '../../../state/calculatorState';
import { useLayout, UPDATE_LAYOUT } from '../../../state/layoutState';
import SummaryTableContracted from './SummaryTableContracted'
import SummaryTableExpanded from './SummaryTableExpanded'
import { PriceIntegerFormat } from '../../common/customtFormatters';
import { SummaryFooter } from './SummaryFooter';


function ValueLabelComponent(props) {
    const { children, open, value } = props;
    return (
        <SliderTooltip open={open} placement='top' title={
            <Typography variant="h4" style={{ fontSize: 12 }}>{value > 0 ? "+" : ""}{PriceIntegerFormat({ value: value, displayType: "text" })} </Typography>
        } >
            {children}
        </SliderTooltip>
    );
}



export const SummaryTable = () => {
    let [{ payOption, salary, adjustment }, dispatch] = useCalculator();
    const theme = useTheme();

    const [adjustmentValue, adjustValue] = useState(0);
    const range = salary * 0.25;

    const updateRange = (range) => {
        return (
            dispatchLayout({
                type: UPDATE_LAYOUT,
                data: { resultsRange: range }
            })
        )
    }

    // if the user edits the inputs the 'adjustment' value will for forced to 0. Make sure the slider is also reset
    // Must be performed after the render loop else the initail interaction is blocked
    useLayoutEffect(() => {
        if(adjustmentValue !== 0 && adjustment === 0 ) adjustValue(0); // eslint-disable-line
    }, [adjustment]);

    useEffect(() => {
        // Update the document title using the browser API
        if (!payOptions[payOption].casual) {
            updateRange(payOption); // eslint-disable-line
        }
    }, [payOption]);

    const [, dispatchLayout] = useLayout();
    const small = useMediaQuery(theme.breakpoints.down('xs'));

    

    const modifyAdjustment = ( _, value) => {
        adjustValue(value);
    }

    const resetAdjustment = () => {
        adjustValue(0);
        return (
            dispatch({
                type: DISPATCH_ADJUSTMENT,
                data: 0,
            })
        )
    }

    const handleAdjustemnt = (event, value) => {
        return (
            dispatch({
                type: DISPATCH_ADJUSTMENT,
                data: value,
            })
        )
    }

    const  step = 0.001 * range;

    return (
        <Grid container>
                {small && (
                     <SummaryTableContracted />
                )}

                {!small &&  (
                    <SummaryTableExpanded />
                )}

                <SummaryFooter />

            <Grid item xs={12} style={{ position:'relative'}}>
                <Box style={{ display: "flex", justifyContent: "center", flexDirection:"column", flexGrow:1  }}>
                    <Typography style={{ padding:0, paddingTop: 0, textAlign: "center" }} variant="h3">Adjust Salary
                      {/* $ <InputBase style={adjustStyle} id="adjustmentValue" value={adjustment} onChange={(e) => handleAdjustemntValue(e)} /> */}
                    </Typography>
                    <Typography style={{ padding: 0, paddingBottom: 10, textAlign: "center" }} variant="body2">{`(per ${payOptions[payOption].tipLabel})`}</Typography>

                </Box>
                <Box style={{position: 'absolute', right:0, top:10 }}>
                    { Math.abs(adjustment) > 1 && <CustomTinyButton variant="contained" onClick={resetAdjustment} >reset</CustomTinyButton> }
                </Box>
                {/* <Box style={{position: 'absolute', left:0, top:10 }}>
                    <TextField label="Adjustment" id="adjustmentValue" value={adjustment} size="small" onChange={(e) => handleAdjustemntValue(e)} />
                </Box> */}

                <Box style={{ flex: 4, paddingLeft: 10, paddingRight: 20, overflow:"hidden" }}>
                    <AdjustSalarySlider
                        ValueLabelComponent={ValueLabelComponent}
                        track={false}
                        valueLabelDisplay="on"
                        marks={[{ value: 0, label: "" }]}
                        min={-1 * range}
                        max={range}
                        scale={x => {
                            if( x < 1 && x > -1) return 0;
                            if(x < 100 && x > -100){
                                return Number(x.toPrecision(1))
                            }
                            return Number(x.toPrecision(2))}
                        }
                        step={step}
                        aria-label="Adjust income"
                        value={adjustmentValue}
                        onChange={modifyAdjustment}
                        onChangeCommitted={handleAdjustemnt}
                    />
                </Box>
                <Box style={{ display: "flex", marginTop: -20, marginBottom: -20, justifyContent: "space-between", flexDirection: "row" }}>
                    <Typography style={{ padding: 10, alignText: "center" }} variant="body2">{`less`}</Typography>
                    <Typography style={{ padding: 10, alignText: "center" }} variant="body2">{`more`}</Typography>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography style={{ padding: 10, alignText: "center" }} variant="body2">{`See how a change in your salary effects your pay`}</Typography>
                </Box>

            </Grid>


        </Grid>
    )
}
