import React from 'react'
import {
    Collapse,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import ComponentWrapper from '../../common/ComponentWrapper';
import { SalaryInput } from './SalaryInput';
import { SalaryIntro } from './SalaryIntro';
import { AdvancedOptions } from './AdvancedOptions';
import { AuxillerytHeader } from '../../common/HeaderBar';
import { useCalculator } from '../../../state/calculatorState';
import { useLayout, EXPAND_ADVANCED_INPUT } from '../../../state/layoutState';
import { compileOptions } from '../../../utils/utils';


const IncomeComponent = () => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [ calculator, ] = useCalculator();
    const [{ expandAdvancedInput }, dispatchLayout] = useLayout();
    const options = compileOptions(calculator);
    // if not current tax year
    //const currentYearIndex = taxData.findIndex((obj) => (obj.current && obj.current === "true"));
    //if(calculator.yearIndex !== currentYearIndex) options.push([calculator.year]);
    const optionsLabels = options.map( o => o[0]);
    const paddingStyle = { paddingTop: 5, paddingBottom: 0, paddingLeft: small ? 5 : 5, paddingRight: small ? 10 : 0 };

    const handleToggleExpand = () => {
        return (
            dispatchLayout({
                type: EXPAND_ADVANCED_INPUT,
                expanded: !expandAdvancedInput,
            })
        )
    }

    return (
        <ComponentWrapper title="Income" >
            <div style={paddingStyle} >
                <SalaryIntro />
            </div>
            <div style={paddingStyle} >
                <SalaryInput />
            </div>

            <AuxillerytHeader title={expandAdvancedInput ? "Hide options" : "More Options"} isOpen={expandAdvancedInput} handleOpen={handleToggleExpand} handleClose={handleToggleExpand} >
                {optionsLabels.join(', ')}
            </AuxillerytHeader>

            <Collapse in={expandAdvancedInput}>
                <div style={paddingStyle} >
                    <AdvancedOptions />
                </div>
            </Collapse>
        </ComponentWrapper>
    )
}

export default IncomeComponent;
