import React, { useRef, useEffect } from "react";
import {
    Box,
    useTheme,
    useMediaQuery,
    fade,
} from '@material-ui/core';

import { useWindowSize, useImage } from '../../../utils/hooks';
import { useCalculator } from '../../../state/calculatorState';
import { DARK_MODE } from '../../../state/themeState';

import marker_white from '../../../assets/marker2_white.png';
import marker_grey from '../../../assets/marker2_grey.png';
import { taxData } from '../../../core/calculatorData';
import { formatMoney, resizeCanvas } from '../../../utils/utils';


export const SummaryTaxBand = () => {
    const [, darkIcon] = useImage(marker_white)
    const [, lightIcon] = useImage(marker_grey)
    const ref = useRef();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));

    let [{income, yearIndex, backpacker, nonResident, noTaxFree, haveTFN},] = useCalculator();
    useWindowSize();

    let icon = (theme.palette.type === DARK_MODE) ? darkIcon : lightIcon;
    if(!icon) icon = null;

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');

        // resize the canvas to the parent node
        const {ratio} = resizeCanvas(canvas, context, 120);


        let taxBrackets = taxData[yearIndex].tax;

        if (backpacker) {
            if (haveTFN) {
                taxBrackets = taxData[yearIndex].taxBackpacker;
            } else {
                taxBrackets = taxData[yearIndex].taxNonResident;
            }
        } else if (nonResident) {
            taxBrackets = taxData[yearIndex].taxNonResident;
        } else if (noTaxFree) {
            taxBrackets = taxData[yearIndex].taxNoFreeThreshold;
        } else {
            taxBrackets = taxData[yearIndex].tax;
        }

        drawTaxBar(context, canvas, theme, ratio, taxBrackets, income.a, icon);
    });

    let height = xs ? 90 : 110;

    let padding = xs ? 0 : 10;
    padding = xs ? 0 : 10;
    padding = lg ? 40: padding

    return (
        <Box style={{paddingBottom: padding, paddingTop:padding}}>
            <Box display="flex" flexGrow={1} style={{ height: `${height}px`,  width: '100%' }}>
                <canvas ref={ref} width={'100%'} height={`${height}px`} />
            </Box>
        </Box>
    );
}



const drawTaxBar = (ctx, canvas, theme, ratio, taxBrackets, income, icon) => {

    let margin = 12.5 * ratio;
    let barWidth = (canvas.width/1) - (2 * margin);
    let barHeight = 30 * ratio;
    let barY = margin * 5;
    let barX = margin;

    let labelY = margin*2.5;

    const shadowColor = fade(theme.palette.grey[900], 0.1)
    // const bgColor = theme.palette.background.default;
    const bgColor = theme.palette.grey[900];

    // shadow
    ctx.beginPath();
    ctx.rect(barX-1,barY-1,barWidth+4, barHeight+4);
    ctx.fillStyle = shadowColor;
    ctx.fill();

    // bar
    ctx.beginPath();
    // ctx.rect(x1, y1, x2, y2);
    ctx.rect(barX,barY,barWidth, barHeight);
    ctx.fillStyle = bgColor;
    ctx.fill();


    const valueRatio = Number(taxBrackets.brackets[taxBrackets.brackets.length - 1].from) * 1.25;

    let colourindex = 100;

    // tax bands
    taxBrackets.brackets.map( (bracket, index) =>{

        const taxFree = Number(bracket.from) === 0 && Number(bracket.value) === 0;
        const last = Number(bracket.to) === 0 && Number(bracket.from) > 0;


        let spanFrom = (Number(bracket.from)/valueRatio)* barWidth + margin;
        let spanTo = (Number(bracket.to)/valueRatio)* barWidth + margin;

        if(taxFree){
            // indicate tax-free
            
            let span = spanTo;
            let gap = 5*ratio;
            // draw bars every 5 px
            while((span - gap) > margin){
                span -= gap;
                ctx.beginPath();
                // ctx.rect(x1, y1, x2, y2);
                ctx.rect(span,barY,2,barHeight);
                ctx.fillStyle = `${fade(theme.palette.grey[300], 1.0)}`
                ctx.fill();
            }
            return true;
        }

        if(last){
            // last bar
            spanTo = barWidth + margin;
        }

        let barColor = `${theme.palette.secondary[colourindex]}`

         // bar
        ctx.beginPath();
        ctx.rect(spanFrom,barY,(spanTo - spanFrom), barHeight);
        ctx.fillStyle = barColor;
        ctx.fill();

        // Label - remember first bracket is skipped if it's zero
        let prevBracket = {from:0, to:0, value:0};
        if(index > 0){
            prevBracket = taxBrackets.brackets[index - 1];
        }
        const preGap = (Number((Number(bracket.from)  - Number(prevBracket.from)))/valueRatio)* barWidth;
        let labelYpos = (preGap < (50*ratio) && index > 1) ? labelY + (14*ratio) : labelY;

        ctx.beginPath();
        ctx.rect(spanFrom,labelYpos,1,(barY + barHeight - labelYpos));
        ctx.fillStyle = `${theme.palette.text.primary}`;
        ctx.fill();

        ctx.font = `${theme.typography.body1.fontSize * ratio*0.85}px ${theme.typography.body1.fontFamily[0]}`;
        ctx.fillStyle = `${theme.palette.text.primary}`;
        ctx.fillText(`$${formatMoney(Math.max(0, bracket.from - 1), 0, ".", ",")}`, spanFrom + (5*ratio), labelYpos - (2.5*ratio));

        ctx.font = `${theme.typography.body1.fontSize * ratio*0.75}px ${theme.typography.body1.fontFamily[0]}`;
        ctx.fillStyle = `${barColor}`;
        ctx.fillText(`${bracket.value}%`, spanFrom + (5*ratio), labelYpos+(12*ratio));


        colourindex = colourindex + 100;
        if(colourindex > 500) colourindex = 100; // return to 100 if > 5 bands

        if(last){
            // indicate tax beyond limit
            let span = spanFrom + (spanTo - spanFrom) * 0.5;
            let gap = 5*ratio;
            // draw bars every 5 px
            while((span) < barWidth + gap){
                span += gap;
                ctx.beginPath();
                ctx.rect(span,barY,2,barHeight);
                ctx.fillStyle = `${fade(theme.palette.grey[900], 1.0)}`
                ctx.fill();
            }
        }
        return true;
    })

        // marker
        let px = (income/valueRatio)* barWidth;
        px = Math.min(barWidth*0.9, px);
        const barH = barHeight/1.0;
    
        ctx.beginPath();
        ctx.rect(barX + px ,barY + (barHeight - barH), barWidth-px, barH);
        ctx.fillStyle =`${fade(theme.palette.background.paper, 0.75)}`;
        ctx.fill();
    
    
        px += margin;
    
        if(icon){
            px -= icon.width*(0.25*ratio)
            ctx.drawImage(icon, px, barY + barHeight*1, 12.5*ratio, 30*ratio);
        }
    


    ctx.font = `${theme.typography.h2.fontSize * ratio}px ${theme.typography.h2.fontFamily[0]}`;
        ctx.fillStyle = `${theme.palette.text.primary}`;
        ctx.textAlign = "center";
        ctx.fillText(`Tax Band`, margin + barWidth * 0.5, margin + 0);


        ctx.scale(ratio, ratio);


}



