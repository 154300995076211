import React, { useContext } from 'react';

import {
    useCalculator,
    payOptions,
    FREQUENCY_HOURLY,
} from '../../../state/calculatorState';
import { SummaryContext } from './Summary';
import {
    CustomTinyButton,
} from '../../common/CustomComponents';

import { formatMoney, compileOptions, compileNotes, formatDate } from '../../../utils/utils';


export const SummaryDownload = () => {

    const summaryData = useContext(SummaryContext);
    const [calculator,] = useCalculator();


    const onClickDownload = () => {
        let csvString = generateCSV(calculator, summaryData);

        var filename = 'paycalculator-summary.csv';
        var blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    return (
        <CustomTinyButton variant='contained' onClick={onClickDownload} >Download</CustomTinyButton>
    );
}

const EOL = '\n';
const generateCSV = (calculator, summaryData) => {

    const { payOption, salary, casualDaily, casualHourly } = calculator;
    const payCycle = payOptions[payOption].title.toLowerCase();
    const options = compileOptions(calculator);

    let csvContent = '';

    let row = '';
    // heading
    csvContent += 'Pay Calculator Summary' + EOL;
    csvContent += EOL;

    // tax year
    csvContent += csvifyKV('Tax Year ', calculator.year + ' - ' + Number(calculator.year) + 1)

    // Income
    csvContent += EOL;
    csvContent += 'Income,' + salary + ',per ' + payOptions[payOption].tipLabel + EOL;

    switch (payCycle) {
        case 'daily':
            csvContent += 'Frequency:,' + casualDaily.days + ',days per ' + casualDaily.frequency.toLowerCase() + EOL;
            if (casualDaily.frequency !== FREQUENCY_HOURLY[3]) {
                csvContent += ',' + casualDaily.annual + ',' + casualDaily.frequency.toLowerCase() + 's per year' + EOL;
            }
            break;
        case 'hourly':
            csvContent += 'Frequency:,' + casualHourly.hours + ',hours per ' + casualHourly.frequency.toLowerCase() + EOL;
            if (casualHourly.frequency !== FREQUENCY_HOURLY[3]) {
                csvContent += ',' + casualHourly.annual + ',' + casualHourly.frequency.toLowerCase() + 's per year' + EOL;
            }
            break;
        default:
            break;

    }

    options.map((item, index) => {
        if (index === 0) {
            csvContent += EOL;
            csvContent += 'Options:,' + item.join(',') + EOL;
        } else {
            csvContent += ',' + item.join(',') + EOL;
        }
        return true;
    })

    // Summary
    csvContent += EOL;
    csvContent += EOL;
    row = 'Pay,,Weekly,Fortnightly,Monthly,Annually';
    csvContent += row + EOL;

    csvContent += csvifyData(summaryData.summary);

    summaryData.data.map(item => {
        csvContent += csvifyData(item, false, true);
        return true;
    });

    // Summary YTD
    csvContent += EOL;
    csvContent += EOL;
    csvContent += 'Year to date,Start Date:,' + formatDate(calculator.payDay) + EOL;
    csvContent += ',Current Date:,' + formatDate() + EOL;
    csvContent += EOL;
    row = ',,Weekly,Fortnightly,Monthly,Annually';
    csvContent += row + EOL;
    row = ',Payment,' + calculator.YTD.w + ' / ' + calculator.payments.w + ',' + calculator.YTD.f + ' / ' + calculator.payments.f + ',' + calculator.YTD.m + ' / ' + calculator.payments.m + ',Full year';
    csvContent += row + EOL;

    summaryData.data.map(item => {
        csvContent += csvifyData(item, true);
        return true;
    });

    //Notes
    csvContent += EOL;
    const notes = compileNotes(calculator)
    notes.map((item, index) => {
        if (index === 0) {
            csvContent += EOL;
            csvContent += 'Notes:,,,,,,' + item.split(",").join("") + EOL;
        } else {
            csvContent += ',,,,,,' + item.split(",").join("") + EOL;
        }
        return true;
    })

    return csvContent;
}


const csvifyKV = (key, value) => {
    let row = key + ',' + value + EOL;
    return row;
}

const csvifyData = (data, ytd = false, showSubData = false, prefix = "") => {
    const value = ytd ? data.data.ytd : data.data.complete;
    let row = `,${prefix}${data.label},${formatValue(value.w)},${formatValue(value.f)},${formatValue(value.m)},${formatValue(value.a)}` + EOL;

    if (data.sub && showSubData) {
        const subRows = data.sub.map(sub => csvifyData(sub, ytd, showSubData, "  > "));
        row += subRows.join("");
    }
    return row;
}



// string version of same method in SummaryTableExpanded
export const formatValue = (value, integerDollars = false, addBrackets = false, addSquareBrackets = false, showZero = true) => {
    if (!showZero && value === 0) return '-';

    let prefix = '';
    let postfix = '';
    prefix = addBrackets ? '(' : prefix;
    prefix = addSquareBrackets ? '[' : prefix;
    postfix = addBrackets ? ')' : postfix;
    postfix = addSquareBrackets ? ']' : postfix;
    const dp = integerDollars ? 0 : 2;

    return prefix + formatMoney(value, dp, '.', '') + postfix;
}
