import React, { } from 'react';

import {
    Box,
    makeStyles,
    FormHelperText
} from '@material-ui/core';

import {
    useCalculator,
} from '../../../state/calculatorState';

import { useLayout, UPDATE_LAYOUT } from '../../../state/layoutState';

import { taxData } from '../../../core/calculatorData';

import { CustomFormControlLabel, CustomSwitch } from '../../common/CustomComponents';

const useStyles = makeStyles(theme => {
    return {
        helperText: {
            opacity: 0.5,
            paddingBottom: 10,
            color: theme.palette.primary.contrastText,
            marginLeft: 45,
            marginTop: -10,
        },

    }
});

export const SummaryYTD = () => {
        const classes = useStyles();
    
        let [{ year }, ] = useCalculator();
        let [{ ytd }, dispatchLayout] = useLayout();
    
        const currentYearIndex = taxData.findIndex(t => t.current === "true");
        const currentYear = taxData[currentYearIndex].year;
    
    
        const handleUpdateLayout = (data) => dispatchLayout({
            type: UPDATE_LAYOUT,
            data: { ...data },
        })
    
        const active = (Number(year) === Number(currentYear));
        if (!active) {
            if (ytd) {
                handleUpdateLayout({ ytd: false });
            }
            return false;
        }
     

    return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <CustomFormControlLabel
                    control={
                        <CustomSwitch
                            checked={ytd}
                            onChange={(event) => handleUpdateLayout({ ytd: (event.target.checked) })}
                            color="primary"
                        />
                    }
                    label="Year-To-Date"
                />
                <FormHelperText className={classes.helperText}>{`Customise salary start date in options`} </FormHelperText>
            </Box>
    )
}
