import React, { } from 'react'
import NumberFormat from 'react-number-format';

export const FloatFormat = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: values.value,

                    },
                });
            }}
            decimalScale={4}
            thousandSeparator
            isNumericString
        />
    );
}

export const PriceFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    if (!onChange) {
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                decimalScale={2}
                thousandSeparator
                // isNumericString
                fixedDecimalScale
                prefix="$"
            />
        )
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: values.value,

                    },
                });
            }
            }
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

export const PriceIntegerFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    if (!onChange) {
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                decimalScale={0}
                thousandSeparator
                prefix="$"
            />
        )
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: values.value,

                    },
                });
            }
            }
            decimalScale={0}
            fixedDecimalScale
            thousandSeparator
            prefix="$"
        />
    );
}



export const IntegerFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    if (!onChange) {
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                decimalScale={0}
                thousandSeparator
            />
        )
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: values.value,

                    },
                });
            }
            }
            decimalScale={0}
            fixedDecimalScale
            thousandSeparator
        />
    );
}

export const PercentFormat = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            isNumericString
            suffix="%"
        />
    );
}


export const MultiplierFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        ...other,
                        value: values.value,
                    },
                });
            }}
            isNumericString
            prefix="x"
        />
    );
}
