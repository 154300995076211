import React from 'react';
import {
  Link,
} from '@material-ui/core';

import { IncomeTaxComponent } from '../components/info/IncomeTax'
import { Heading, Content, SubHeading, Intro } from '../components/common/LayoutComponents';

import AuxilleryPage from './AuxilleryPageLayout';
import {
  RESIDENCY_INFO,
} from '../constants';


const TaxInfoPage = () => {
  return (
    <AuxilleryPage>
      <Heading>Income Tax</Heading>
      <Intro>
        Learn more about how income tax is calculated in Australia
      </Intro>

      <SubHeading>Progressive tax bands</SubHeading>

      <Content>
        Australian income is levied at progressive tax rates. Tax bracket start at 0%, known as the tax-free rate, and increases progressively up to 45% for incomes over $180,000. In addition to income tax, there are additional levies such as Medicare. Individuals on incomes below $18,200 are also entitled to the Low and Middle Income Tax Offset (LAMITO). Tax rates vary depending on residential status.

        Use the table below to see how the current tax rates are calculated.

      </Content>

      <IncomeTaxComponent />

      <SubHeading>Tax-Free Threshold</SubHeading>
      <Content>
        If you are an Australian resident, income under $18,200 has a tax rate of 0% and so it is not taxed. This is known as the tax free threshold. However, you can only apply this threshold to one employment income source. If you have more than one job, you can't claim the tax-free threshold on this income.
        </Content>

      <SubHeading>Foreign Residents and the 'Backpacker-Tax'</SubHeading>
      <Content>
        Residents who don't satisfy the <Link href={RESIDENCY_INFO} title='residency test'>residency test</Link> are not entitled to claim the tax-free threshold. The classification of a foreign resident is not necessarily determined by your passport or visa entitlement. Generally the ATO classifies a worker to be a non-resident if they have lived in Australia for less than 6 months.

        The exception however is for participants in the ‘Working Holiday Maker Program’. From 1 January 2017, tax rates changed for working holiday makers who are in Australia on a 417 or 462 visas. This has became known as the "Backpacker Tax" as it largely effected foreign backpackers who often work casually as farm laborers and fruit pickers. These workers became an important part of the rural economy and were previously exempt from income tax below $37,000. After considerable debate in Parliament, a new revised tax schedule was legislated such that a tax rate of 15% for earnings below $37,000 was imposed. Above this rate the non-resident income rates applied. See the new rates and work out your tax residency status.
        </Content>

      <SubHeading>Low Income Tax Offset and Low and Middle Income Offset</SubHeading>
      <Content>
        The Low Income Tax Offset (LITO) is a tax rebate for individuals on low incomes. Since 1 July 2013 the full offset is $445, with a withdrawal rate of 1.5 cents per dollar of income over $37,000, such that it cuts out at $66,667. You will need to complete 'Withholding declaration (NAT 3093)' to withhold your tax offsets from your pay. Otherwise, Tax Offsets are paid back as a lump sum on your tax return.

        The Low And Middle Income Tax Offset (LAMITO) is an additional income tax offset introduced in the 2019 Tax year. The offset is in addition to the LITO. This entitlement provides additional tax offset for incomes up to $125,333. The offset is applied to your tax return at the end of the year and is therefore not included in you regular PAYG tax on your wages.
        </Content>
    </AuxilleryPage>
  )
}

export default TaxInfoPage;
