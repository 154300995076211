import React, { useRef, useEffect, useState, useContext } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    makeStyles,
    useTheme,
    fade,
    Typography,
} from '@material-ui/core';

import { useWindowSize } from '../../utils/hooks';
import { useCalculator } from '../../state/calculatorState';
import placeholder from '../../assets/placeholder/TaxChart.png';
import { TaxChartSlider, StyledTableRow, SliderThumbComponent } from '../../components/common/CustomComponents'
import { PriceIntegerFormat } from '../common/customtFormatters';
import { taxData } from '../../core/calculatorData';
import { colorSequence } from '../calculator/distribution/DistributionChart'
import { calculateBracket, getBracket } from "../../core/Calculator";
import { StudentLoanContext } from "./StudentLoanComponent";

const getPixelRatio = context => {
    let backingStore =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;

    return (window.devicePixelRatio || 1) / backingStore;
};

const useStyles = makeStyles(theme => {
    return {
        image: {
            width: '100%',
            paddingBottom: 20,
        },

    }
});

function createData(label, description) {
    return { label, description };
}

export const StudentLoanChart = () => {
    const ref = useRef();
    const theme = useTheme();
    let [{ income: salary },] = useCalculator();

    const { year, category } = useContext(StudentLoanContext);

    const currentTaxData = taxData.find(c => c.year === year);
    let taxObject;
    for (const key of Object.keys(currentTaxData)) {
        if (currentTaxData[key].label && currentTaxData[key].label === category) {
            taxObject = currentTaxData[key];
        }
    }
    const taxRates = taxObject.brackets;
    const MAX_INCOME = Number(Number(taxRates[taxRates.length - 1].from) * 1.2).toPrecision(2);

    const [income, setIncome] = useState(salary.a > MAX_INCOME * 0.95 ? MAX_INCOME * 0.95 : salary.a);


    useWindowSize();

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');

        // resize the canvas to the parent node
        canvas.style.width = '100%';
        canvas.style.height = '100%';

        let ratio = getPixelRatio(context);
        let w = getComputedStyle(canvas)
            .getPropertyValue('width')
            .slice(0, -2);
        let h = getComputedStyle(canvas)
            .getPropertyValue('height')
            .slice(0, -2);

        // reset the new values
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        const value = Math.min(income / MAX_INCOME, 1);
        drawIncome(context, canvas, theme, ratio, value, income, MAX_INCOME, taxRates, taxObject.incremental);
    });

    const modifyIncome = (event, value) => {
        setIncome(value);
    }

    

    let height = 280;

    let tax = calculateBracket(income, taxRates, taxObject.incremental);
    const currentBracket = getBracket( income, taxRates);

    const RESULTS_TABLE = [
        createData(
            'Annual Salary', PriceIntegerFormat({ value: income, displayType: "text" })
        ),
        createData(
            'Annual repayment', PriceIntegerFormat({ value: tax, displayType: "text" }),
        ),
        createData(
            'Repayment Rate', `${currentBracket.value}%`
        ),
    ];


    return (
        <Box style={{ borderRadius: 10 }}>
            <Box style={{ padding: 0, paddingBottom: 20, position: "relative" }}>

                <Box display="flex" flexDirection="column" flexGrow={1} style={{ height: `${height}px`, width: '100%' }}>
                    <canvas ref={ref} width={'100%'} height={`${height}px`} />
                </Box>
                
                <Box style={{ padding: 12, paddingTop: 20 }}>
                    <Typography variant="body2">Adjust Salary</Typography>
                    <TaxChartSlider
                        ThumbComponent={SliderThumbComponent}
                        valueLabelDisplay="off"
                        min={0}
                        max={Number(MAX_INCOME)}
                        step={1000}
                        aria-label="Adjust income"
                        value={income}
                        onChange={modifyIncome}
                    />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                    <TableContainer component={Paper}>
                            <Table aria-label="Tax table">
                                <TableBody>
                                    {RESULTS_TABLE.map((row, index) => (
                                        <StyledTableRow key={row.label}>
                                            <TableCell size="small" align="left" style={{ fontSize: 16,  }}>
                                            <Typography variant="h2">{row.label}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                            <Typography component="span" variant="h4" style={{ fontSize: 16, color: (index === 0 ? theme.palette.secondary.main : theme.palette.primary.main) }}>
                                            {row.description}
                                            </Typography>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                </Box>
            </Box>
        </Box>
    );
}


export const TaxChartPlaceholder = () => {
    const classes = useStyles();

    return (
        <img src={placeholder} alt="placeholder" className={classes.image} />
    )

}


const drawIncome = (ctx, canvas, theme, ratio, value, income, MAX_INCOME, taxRates, inc) => {
    let w = canvas.width;
    let h = canvas.height

    let scaleX = w / MAX_INCOME;

    const tax = calculateBracket(MAX_INCOME * 1.1, taxRates, inc);
    let scaleY = h / tax;

    const bg = fade(theme.palette.background.overlay, 0.0)

    ctx.beginPath();
    ctx.fillStyle = `${bg}`;
    ctx.fillRect(0, 0, w, h);

    const plot = [];
    const thickness = 10;

    taxRates.map((b, index) => {
        ctx.beginPath();
        const c = colorSequence[3 % colorSequence.length].split(".");
        ctx.fillStyle = theme[c[0]][c[1]][c[2]];

        const from = b.from;
        const to = Number(b.to) === 0 ? Number(MAX_INCOME) : Number(b.to);

        let taxFrom = calculateBracket(from, taxRates, inc);
        let taxTo = calculateBracket(to-0.1, taxRates, inc);

        ctx.moveTo(from * scaleX, h);
        ctx.lineTo(from * scaleX, h - taxFrom * scaleY);
        ctx.lineTo(to * scaleX, h - taxTo * scaleY);
        ctx.lineTo(to * scaleX, h);
        ctx.lineTo(from * scaleX, h);

        let gradient = ctx.createLinearGradient(from * scaleX, h, (from * scaleX), scaleY);
        gradient.addColorStop("0", theme.palette.primary.main);
        gradient.addColorStop("0.6", theme.palette.secondary.main);

        ctx.fillStyle = gradient;
        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(to * scaleX, h - taxTo * scaleY);
        ctx.lineTo(to * scaleX, h);
        ctx.lineWidth = thickness;
        ctx.strokeStyle = fade(theme.palette.background.default, 1.0);
        ctx.stroke();

        ctx.font = `${theme.typography.body1.fontSize * ratio}px ${theme.typography.h4.fontFamily[0]}`;

        if (income > from) {
            if (income < to) {
                // part way
                let t = calculateBracket(income, taxRates, inc);
                plot.push({ x: (from + 0.1) * scaleX, y: h - taxFrom * scaleY })
                plot.push({ x: income * scaleX, y: h - t * scaleY })
            } else {
                plot.push({ x: (from + 0.1) * scaleX, y: h - taxFrom * scaleY })
                plot.push({ x: to * scaleX, y: h - taxTo * scaleY })
            }
            ctx.fillStyle = theme.palette.primary.contrastText;
        } else {
            ctx.fillStyle = fade(theme.palette.primary.contrastText, 0.25);
        }

        // barcket value label
        //if ((to * scaleX - from * scaleX) > 100) {
        if(index === 0 || index === taxRates.length - 1){
            ctx.textAlign = "center";
            const textX = (to + 0.75 * (from - to)) * scaleX;
            const textY = h - (taxFrom + 1.75 * (taxTo - taxFrom)) * scaleY
            ctx.fillText(`${b.value}%`, textX, Math.min(h - 40, Math.max(textY, 80)));
        }
        return true;
    })

    // plot overlay
    const minY = h - (thickness * 0.5);
    ctx.beginPath();
    ctx.moveTo(0, minY);

    plot.map(pos => ctx.lineTo(pos.x, Math.min(pos.y, minY)));
    //plot.map(pos => ctx.lineTo(pos.x, pos.y ));
    ctx.lineWidth = thickness;
    ctx.strokeStyle = theme.palette.primary.contrastText;
    ctx.stroke();


    let glossGradient = ctx.createLinearGradient(w * value - w, 0, w * value, 0);
    // let glossGradient = ctx.createLinearGradient(0, 0,w * value, 0);
    // glossGradient.addColorStop("0", `rgba(255,255,255,0.0)`);
    glossGradient.addColorStop("0", fade(theme.palette.primary.contrastText, 0.05));
    glossGradient.addColorStop("1.00", fade(theme.palette.primary.contrastText, 0.25));
    // glossGradient.addColorStop("0.75", `rgba(255,255,255,0.1)`);
    // glossGradient.addColorStop("1.0", `rgba(255,255,255,0.25)`);
    ctx.beginPath();
    ctx.fillStyle = glossGradient;
    ctx.fillRect(0, 0, w * value, h);



}
