import React, { useRef, useEffect, useContext } from "react";
import {
    Box,
    useTheme,
    useMediaQuery,
    fade,
} from '@material-ui/core';

import { useWindowSize } from '../../../utils/hooks';
import { SummaryContext } from './Summary';
import { resizeCanvas } from '../../../utils/utils';


export const SummaryChart = () => {
    const ref = useRef();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));
    const summaryData = useContext(SummaryContext);

    useWindowSize();

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');
        const {ratio} = resizeCanvas(canvas, context);

        drawIncome(context, canvas, theme, summaryData.data, ratio);
    });


    let height = 340;
    height = xs ? window.innerWidth * 0.7 : height;
    height = sm ? window.innerWidth * 0.4 : height;
    height = md ? window.innerWidth * 0.25 : height;
    height = lg ? window.innerWidth * 0.25 : height;
    height = xl ? window.innerWidth * 0.15 : height;

    return (
        <Box >
            <Box display="flex" flexGrow={1} style={{ height: `${height}px`, width: '100%', maxWidth:500, marginRight:0, marginLeft:'auto'}}>
                <canvas ref={ref} width={'100%'} height={`${height}px`} />
            </Box>
        </Box>
    );
}



const drawIncome = (ctx, canvas, theme, data, scale) => {
    // let cx = canvas.width / 3;
    let cy = canvas.height / 2;
    let r = canvas.width / 4.5;

    let t = canvas.width * 0.08;
    let cx = r + t + 20;

    var glossGradient = ctx.createLinearGradient(cx - r, cy - r, cx + r, cy + r);
    glossGradient.addColorStop("0", `rgba(255,255,255,0.25)`);
    glossGradient.addColorStop("1.0", `rgba(255,255,255,0.02)`);



    const gradients = [
        { a: theme.palette.primary[600], b: theme.palette.primary[200], c: theme.palette.primary[100] },
        { a: theme.palette.tertiary[600], b: theme.palette.tertiary[200], c: theme.palette.tertiary[100] },
        { a: theme.palette.secondary[600], b: theme.palette.secondary[200], c: theme.palette.secondary[100] },
    ]

    const textColor = theme.palette.text.primary
    const shadowColor = fade(theme.palette.grey[900], 0.1)
    // process data
    const total = data.reduce((tot, obj) => tot + Number(obj.data.current), 0);
    const UNIT = Math.PI * 2;


    let dividerColor = `rgba(255, 255, 255, 1.0)`;
    const dividerWidth = 0.5 * UNIT / 360;
    let angle = 0
    let start = angle;
    let end = start;


    // shadow
    const shadowT = t + 20;
    ctx.beginPath();
    ctx.arc(cx + 4, cy + 4, r, 0, 2 * Math.PI);
    ctx.strokeStyle = shadowColor;
    ctx.lineWidth = shadowT;
    ctx.stroke();


    if (total > 0) {

        data.map((obj) => {
            // major group
            const sumGroup = obj.sub.reduce((sum, o) => {
                if (o.negative || o.hidden) return sum;
                return sum + Number(o.data.current);
            }, 0);

            const groupRatio = sumGroup / total;

            obj.sub.map(sub => {
                // sub group
                const value = sub.data.current;
                let ratio = (value / sumGroup) * groupRatio;

                if (ratio > 0 && !sub.hidden && !sub.negative) {

                    start = angle;
                    angle += (UNIT * ratio);
                    end = angle;

                    ctx.beginPath();
                    ctx.arc(cx, cy, r, start, end);
                    ctx.strokeStyle = sub.color
                    ctx.lineWidth = t;
                    ctx.stroke();
                }
                return true;
            })
            start = angle;
            angle += dividerWidth;
            end = angle
            ctx.beginPath();
            ctx.arc(cx, cy, r, start, end);
            ctx.strokeStyle = dividerColor;
            ctx.lineWidth = t;
            ctx.stroke();
            return true;
        })
    } else {
        // empty
        const grads = gradients[0];
        var primaryGradient = ctx.createLinearGradient(cx - r, cy - r, cx + r, cy + r);
        primaryGradient.addColorStop("0", grads.c);
        primaryGradient.addColorStop("0.4", grads.b);
        primaryGradient.addColorStop("1.0", grads.a);
        ctx.beginPath();
        ctx.arc(cx + 4, cy + 4, r, 0, 2 * Math.PI);
        ctx.strokeStyle = primaryGradient;
        ctx.lineWidth = t;
        ctx.stroke();
    }

    // gloss
    const glossT = t * 0.5;
    const glossR = r + glossT * 0.5;
    ctx.beginPath();
    ctx.arc(cx, cy, glossR, 0, 2 * Math.PI);
    ctx.strokeStyle = glossGradient;
    ctx.lineWidth = glossT;
    ctx.stroke();

    let lineSpacing = (30 * scale);
    let xPos = cx + r + t + (10*scale);
    let height = data.length * lineSpacing;
    let yPos = cy - height / 2;


    data.map((obj, index) => {
        ctx.font = `${14 * scale}px ${theme.typography.h1.fontFamily[0]}`;
        ctx.fillStyle = textColor;
        ctx.textAlign = "left";
        ctx.fillText(obj.shortLabel, xPos + (15*scale), yPos);
        ctx.beginPath();
        ctx.arc(xPos, yPos - (5*scale), (10 * scale), 0, 2 * Math.PI);
        ctx.fillStyle = obj.color
        ctx.fill();

        const grads = gradients[index % gradients.length];
        var primaryGradient = ctx.createLinearGradient(xPos, yPos - (20*scale), xPos + (10*scale), yPos + 0);
        primaryGradient.addColorStop("0", grads.c);
        primaryGradient.addColorStop("0.4", grads.c);
        primaryGradient.addColorStop("0.4", grads.b);
        primaryGradient.addColorStop("0.6", grads.b);
        primaryGradient.addColorStop("1.0", grads.a);

        ctx.beginPath();
        ctx.arc(xPos, yPos - (5*scale), (10*scale), 0, 2 * Math.PI);
        ctx.fillStyle = primaryGradient
        ctx.fill();

        yPos += lineSpacing;
        return true;
    });

    ctx.scale(scale, scale);

}
