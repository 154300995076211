import React from "react";
import {
    Grid,
    InputLabel,
    Select,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

import { taxData } from '../../../core/calculatorData';
import {
    useCalculator,
    DISPATCH_UPDATE,
} from '../../../state/calculatorState';
import { useLayout, UPDATE_LAYOUT } from '../../../state/layoutState';
import {
    StyledFormControl,
    CustomTinyButton,
} from '../../common/CustomComponents';


export const SummaryOptions = () => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('xs'));

    let [{ yearIndex }, dispatchCalculator] = useCalculator();
    let [{ resultsRange }, dispatchLayout] = useLayout();

    const handleUpdateLayout = (data) => dispatchLayout({
        type: UPDATE_LAYOUT,
        data: { ...data },
    })

    const handleChangeYear = () => event => {
        return (
            dispatchCalculator({
                type: DISPATCH_UPDATE,
                year: taxData[event.target.value].year,
                yearIndex: event.target.value,
            })
        )
    }


    const btnStyle = { width: '100%' };
    const btnActiveStyle = { width: '100%', backgroundColor: theme.palette.primary.main }

    // show active button
    const annualButton = resultsRange === 0 ? btnActiveStyle : btnStyle;
    const monthlyButton = resultsRange === 1 ? btnActiveStyle : btnStyle;
    const fortnightlyButton = resultsRange === 2 ? btnActiveStyle : btnStyle;
    const weeklyButton = resultsRange === 3 ? btnActiveStyle : btnStyle;

    if (!small) return false;

    return (
        <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={5}>

                <StyledFormControl variant="filled" style={{ paddingLeft: 5, width: '100%' }}>
                    <InputLabel htmlFor="year">Tax Year</InputLabel>
                    <Select
                        native
                        variant="filled"
                        id="year"
                        onChange={handleChangeYear()}
                        value={yearIndex}
                    >
                        {taxData.map((option, index) => (
                            <option
                                key={option.year + " " + index}
                                data={option.year}
                                value={index}
                            >
                                {`${Number(option.year) - 1} - ${Number(option.year) - 2000}`}
                            </option>
                        ))}
                    </Select>
                </StyledFormControl>
            </Grid>

            <Grid item xs={7}>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <CustomTinyButton style={weeklyButton} onClick={() => handleUpdateLayout({ resultsRange: 3 })} >Weekly</CustomTinyButton>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTinyButton style={fortnightlyButton} onClick={() => handleUpdateLayout({ resultsRange: 2 })} >Fortnightly</CustomTinyButton>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTinyButton style={monthlyButton} onClick={() => handleUpdateLayout({ resultsRange: 1 })} >Monthly</CustomTinyButton>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTinyButton style={annualButton} onClick={() => handleUpdateLayout({ resultsRange: 0 })}> Annual</CustomTinyButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >

    );
}