/**
 * 
 * Context/Provider/Reducer state model based on this post:
 * https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
 * 
 * implementataion:
 * import { useCalculator } from '...../calculatorstate'
 * ...
 * // pull out objects and reducer dispatcher
 * const [{ superannuationRate, additionalSuper, deductions, nonResident, backpacker, noTaxfree, HELP, withhold }, dispatch] = useCalculator();
 */

import React, { createContext, useContext, useReducer } from 'react'
import { Calculator } from '../core/Calculator';
import { taxData } from '../core/calculatorData';
import { calculatePayments, resetDate, date2str } from '../utils/utils';

const LOCAL_STORAGE_NAME = "state_1.0";

export const OVERTIME_DATA = { hourlyRate: 20, loadingRate: 1.5, hours: 1, frequency: "Week", type: "Loading" };
export const OVERTIME_FREQUENCY = ["Week", "Fortnight", "Month", "Year"]
export const OVERTIME_TYPE = ["Loading", "Hourly"]
export const FREQUENCY_DAILY = [...OVERTIME_FREQUENCY]
export const FREQUENCY_HOURLY = [...OVERTIME_FREQUENCY];
export const FREQUENCY_ANNUALLY = ["Weeks", "Months"];
export const FREQUENCY_DEFAULTS = [52, 24, 12, 1];

export const DISPATCH_ADJUSTMENT = "adjustment";
export const DISPATCH_RESET_SALARY = "resetSalary";
export const DISPATCH_UPDATE = "update";
export const DISPATCH_UPDATE_OVERTIME = "updateOvertime";
export const DISPATCH_UPDATE_SALARY = "updateSalary";
export const DISPATCH_UPDATE_SALARY_ALT = "updateSalaryAlt";
export const DISPATCH_UPDATE_SALARY_SACRAFICE = "updateSalarySacrafice";

export const payOptions = [
  { title: "Annually", type: "fulltime", tipLabel: "year", label: "Annual", default: 60000, range: "a" },
  { title: "Monthly", type: "fulltime", tipLabel: "month", label: "Monthly", default: 5000, range: "m" },
  { title: "Fortnightly", type: "fulltime", tipLabel: "fortnight", label: "Fortnightly", default: 1500, range: "f" },
  { title: "Weekly", type: "fulltime", tipLabel: "week", label: "Weekly", default: 750, range: "w" },
  { title: "Daily", type: "daily", tipLabel: "day", label: "Daily", casual: true, default: 150, range: "w" },
  { title: "Hourly", type: "hourly", tipLabel: "hour", label: "Hourly", casual: true, default: 20, range: "w" },
  // { title: "Hourly/day", type:"hourly_day", tipLabel: "hour", label: "Hourly rate", casual: true, default: 20 },
]

export const salarySacraficeOptions = [
  "Year",
  "Month",
  "Fortnight",
  "Week",
  "Percent",
]

// reset Superannuation
// Use rate from tax Data for the current year

export const InitialState = {
  adjustment: 0, // feature to quickly add or subtract an amount to the current salary (regardless of range a, m, f, w...) Reset whenever the income is changed

  annuated: true, // the calculations can be derived from annual amounts (otherwise calculated at each)
  PAYG: true, // calculator uses ATO PAYG rates
  salary: 60000, // value in the salary input field

  hpd: 7.5,
  dpw: 5,
  wpy: 52,
  hpw: 38,

  casualDaily: { days: 5, frequency: OVERTIME_FREQUENCY[0], annual: FREQUENCY_DEFAULTS[0] },
  casualHourly: { hours: 38, frequency: OVERTIME_FREQUENCY[0], annual: FREQUENCY_DEFAULTS[0] },

  adjustAnnual: false,

  medicareExemption: false,
  spouse: false,
  spouseIncome: 0,
  dependants: false,
  dependantsCount: 1,
  medicareExemptionValue: 1,

  medicareDescription: "Single, no dependants",

  SAPTO: false,
  spouseSeparated: false,

  adjustSuperannuation: false,
  additionalSuper: 0,

  adjustSuperannuationRate: false,
  superannuationRate: 9.5,

  adjustSalaryScaracfice: false,
  salaryScaracficeAmount: 0,
  salarySacraficeOption: 0,
  adjustSalaryScaracficeAdditional: false,
  includesSuperannuation: false,
  over65: false,

  benefits: 0, // Governemnt benefits, pension

  adjustDeductions: false,
  taxableDeductions: 0,
  otherTaxableIncome: 0,

  fringeBenefits: 0,
  fringeBenefitsExempt: 0, // Hmm this is not being calculated

  backpacker: false, // backpacker tax rates
  haveTFN: true,
  nonResident: false, // non-resident tax rates
  noTaxFree: false, // option to not claim the tax free threshold
  notForProfit: false,
  HELP: false, // all student loan repayments HELP, VSL, SFSS, SSL, ABSTUDY SSL, TSL
  SFSS: false,  // legacy sudent financial suppliment scheme
  withhold: false, // not used only related to specific offsetds
  yearIndex: 7,
  year: 2020,

  adjustPayDay: false,
  payDay: "2019-07-07",

  payOption: 0, // index of payOptions annual income

  // overtime: [{rate: 0, rateMultiple: 0, hpd: 7.5, hpw: 38,}],
  overtime: [],

  // annual figures
  division293: 0,
  listo: 0, // Low income super annuation tax offset
  otherTaxesAndLevies: 0,
  lito: 0,
  lamito: 0,
  mito: 0,
  mawto: 0,
  sapto: 0, // value of sapto (as opposed to SAPTO which is the eligibility option)
  saptoExcess: 0, // potential excess sapto can be transferred to spouse

  hasPrivateHealthcare: true,
  medicareSurcharge: 0, // this is what it could be
  medicareSurchargeLiability: 0, // this is what it is


  // Superannaution categories
  superannuationGuarantee: 0,
  superannuationCoContribution: 0,

  superannuationConcessional: 0,
  superannuationConcessionalTax: 0,
  superannationConcessionalRemaining: 0,

  superannationNonConcessional: 0,
  superannationNonConcessionalRemaining: 0,


  superannationExcess: 0,
  superannationExcessTax: 0,


  warnings: {
    nonConcessionalCap: false,// over the limit
    superannuationGuarantee: false, // under the guarantee
    medicareSurcharge: false, // Over the limit if not private healthcare
    division293: false, // additional tax on super outside fund
    extraPayment: false,
    maxSalarySacrafice: false,
  },


  taxableDeducations: 0,

  taxableIncome: 0, // Earnings minus deductions

  //https://www.ato.gov.au/Individuals/Tax-return/2019/Tax-return/Adjusted-taxable-income-(ATI)-for-you-and-your-dependants-2019/
  adjustedTaxableIncome: 0, // used to detemine medicare, HELP repayments
  rebateIncome: 0,  // Used for SAPTO


  // payg figures
  baseSalary: { a: 0, m: 0, f: 0, w: 0 },
  income: { a: 0, m: 0, f: 0, w: 0 }, // taxable income

  net: { a: 0, m: 0, f: 0, w: 0 },

  superannuation: { a: 0, m: 0, f: 0, w: 0 }, // Superannuation guarantee
  superannuationSacrafice: { a: 0, m: 0, f: 0, w: 0 }, // Superannuation salary sacrafice
  superannuationTax: { a: 0, m: 0, f: 0, w: 0 },
  totalSuperannuation: { a: 0, m: 0, f: 0, w: 0 },

  incomeTax: { a: 0, m: 0, f: 0, w: 0 },
  extraWithholdingTax: { a: 0, m: 0, f: 0, w: 0 }, // used for 26/53 week years ONLY
  grossTax: { a: 0, m: 0, f: 0, w: 0 },
  otherTax: { a: 0, m: 0, f: 0, w: 0 },
  offsets: { a: 0, m: 0, f: 0, w: 0 },
  medicare: { a: 0, m: 0, f: 0, w: 0 },
  medicareAdjustment: { a: 0, m: 0, f: 0, w: 0 },
  levies: { a: 0, m: 0, f: 0, w: 0 },
  help: { a: 0, m: 0, f: 0, w: 0 },
  sfss: { a: 0, m: 0, f: 0, w: 0 },

  payments: { w: 52, f: 26, m: 12, a: 1 },
  YTD: { w: 1, f: 1, m: 1, a: 1 },

  deductions: { a: 0, m: 0, f: 0, w: 0 } // not being used right now
}

export const getCurrentYear = () => {
  const yearIndex = taxData.findIndex((obj) => (obj.current && obj.current === "true"));
  const year = taxData[yearIndex].year;

  return { yearIndex, year };
}


export const resetSuperRate = () => {
  const yearIndex = taxData.findIndex((obj) => (obj.current && obj.current === "true"));
  const superannuationRate = Number(taxData[yearIndex].superannuation.rate);
  return { superannuationRate };
}



export const checkPayDay = (state) => {

  const { year, payDay, payOption, warnings, adjustPayDay } = state;
  let payDate = new Date(payDay);

  const startFTY = new Date(Number(year) - 1, 6, 1);
  const endFTY = new Date((year), 6, 1);

  // default
  let checkPayments = {};
  let extraPayment = false;

  if (adjustPayDay) {
    // make sure the dat is in range
    payDate = resetDate(payDate, startFTY, endFTY);
  } else {
    // safe start date
    payDate = new Date(Number(year) - 1, 6, 6);
  }
  checkPayments = calculatePayments(payDate, year, payOption);

  extraPayment = checkPayments.payments.f > InitialState.payments.f || checkPayments.payments.w > InitialState.payments.w;


  return { payDay: date2str(payDate), ...checkPayments, warnings: { ...warnings, extraPayment } };

}


export const readData = () => {
  let data = InitialState;

  if (typeof (Storage) !== "undefined") {
    try {
      const localData = localStorage.getItem(LOCAL_STORAGE_NAME);
      if(localData){
        data = JSON.parse(localData);
      }
    }
    catch (error) { console.log(error) }
  }

  data = { ...InitialState, ...data, ...getCurrentYear(data.year), adjustment: 0 };
  data = { ...data, ...checkPayDay(data) };



  Object.keys(data).map(key => {
    // Remove redundant objects
    if (InitialState[key] === undefined) {
      try {
        delete data[key];
      } catch (e) { console.log(e) }
    }

    // Reset if the data has become undefined (POTENTIALLY DANGEROUS)
    if (typeof InitialState[key] === "number" && data[key] === "") {
      console.log("RESET", key, InitialState[key])
      data[key] = InitialState[key];
    }
    return true;
  })

  // fill-in missing data objects
  Object.keys(data).map(key => {
    // Remove redundant objects
    if (typeof InitialState[key] === "object") {
      // check saved data
      Object.keys(data[key]).map(subkey => {
        if (InitialState[key][subkey] === undefined && key !== "overtime") {
          if (data[key] && data[key][subkey]) {
            try {
              delete data[key][subkey];
            } catch (e) { console.log(e) }
          }
        }
        return true;
      });

      // check initial data
      Object.keys(InitialState[key]).map(subkey => {
        if (data[key][subkey] === undefined) {
          console.log("ADD", key, subkey, InitialState[key][subkey])
          data[key][subkey] = InitialState[key][subkey]
        }
        return true;
      })

    }
    return true;
  })

  // purge empty overtime
  if (data.overtime) {
    data.overtime = data.overtime.filter(ot => ot !== undefined && ot !== null)
  }


  data = calculate(data);
  //data = calculate(InitialState);
  return data;
}

export const saveData = (data) => {
  // only save the theme type not the entire object
  if (typeof (Storage) !== "undefined") {
    return localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(data));
  }
  return;
}

export const getCasualData = (payOption, casualHourly, casualDaily) => {
  switch (payOptions[payOption].title.toLowerCase()) {
    case "hourly":
      return casualHourly;
    case "daily":
      return casualDaily;
    default:
      return false;
  }
}


const calculate = (state) => {

  const result = Calculator.setState(state)
    .reset()
    .getIncome()
    .calculateSuperannuationGuarantee()
    .calculateSalarySacrafice()
    .calculateSuperannuationCoContribution()
    .calculateTotalSuperannuation()
    .calculateSuperannuationLISTO()
    .calculateTotalSuperannuationTax()
    .calculateTaxableIncome()
    .calculateDivision293()
    .calculateIncomeTax()
    .calculateIncomeTaxExtraWitholding()
    .calculateStudentLoans()
    .calculateMedicare()
    .calculateMedicareAdjustment()
    .calculateOffsets()
    .calculateGrossTax()
    .calculateNetIncome()


  return result.state;

}


// Instantiates the Context object (no initial data)
export const CalculatorContext = createContext();

// Calculator reducer ations
export const CalculatorReducer = (state, action) => {
  let newState = {};
  switch (action.type) {
    case DISPATCH_ADJUSTMENT:
      newState = {
        ...state,
        adjustment: Number(action.data),
      }
      break;

    case DISPATCH_RESET_SALARY:
      const oldSalary = state.salary;
      const oldPayOption = state.payOption;

      newState = {
        ...state,
        ...InitialState,
        ...getCurrentYear(),
        ...resetSuperRate(),
        salary: oldSalary,
        payOption: oldPayOption,
      }

      break;

    case DISPATCH_UPDATE:
      newState = {
        ...state,
        ...action,
        adjustment: 0,
      }
      break;

    case DISPATCH_UPDATE_OVERTIME:
      newState = {
        ...state,
        overtime: [...action.overtime],
        adjustment: 0,
      }
      break;

    case DISPATCH_UPDATE_SALARY:
      const salary = Number(action.salary);
      // if (Number(state.salary) === salary) {
      //   return state;
      // }

      newState = {
        ...state,
        salary: salary,
        adjustment: 0,
      }
      break;


    case DISPATCH_UPDATE_SALARY_ALT:
      // this was done to try and fix a problem a few people were havinog with adjusting the salary input field
      // Maybe it was fixed by de coupling the adjust salary with the salary - who knows.
      const salaryAlt = Number(action.salary);
      newState = {
        ...state,
        salary: salaryAlt,
        adjustment: 0,
      }
      newState = calculate(newState);
      saveData(newState);

      return newState
      break;




    case DISPATCH_UPDATE_SALARY_SACRAFICE:
      // ensure that limits on ss amount is observed
      newState = {
        ...state,
        ...action,
        adjustment: 0,
      }
      break;

    default:
      // no change
      return state;
  }

  // check for leapiness
  newState = {
    ...newState,
    ...checkPayDay(newState),
  }

  // perform calculation
  newState = calculate(newState);
  saveData(newState);

  return newState
};



// Wrapper for the Provider injection
export const CalculatorProvider = ({ initialState, children }) => {

  return (
    <CalculatorContext.Provider value={useReducer(CalculatorReducer, initialState)}>
      {children}
    </CalculatorContext.Provider>
  )
};



// a custom hook function to access your state/reducer in any component
// It returns [state, dispatch] array, that is passed as a value to our Provider.
// const [{ superannuationRate, additionalSuper, deductions, nonResident, backpacker, noTaxFree, HELP, withhold }, dispatch] = useCalculator();

// NOTE: A component calling useContext will always re-render when the context value changes. If re-rendering the component is expensive, you can optimize it by using memoization.
export const useCalculator = () => useContext(CalculatorContext);