import React, { useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// import Hero from '../components/calculator/hero/Hero'

import {
  PC_header_native,
  PC_content_native,
  PC_right_sticky,
  PC_footer_sticky,
} from '../components/ads/FuseAds';
import IncomeComponent from '../components/calculator/income/Income';
import { SummaryComponent } from '../components/calculator/summary/Summary';
import { FAQ } from '../components/calculator/faq/FAQ';
import Footer from '../components/footer/Footer';

import { MAX_WIDTH } from '../constants';


const useStyles = makeStyles(theme => (
  {
    contentWrapper: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
    },
  }
));



const CalculatorPage = () => {
  const calculatorRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const mobile = xs;
  const tablet = sm;
  const desktop = md || lg || xl;
  const smallDesktop = md;
  const largeDesktop = lg || xl;


  let padding = 20;
  let bannerWidth = 0;
  if (tablet) bannerWidth = 160 + padding * 2;
  if (desktop) bannerWidth = 300 + padding * 1;

  const paddingH = (mobile || tablet) ? 0 : 20;

  const spacing = (mobile || tablet) ? 0 : 2;


  return (
    <React.Fragment>
      {/* <Hero getStarted={scrollToCalculator} /> */}
      <Box style={{ height: 100 }} />

      <Box ref={calculatorRef} style={{ width: largeDesktop ? `calc(100vw - ${bannerWidth}px)` : `100vw`}}>
          <PC_header_native />
      </Box>

      <Box display="flex" justifyContent="flex-start">

        <div className={classes.contentWrapper} style={{ width: `calc(100vw - ${bannerWidth}px)`, maxWidth: MAX_WIDTH, paddingLeft: paddingH, paddingRight: paddingH }}>
          {/* <Box display="flex" justifyContent="center">
            {largeDesktop && (
              <PC_header_native />
            )}
          </Box> */}
          <Grid container spacing={spacing} >
            <Grid item xs={12} xl={5}>
              <IncomeComponent />
            </Grid>
            <Grid item xs={12} xl={7}>
              <SummaryComponent style={{ paddingBottom: padding }} />
            </Grid>
          </Grid>

          <Box display="flex" flex="1" justifyContent="space-around" flexDirection="row">
            <PC_content_native />
          </Box>

          <FAQ style={{ paddingBottom: padding }} />

        </div>
          
        { !mobile && <PC_right_sticky /> }

      </Box>
      { mobile && <PC_footer_sticky /> }
      <Footer />
    </React.Fragment>
  )
}

export default CalculatorPage;
