import {
    roundToNearestCent,
    roundToNearestDollar,
    roundToNearestDollerPlus99,
} from '../utils/utils';


export const medicareData = [
    {
        year: "2020",
        current: true,

        scale: "weekly",
        moreChildrenA: 82,
        moreChildrenB: 65.5,
        WFT: {
            spouse: "37089",
            children: "3406",
            scale: "annual",
            round: "cents",
        },
        scaleThresholds: {
            scale2: 422,
            scale2Raw: 422.69,
            scale6: 713,
            scale6Raw: 713.25,
        },

        SOPThresholds: {
            scale2: 528,
            scale6: 891,
            WFTMultiplier: 0.1,
            WFTDivider: 0.08,
        },

        thresholds: [
            { type: "spouse", children: 0, ColumnA: 891, ColumnB: 0 },
            { type: "children", children: 1, ColumnA: 973, ColumnB: 0 },
            { type: "children", children: 2, ColumnA: 1055, ColumnB: 0 },
            { type: "children", children: 3, ColumnA: 1137, ColumnB: 0 },
            { type: "children", children: 4, ColumnA: 1219, ColumnB: 0 },
            { type: "children", children: 5, ColumnA: 1300, ColumnB: 0 },
            { type: "children", children: 6, ColumnA: 1382, ColumnB: 1106.25 },
            { type: "children", children: 7, ColumnA: 1464, ColumnB: 1171.75 },
            { type: "children", children: 8, ColumnA: 1546, ColumnB: 1237.25 },
            { type: "children", children: 9, ColumnA: 1628, ColumnB: 1302.75 },
            { type: "children", children: 10, ColumnA: 1710, ColumnB: 1368.25 },
        ],
    },
];


export const calculateMedicareLevyAdjustment = (weeklyIncome, spouse, spouseIncome, dependants, dependantsCount) => {
    // TODO: expand for different years
    const data = medicareData[0];

    // income is weekly income to nearest dollar plus 99 cents
    const combinedIncome = Number(weeklyIncome) + Number((spouse ? spouseIncome : 0));
    const income = roundToNearestDollerPlus99(combinedIncome);

    let WFT = 0
    let WLA = 0;
    let SOP = 0;
    let qualify = false;

    const debug = false;

    if(debug) console.log("combined income", weeklyIncome, spouseIncome, combinedIncome)
    // Qualify adjustment
    // https://www.ato.gov.au/rates/medicare-levy-adjustment-weekly-tax-table/?page=1#When_your_employee_is_entitled_to_a_Medicare_levy_adjustment
    if (combinedIncome >= 422) {

        if (dependants && dependantsCount > 0) {
            if (dependantsCount <= 10) {
                if(debug) console.log("COLUMN A - deps < 10")
                const threshold = data.thresholds.find(d => Number(d.children) === Number(dependantsCount));
                if(debug) console.log(threshold)
                qualify = threshold && combinedIncome < threshold.ColumnA;
            } else {
                if(debug) console.log("COLUMN A - no deps > 10")
                const threshold = data.thresholds.find(d => Number(d.children) === 10);
                qualify = threshold && combinedIncome < (Number(threshold.ColumnA) + (dependantsCount - 10) * 82);
            }

        // } else if (spouse) {
        } else if(spouse) {
            if(debug) console.log("COLUMN A - spouse only")
            const threshold = data.thresholds.find(d => Number(d.children) === 0);
            if(debug) console.log("threshold:", threshold);
            qualify = threshold && combinedIncome < threshold.ColumnA;
        }else{
            if(debug) console.log("No Spouse, no dependancies, no adjustment")
        }

    }
    // qualify = true;

    if(debug) console.log("qualify:", qualify)
    if (qualify) {

        // calculate Weekly Family Threshold
        // https://www.ato.gov.au/Rates/Schedule-1---Statement-of-formulas-for-calculating-amounts-to-be-withheld/?page=4#Weekly_family_threshold__WFT_
        if (spouse && !dependants) {
            WFT = Number(data.WFT.spouse) / 52;
            WFT = roundToNearestCent(WFT);
        } else if (dependants) {
            WFT = (Number(data.WFT.spouse) + dependantsCount * data.WFT.children) / 52;
            WFT = roundToNearestCent(WFT);
        }

        if(debug) console.log("WFT:", WFT)
        // Calculate Shading Out Point (SOP)
        //https://www.ato.gov.au/Rates/Schedule-1---Statement-of-formulas-for-calculating-amounts-to-be-withheld/?page=5#Shading_Out_Point__SOP_
        if(WFT){
        SOP = (WFT * Number(data.SOPThresholds.WFTMultiplier)) / Number(data.SOPThresholds.WFTDivider);
        }else{
            // Need to neutralise this variable form the formula
            SOP = weeklyIncome + 0.001;
        }

        // Calculate Weekly Levy Adjustment
        // https://www.ato.gov.au/Rates/Schedule-1---Statement-of-formulas-for-calculating-amounts-to-be-withheld/?page=6#Weekly_Levy_Adjustment__WLA_
        if (weeklyIncome >= Number(data.scaleThresholds.scale2) && weeklyIncome < SOP ) {
            // SCALE 2
            if(debug) console.log("SCALE 2")
            if (income < Number(data.SOPThresholds.scale2)) {
                if(debug) console.log("Fn 1")
                WLA = (income - Number(data.scaleThresholds.scale2Raw)) * 0.1;
            } else if (income < WFT) {
                if(debug) console.log("Fn 2")
                WLA = income * 0.02;
            } else if (income < SOP) {
                if(debug) console.log("Fn 3")
                WLA = (WFT * 0.02) - ((income - WFT) * 0.08);
            }
        }

        else if (weeklyIncome >= Number(data.scaleThresholds.scale6 && weeklyIncome < SOP )) {
            // SCALE 6
            if(debug) console.log("SCALE 6")
            if (income < Number(data.SOPThresholds.scale6)) {
                if(debug) console.log("Fn 1")
                WLA = (income - Number(data.scaleThresholds.scale6Raw)) * 0.05;
            } else if (income < WFT) {
                if(debug) console.log("Fn 2")
                WLA = income * 0.01;
            } else if (income < SOP) {
                if(debug) console.log("Fn 3")
                WLA = (WFT * 0.01) - ((income - WFT) * 0.04);
            }

        }

        WLA = roundToNearestDollar(WLA);
    }

    return WLA

}

/*
export const roundToNearestCent = (value) => {
    return Math.round(value * 100) / 100;
}

export const roundToNearestDollar = (value) => {
    return Math.round(value * 1);
}


export const roundToNearestDollerPlus99 = (value) => {
    return Math.round(value * 1) + 0.99;
}
*/