import React from 'react'
import {
    Grid,
    Box,
    makeStyles,
    useMediaQuery,
    useTheme,
    Select,
    InputLabel,
    FormHelperText,
    Tooltip,
    Hidden,
    IconButton,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import { PercentFormat } from '../../common/customtFormatters'


import NumberFormat from 'react-number-format';
import {
    useCalculator,
    payOptions,
    OVERTIME_DATA,
    FREQUENCY_DAILY,
    FREQUENCY_HOURLY,
    FREQUENCY_DEFAULTS,
    OVERTIME_TYPE,
    DISPATCH_UPDATE,
    DISPATCH_UPDATE_OVERTIME,
    // DISPATCH_UPDATE_SALARY,
    DISPATCH_UPDATE_SALARY_ALT,
} from '../../../state/calculatorState';
import {
    StyledTextField,
    // StyledPrimaryTextField,
    StyledFormControl,
    StyledFormControlMobile,
    StyledLink,
    CustomTinyButton,
    CustomFormControlLabel,
    CustomSwitch,
} from '../../common/CustomComponents';

import {
    // PriceIntegerFormat,
    FloatFormat,
    PriceFormat,
    MultiplierFormat,
} from '../../common/customtFormatters'

import { AWARD_RATES } from '../../../constants';
import { taxData } from '../../../core/calculatorData';

const useStyles = makeStyles(theme => {
    return {
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },

        image: {
            width: '100%',
            paddingBottom: 20,
        },

        tabs: {
            flexGrow: 1,
            padding: 20,
        },
        tab: {
            padding: 20,
        },
        contentWrapper: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
        },
        helperText: {
            opacity: 0.5,
            paddingBottom: 10,
            color: theme.palette.primary.contrastText,
            marginLeft: 0,
            letterSpacing: "-0.25px",
        },
        switchHelperText: {
            opacity: 0.5,
            marginTop: -12,
            paddingLeft: 45,
            color: theme.palette.primary.contrastText,
            letterSpacing: "-0.25px",
        },
        insetInput:{
            paddingTop: 2,
            paddingLeft: 45,
        },
        button: {
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 2,
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.grey[100]
        },
        deleteButton: {
            paddingLeft: 10,
            paddingRight: 10,
            // paddingTop:20,
            marginTop: 10,
            width: 40,
            height: 40,
        },
        seperator: {
            height: 1,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
            margin: '5px 0px 0 0',
        }
    }
});


export const SalaryInput = () => {
    const theme = useTheme();
    const classes = useStyles();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    let [{ yearIndex, payOption, salary, superannuationRate, adjustSuperannuationRate, includesSuperannuation, HELP, nonResident, backpacker }, dispatch] = useCalculator();

    // option toggles
    let disableHELP = false;

    if (nonResident && backpacker) {
        disableHELP = true;
    }

    const handleUpdate = (data) => dispatch({
        type: DISPATCH_UPDATE,
        ...data
    })

    // const handleChangeSalary = () => event => {
    //     //if (document.activeElement.className !== event.target.className) return;
    //     return (
    //         dispatch({
    //             type: DISPATCH_UPDATE_SALARY,
    //             payOption,
    //             salary: Number(event.target.value),
    //         })
    //     )
    // }


    const handleChangeSalaryAlt = () => event => {
        return (
            dispatch({
                type: DISPATCH_UPDATE_SALARY_ALT,
                payOption,
                salary: Number(event.target.value),
            })
        )
    }


    const handleChangeCycle = () => event => {
        //
        return (
            dispatch({
                type: DISPATCH_UPDATE,
                payOption: Number(event.target.value)
            })
        )
    }

    const fulltime = payOptions[payOption].type === "fulltime";
    const salaryType = fulltime ? "salary" : "rate";
    // const annual = payOptions[payOption].tipLabel === "year";

    return (
        <Box className={classes.root}>
            <Grid container spacing={(small ? 1 : 10)} style={{ width: '100%' }} >
                <Grid item sm={12} md={6} style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0, width: '100%' }} >

                    <Hidden mdUp>
                        <StyledFormControlMobile variant="filled" fullWidth style={{ paddingBottom: small ? 0 : 10 }}>
                            <InputLabel htmlFor="payCycle">Pay Cycle</InputLabel>
                            <Select
                                native
                                variant="filled"
                                id="payCycle"
                                onChange={handleChangeCycle()}
                                value={payOption}
                            >
                                {payOptions.map((option, index) => (
                                    <option
                                        key={option.title}
                                        data={option.tipLabel}
                                        value={index}
                                    >
                                        {option.title}
                                    </option>
                                ))}
                            </Select>
                        </StyledFormControlMobile>
                        <Box style={{ paddingTop: 20 }} />
                    </Hidden>
                    {/* 
                    <StyledPrimaryTextField
                        label={`${payOptions[payOption].label} ${salaryType}`}
                        fullWidth
                        value={Number(salary) + Number(adjustment)}
                        variant="filled"
                        InputProps={{
                            inputComponent: annual ? PriceIntegerFormat : PriceFormat,
                        }}
                        onChange={handleChangeSalary()}
                        id="salary"
                    /> */}

                    <StyledTextField
                        label={`${payOptions[payOption].label} ${salaryType}`}
                        fullWidth
                        value={salary}
                        variant="filled"
                        InputProps={{
                            inputComponent: PriceFormat,
                        }}
                        onChange={handleChangeSalaryAlt()}
                        id="salary"
                    />


                    {payOptions[payOption].tipLabel !== "hour" && (
                        <FormHelperText className={classes.helperText}>{`Enter how much you earn per ${payOptions[payOption].tipLabel}`} </FormHelperText>

                    )}
                    {payOptions[payOption].tipLabel === "hour" && (
                        <FormHelperText className={classes.helperText}><StyledLink href={AWARD_RATES} title='Award rates'>Check award rates</StyledLink></FormHelperText>

                    )}

                    <CasualDailyOptions />
                    <CasualHourlyOptions />

                </Grid>
                <Grid item sm={12} md={6} style={{ paddingLeft: 0, paddingBottom: 10, paddingTop: 0, width: '100%' }}>
                    <Hidden smDown>
                        <StyledFormControl variant="filled" fullWidth style={{ paddingBottom: 10 }}>
                            <InputLabel htmlFor="payCycle">Pay Cycle</InputLabel>
                            <Select
                                native
                                variant="filled"
                                id="payCycle"
                                onChange={handleChangeCycle()}
                                value={payOption}
                            >
                                {payOptions.map((option, index) => (
                                    <option
                                        key={option.title}
                                        data={option.tipLabel}
                                        value={index}
                                    >
                                        {option.title}
                                    </option>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </Hidden>

                    <div >
                        <CustomFormControlLabel 
                            control={
                                <CustomSwitch
                                    checked={includesSuperannuation}
                                    onChange={(event) => handleUpdate({ includesSuperannuation: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Includes Superannuation"
                        />
                        <FormHelperText className={classes.switchHelperText} >{includesSuperannuation ? "Super is taken out of your salary" : "Super is paid in addition to your salary"}</FormHelperText>
                    </div>


                    <div >
                        <CustomFormControlLabel 
                            control={
                                <CustomSwitch
                                    checked={adjustSuperannuationRate}
                                    onChange={(event) => handleUpdate({ adjustSuperannuationRate: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Adjust Superannuation Rate"
                        />
                        <FormHelperText className={classes.switchHelperText}>Default rate is {taxData[yearIndex].superannuation.rate}%</FormHelperText>
                    </div>
                    {adjustSuperannuationRate && (
                        <Box className={classes.insetInput}>
                            <div >
                                <StyledTextField fullWidth
                                    label="Superannuation Rate"
                                    fullWidth
                                    value={superannuationRate}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: PercentFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ superannuationRate: Number(event.target.value) })}
                                    id="superannuationRate"
                                />
                            </div>
                        </Box>
                    )}


                    <div >
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    checked={disableHELP ? false : HELP}
                                    disabled={disableHELP}
                                    onChange={disableHELP ? null : (event) => handleUpdate({ HELP: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label={`Student loan`}
                        />
                        <FormHelperText className={classes.switchHelperText}>{`HECS, HELP, VSL, TSL...`} </FormHelperText>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}



// --------------------------------------------------------------------------------
// DAILY
// --------------------------------------------------------------------------------

const CasualDailyOptions = () => {
    // observe Hook rules - declare 'use' regardless of conditionals
    const classes = useStyles();
    const [{ casualDaily, payOption, overtime }, dispatch] = useCalculator();

    if (payOptions[payOption].type !== "daily") return null;


    // Append a new "OVERTIMRE_DATA object onto the overtime state
    const addOvertime = () => {
        dispatch({ type: DISPATCH_UPDATE, overtime: overtime.concat({ ...OVERTIME_DATA }) })
    }

    const handleChange = (event) => {
        console.log(event.target);
        const name = event.target.name;
        const value = event.target.value;
        const data = { ...casualDaily }
        switch (name) {
            case "days":
                data[name] = value;
                break;
            case "frequency":
                data.days = Math.round(convertFrequency(data.frequency, value, data.days));
                data.annual = FREQUENCY_DEFAULTS[1];
                data[name] = value;
                break;
            case "annual":
                data[name] = value;
                break;
            default: return;
        }

        dispatch({
            type: DISPATCH_UPDATE,
            ...{ casualDaily: data }
        })
    }


    return (
        <>
            <Grid container spacing={2} direction="row">
                <Grid item xs={4} >
                    <StyledTextField
                        label="Days"
                        value={casualDaily.days}
                        variant="filled"
                        name="days"
                        onChange={event => handleChange(event)}
                    />
                </Grid>
                <Grid item xs={8} >
                    <StyledFormControl variant="filled" fullWidth >
                        <InputLabel htmlFor="frequency">each</InputLabel>
                        <Select
                            native
                            variant="filled"
                            name="frequency"
                            value={casualDaily.frequency}
                            // defaultValue={casualDaily.frequency}
                            onChange={event => handleChange(event)}
                        >
                            {FREQUENCY_DAILY.map((option, index) => (
                                <option
                                    key={"option" + index}
                                >
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </StyledFormControl>
                </Grid>
            </Grid>
            <FormHelperText className={classes.helperText}>{`Adjust how many days per ${casualDaily.frequency.toLowerCase()} you work`} </FormHelperText>

            <Grid container spacing={2} direction="row">
                {casualDaily.frequency !== FREQUENCY_DAILY[FREQUENCY_DAILY.length - 1] && (
                    <>
                        <Grid item xs={4} >
                            <StyledTextField
                                label={`${casualDaily.frequency}s`}
                                value={casualDaily.annual}
                                variant="filled"
                                name="annual"
                                onChange={event => handleChange(event)}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <StyledTextField style={{ width: '100%' }}
                                label={`each`}
                                value={`Year`}
                                variant="filled"
                            />
                        </Grid>
                        <FormHelperText className={classes.helperText} style={{ marginLeft: 10, marginTop: 0 }}>{`Adjust how many ${casualDaily.frequency.toLowerCase()}s per year you work`} </FormHelperText>
                    </>
                )}
            </Grid>


            <Overtime />
            <div style={{ alignItems: "flex-end", paddingTop: 20, paddingBottom: 10, paddingRight: 5 }}>
                <CustomTinyButton
                    style={{ display: "block", marginTop: 0, marginRight: 0, marginLeft: "auto" }}
                    variant="contained"
                    onClick={() => addOvertime(overtime)}
                >
                    Add Overtime
                </CustomTinyButton>
            </div>
        </>
    )
}




// --------------------------------------------------------------------------------
// HOURLY
// --------------------------------------------------------------------------------
const CasualHourlyOptions = () => {
    // observe Hook rules - declare 'use' regardless of conditionals
    const classes = useStyles();
    const [{ salary, payOption, casualHourly, overtime }, dispatch] = useCalculator();

    if (payOptions[payOption].type !== "hourly") return null;

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const data = { ...casualHourly }
        switch (name) {
            case "hours":
                data[name] = value;
                break;
            case "frequency":
                data.hours = Math.round(convertFrequency(data.frequency, value, data.hours) * 2) / 2;
                data[name] = value;
                break;
            case "annual":
                data[name] = value;
                break;
            default: return;
        }

        dispatch({
            type: DISPATCH_UPDATE,
            ...{ casualHourly: data }
        })
    }

    // Append a new "OVERTIMRE_DATA object onto the overtime state
    const addOvertime = () => {
        // add the current hourly rate as a default
        const defaultOvertimeData = { ...OVERTIME_DATA };
        defaultOvertimeData.rate = salary;
        dispatch({ type: DISPATCH_UPDATE, overtime: overtime.concat({ ...defaultOvertimeData }) })
    }


    return (
        <React.Fragment>
            <Grid container spacing={2} direction="row">
                <Grid item xs={4} >
                    <StyledTextField
                        label="Hours"
                        value={casualHourly.hours}
                        variant="filled"
                        name="hours"
                        onChange={event => handleChange(event)}
                    />
                </Grid>
                <Grid item xs={8} >
                    <StyledFormControl variant="filled" fullWidth >
                        <InputLabel htmlFor="frequency">each</InputLabel>
                        <Select
                            native
                            variant="filled"
                            name="frequency"
                            value={casualHourly.frequency}
                            onChange={event => handleChange(event)}
                        >
                            {FREQUENCY_HOURLY.map((option, index) => (
                                <option key={"option" + index}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </StyledFormControl>
                </Grid>
            </Grid>
            <FormHelperText className={classes.helperText}>{`Adjust how many hours per ${casualHourly.frequency.toLowerCase()} you work`} </FormHelperText>

            <Grid container spacing={2} direction="row">
                {casualHourly.frequency !== FREQUENCY_HOURLY[FREQUENCY_HOURLY.length - 1] && (
                    <>
                        <Grid item xs={4} >
                            <StyledTextField
                                label={`${casualHourly.frequency}s`}
                                value={casualHourly.annual}
                                variant="filled"
                                name="annual"
                                onChange={event => handleChange(event)}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <StyledTextField style={{ width: '100%' }}
                                label={`each`}
                                value={`Year`}
                                variant="filled"
                            />
                        </Grid>
                        <FormHelperText className={classes.helperText} style={{ marginLeft: 10, marginTop: 0 }} >{`Adjust how many ${casualHourly.frequency.toLowerCase()} per year you work`} </FormHelperText>
                    </>
                )}
            </Grid>


            <Overtime />

            <div style={{ alignItems: "flex-end", paddingTop: 20, paddingBottom: 10, paddingRight: 5 }}>
                <CustomTinyButton
                    style={{ display: "block", marginTop: 0, marginRight: 0, marginLeft: "auto" }}
                    variant="contained"
                    onClick={() => addOvertime(overtime)}
                >
                    Add Overtime
                </CustomTinyButton>
            </div>


        </React.Fragment >
    )
}



// --------------------------------------------------------------------------------
// OVERTIME
// --------------------------------------------------------------------------------

const Overtime = () => {
    // observe Hook rules
    const classes = useStyles();
    const [{ overtime, payOption, salary }, dispatch] = useCalculator();

    // skip
    if (!overtime || overtime.length === 0) return null;

    const deleteOvertime = (index) => {
        overtime.splice(index, 1)
        let data = { overtime };
        dispatch({
            type: DISPATCH_UPDATE_OVERTIME,
            ...data
        })
    }

    const handleChangeOvertime = (event, index) => {
        const hourlyRate = salary;
        const name = event.target.name;
        const value = event.target.value;
        const data = [...overtime];

        switch (name) {
            case "hours":
                data[index][name] = Number(value);
                break;
            case "frequency":
            case "type":
                data[index][name] = value;
                break;
            case "hourlyRate":
                data[index][name] = Number(value);
                if (hourlyType) {
                    data[index].loadingRate = Number(value / hourlyRate);
                } else {
                    // data[index].loadingRate = 1
                }
                break;
            case "loadingRate":
                data[index][name] = Number(value);
                // this is only available in hourlyType mode
                data[index].hourlyRate = Number(hourlyRate * data[index].hours * data[index].loadingRate);
                break;
            default: return;
        }
        dispatch({
            type: DISPATCH_UPDATE_OVERTIME,
            ...{ overtime: data }
        })

    }


    // only include hourly rate if hourly rate is set via pay cycle
    const hourlyType = payOptions[payOption].type.indexOf("hour") >= 0

    return (
        <React.Fragment>
            <h3>OVERTIME</h3>
            {overtime.map((ot, index) => {
                const hourlyRate = salary;
                let overtime_value = (hourlyType && ot.type === "Loading") ? ot.hours * ot.loadingRate * hourlyRate : ot.hours * ot.hourlyRate;
                overtime_value = overtime_value > 0 ? (
                    <NumberFormat decimalScale={2} value={(overtime_value)} displayType={'text'} thousandSeparator={true} prefix={'$'} />) : ('$0')
                return (
                    <React.Fragment key={"overtime" + index}>
                        {hourlyType ? renderOvertimeHourly(ot, index, handleChangeOvertime, deleteOvertime, classes) : renderOvertimeDaily(ot, index, handleChangeOvertime, deleteOvertime, classes)}
                        <FormHelperText className={classes.helperText} >
                            Overtime of {overtime_value} per {ot.frequency.toLowerCase()}
                        </FormHelperText>
                    </React.Fragment>
                )
            })}
        </React.Fragment >
    )
}


const renderOvertimeHourly = (ot, index, handleChangeOvertime, deleteOvertime, classes) => {
    return (
        <React.Fragment key={"hourly" + index}>
            <Grid container key={"overtime" + index} direction="row" spacing={1}>
                <Grid item xs={4} >
                    <StyledTextField
                        label="Hours"
                        value={ot.hours}
                        variant="filled"
                        name="hours"
                        InputProps={{
                            inputComponent: FloatFormat,
                        }}
                        onChange={event => handleChangeOvertime(event, index)}
                    />
                </Grid>
                <Grid item xs={8}>
                    <StyledFormControl variant="filled" fullWidth >
                        <InputLabel htmlFor="frequency">each</InputLabel>
                        <Select
                            native
                            variant="filled"
                            name="frequency"
                            onChange={event => handleChangeOvertime(event, index)}
                        >
                            {FREQUENCY_HOURLY.map((option, index) => (
                                <option key={"option" + index}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </StyledFormControl>
                </Grid>
                <Grid item xs={4}>

                    {ot.type === "Hourly" ? (
                        <StyledTextField
                            label="Hourly rate"
                            name="hourlyRate"
                            value={ot.hourlyRate}
                            variant="filled"
                            InputProps={{
                                inputComponent: PriceFormat,

                            }}
                            onChange={event => handleChangeOvertime(event, index)}
                        />
                    ) : (
                            <StyledTextField
                                label="Loading rate"
                                name="loadingRate"
                                value={ot.loadingRate}
                                variant="filled"
                                InputProps={{
                                    inputComponent: MultiplierFormat,
                                }}
                                onChange={event => handleChangeOvertime(event, index)}
                            />

                        )}
                </Grid>
                <Grid item xs={7} >
                    <StyledFormControl variant="filled" fullWidth >
                        <InputLabel htmlFor="frequency">calculation</InputLabel>
                        <Select
                            native
                            variant="filled"
                            name="type"
                            value={ot.type}
                            onChange={event => handleChangeOvertime(event, index)}
                        >
                            {OVERTIME_TYPE.map((option, index) => (
                                <option key={"type" + index} value={option}>
                                    {`${option} rate`}
                                </option>
                            ))}
                        </Select>
                    </StyledFormControl>

                </Grid>
                <Grid item xs={1} >
                    <Tooltip title={`Delete overtime`} placement="top">
                        <IconButton aria-label="delete" className={classes.deleteButton} size="small" onClick={() => deleteOvertime(index)}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>


                </Grid>
            </Grid>
        </React.Fragment>
    )
}


const renderOvertimeDaily = (ot, index, handleChangeOvertime, deleteOvertime, classes) => {
    return (
        <React.Fragment key={"daily" + index}>
            <Grid container key={"overtime" + index} style={{ width: '100%' }} direction="row" spacing={1}>
                <Grid item xs={3} >
                    <StyledTextField
                        label="Hours"
                        value={ot.hours}
                        variant="filled"
                        name="hours"
                        onChange={event => handleChangeOvertime(event, index)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <StyledTextField
                        label="Rate"
                        name="hourlyRate"
                        value={ot.hourlyRate}
                        variant="filled"
                        InputProps={{
                            inputComponent: PriceFormat,

                        }}
                        onChange={event => handleChangeOvertime(event, index)}
                    />
                </Grid>
                <Grid item xs={5}>
                    <StyledFormControl variant="filled" fullWidth >
                        <InputLabel htmlFor="frequency">each</InputLabel>
                        <Select
                            native
                            variant="filled"
                            name="frequency"
                            onChange={event => handleChangeOvertime(event, index)}
                        >
                            {FREQUENCY_HOURLY.map((option, index) => (
                                <option key={"option" + index}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </StyledFormControl>
                </Grid>

                <Grid item xs={1} >
                    <Tooltip title={`Delete overtime`} placement="top">
                        <IconButton aria-label="delete" className={classes.deleteButton} size="small" onClick={() => deleteOvertime(index)}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>

                </Grid>
            </Grid>
        </React.Fragment>
    )
}






const convertFrequency = (from, to, currentValue) => {
    let newValue = currentValue;
    switch (from.toLowerCase()) {
        case "day":
            break;
        case "week":
            newValue /= 5;
            break;
        case "fortnight":
            newValue /= 10;
            break;
        case "month":
            newValue = (newValue * 12) / 270
            break;
        case "year":
            newValue /= 270
            break;
        default:
            break;
    }


    switch (to.toLowerCase()) {
        case "day":
            break;
        case "week":
            newValue *= 5;
            break;
        case "fortnight":
            newValue *= 10;
            break;
        case "month":
            newValue = (newValue * 270) / 12;
            break;
        case "year":
            newValue *= 270
            break;
        default:
            break;
    }
    return newValue;
}