import React, { } from 'react'
import {
    Grid,
    Box,
    InputLabel,
    Select,
    Hidden,
    Typography,
    makeStyles,
    useMediaQuery,
    useTheme,
    FormHelperText,
    Tooltip,
} from '@material-ui/core';

import { DatePicker } from "@material-ui/pickers";
import { taxData } from '../../../core/calculatorData';
import CheckIcon from '@material-ui/icons/Check';

import { useCalculator, salarySacraficeOptions, DISPATCH_UPDATE, DISPATCH_UPDATE_SALARY_SACRAFICE } from '../../../state/calculatorState';
import { StyledTextField, StyledSmallSelectControl, StyledFormControl, CustomSwitch, CustomFormControlLabel, StyledLink } from '../../common/CustomComponents';
import { PercentFormat, IntegerFormat, PriceIntegerFormat } from '../../common/customtFormatters'
import { formatMoney } from '../../../utils/utils';

const useStyles = makeStyles(theme => {
    return {
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },

        helperText: {
            opacity: 0.5,
            paddingBottom: 12,
            color: theme.palette.primary.contrastText,
            letterSpacing: "-0.25px",
        },

        alertText: {
            opacity: 0.8,
            paddingBottom: 12,
            fontWeight: "bold",
            color: theme.palette.text.alert,
        },
        switchHelperText: {
            opacity: 0.5,
            marginTop: -10,
            paddingLeft: 45,
            color: theme.palette.primary.contrastText,
            letterSpacing: "-0.25px",
        },

        insetInput: {
            paddingTop: 0,
            paddingLeft: 45,
        },

        seperator: {
            height: 1,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
            //margin: '5px 0px 0 0',
            margin: '8px 0 6px 0px',
        },


    }
});



export const AdvancedOptions = () => {
    const theme = useTheme();
    const classes = useStyles();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const ToolTipDelay = 2000;

    const [{
        yearIndex,
        year,
        adjustPayDay,
        payDay,
        adjustSuperannuation,
        adjustSalaryScaracfice,
        salaryScaracficeAmount,
        salarySacraficeOption,
        adjustSalaryScaracficeAdditional,
        additionalSuper,
        over65,
        adjustDeductions,
        taxableDeductions,
        fringeBenefits,
        nonResident,
        backpacker,
        haveTFN,
        noTaxFree,
        notForProfit,
        SFSS,
        SAPTO,
        spouseSeparated,
        hasPrivateHealthcare,
        medicareSurcharge,
        medicareExemption,
        spouse,
        spouseIncome,
        dependants,
        dependantsCount,
        medicareExemptionValue,
        medicareAdjustment,
        superannationConcessionalRemaining,
        adjustSuperannuationRate,
        superannuationRate,
        warnings,
        otherTaxableIncome,
    }, dispatch] = useCalculator();

    const handleUpdate = (data) => dispatch({
        type: DISPATCH_UPDATE,
        ...data
    })


    const handleUpdateSalarySacrafice = (data) => {
        dispatch({
            type: DISPATCH_UPDATE_SALARY_SACRAFICE,
            ...data
        })
    }

    const handleChangeYear = () => event => {
        return (
            dispatch({
                type: DISPATCH_UPDATE,
                year: taxData[event.target.value].year,
                yearIndex: event.target.value,
            })
        )
    }






    const fundContributionCapMultiplier = over65 ? 1 : 3;
        let nonConcesssionalCap = 0;
        let concessionalCap = 0;
        if (taxData[yearIndex].superannuation && taxData[yearIndex].superannuation.nonConcesssionalCap !== undefined) {
            nonConcesssionalCap =  Number(taxData[yearIndex].superannuation.nonConcesssionalCap);
            concessionalCap = Number(taxData[yearIndex].superannuation.concessionalCap);
        }
        const fundContributionCap = nonConcesssionalCap * fundContributionCapMultiplier


    // option toggles
    let disableTaxFree = false;
    let disableBackpacker = false;
    let disableSFSS = false;
    let disableMEDICARE = false;


    if (nonResident || backpacker) {
        disableTaxFree = true;
        disableMEDICARE = true;

        if (backpacker) {
            disableSFSS = true;
        }
    }

    const hideMedicareOptions = disableMEDICARE || (medicareExemption && medicareExemptionValue === 0);
    const minDate = new Date(`${Number(year) - 1}-07-01`)
    const maxDate = new Date(`${Number(year)}-07-01`)
    maxDate.setDate(maxDate.getDate() - 1);

    const paddingStyle = { paddingBottom: small ? 2 : 2 };

    return (
        <Box className={classes.root} >
            <Grid container spacing={(small ? 2 : 10)} style={{ width: '100%' }} display="flex" >
                <Grid item sm={12} md={6} style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0, width: '100%' }} >


                    {/* <div style={paddingStyle}>
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    checked={adjustSuperannuationRate}
                                    onChange={(event) => handleUpdate({ adjustSuperannuationRate: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Adjust Superannuation Rate"
                        />
                        <FormHelperText className={classes.switchHelperText}>Default rate is {taxData[yearIndex].superannuation.rate}%</FormHelperText>
                    </div>
                    {adjustSuperannuationRate && (
                        <Box className={classes.insetInput}>
                            <div style={paddingStyle}>
                                <StyledTextField
                                    label="Superannuation Guarantee Rate"
                                    fullWidth
                                    value={superannuationRate}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: PercentFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ superannuationRate: Number(event.target.value) })}
                                    id="superannuationRate"
                                />
                            </div>
                        </Box>
                    )} */}

                    <div style={paddingStyle}>
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    checked={adjustSalaryScaracfice}
                                    onChange={(event) => handleUpdate({ adjustSalaryScaracfice: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Salary Sacrifice Superannuation"
                        />
                        <FormHelperText className={classes.switchHelperText}>Reportable Superannuation contributions</FormHelperText>
                            
                    </div>

                    {adjustSalaryScaracfice && (
                        <Box className={classes.insetInput}>
                            <div style={paddingStyle}>
                                <Box display="flex" flexDirection="row" >
                                    <Box flex='3' style={{ paddingRight: 10 }}>

                                        <StyledTextField
                                            label="Amount"
                                            fullWidth
                                            value={salaryScaracficeAmount}
                                            variant="filled"
                                            InputProps={{
                                                inputComponent: salarySacraficeOptions[salarySacraficeOption] === "Percent" ? PercentFormat : PriceIntegerFormat,
                                            }}
                                            onChange={(event) => handleUpdateSalarySacrafice({ salaryScaracficeAmount: (Number(event.target.value)) })}
                                            id="salarySacrificeSuper"
                                        />

                                    </Box>
                                    <Box flex='4' >
                                        <StyledFormControl variant="filled" fullWidth >
                                            <InputLabel htmlFor="salarySacrificeOption">Frequency</InputLabel>
                                            <Select
                                                native
                                                variant="filled"
                                                id="salarySacrificeOption"
                                                onChange={(event) => handleUpdateSalarySacrafice({ salarySacraficeOption: (event.target.value) })}
                                                value={salarySacraficeOption}
                                            >
                                                {salarySacraficeOptions.map((option, index) => (
                                                    <option
                                                        key={option}
                                                        value={index}
                                                    >
                                                        {option}
                                                    </option>
                                                ))}
                                            </Select>
                                        </StyledFormControl>
                                    </Box>
                                </Box>

                                <FormHelperText className={classes.alertText} style={{ paddingBottom: 0 }}>{superannationConcessionalRemaining < 1 ? `Over Concessional limit of $${formatMoney(concessionalCap, 0)}` : ''}</FormHelperText>

                                <CustomFormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={adjustSalaryScaracficeAdditional}
                                            onChange={(event) => handleUpdate({ adjustSalaryScaracficeAdditional: (event.target.checked) })}
                                            color="primary"
                                        />
                                    }
                                    label="Additional to salary"
                                />
                                <FormHelperText className={classes.switchHelperText}>{adjustSalaryScaracficeAdditional < 1 ? 'Included in your salary package' : 'On top of your salary'}</FormHelperText>
                            </div>

                        </Box>
                    )}

                    <div style={paddingStyle}>
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    checked={adjustSuperannuation}
                                    onChange={(event) => handleUpdate({ adjustSuperannuation: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Voluntary Superannuation"
                        />
                        <FormHelperText className={classes.switchHelperText}>Contributions made after tax</FormHelperText>
                    </div>

                    {adjustSuperannuation && (
                        <Box className={classes.insetInput}>
                            <div style={paddingStyle}>
                                <StyledTextField
                                    label="Annual contributions"
                                    value={additionalSuper}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: PriceIntegerFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ additionalSuper: (event.target.value) })}
                                    id="additionalSuper"
                                />
                            </div>
                            <FormHelperText className={classes.helperText}>Effects annual tax calculations only.</FormHelperText>
                        </Box>
                    )}

<div style={paddingStyle}>
<CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={over65}
                                        onChange={(event) => handleUpdate({ over65: event.target.checked })}
                                        color="primary"
                                    />
                                }
                                label="Over 65"
                            />
                            <FormHelperText className={classes.switchHelperText}>{`Your Superannuation fund cap is $${formatMoney(fundContributionCap, 0)}`}</FormHelperText>
                            </div>



                    <div className={classes.seperator} />
                    <div style={paddingStyle}>
                        <Tooltip
                            enterDelay={ToolTipDelay}
                            title="Generally you're a non resident if you are visiting Australia for less than 6 months. However if you have been living in Australia for more than 6 months, have been in the same job and living in the same residence, you are treated as a resident."
                            placement="top">
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={nonResident}
                                        onChange={(event) => handleUpdate({ nonResident: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label="Non Resident"
                            />
                        </Tooltip>
                        <FormHelperText className={classes.switchHelperText} >{`Select if you're a visitor`} </FormHelperText>
                    </div>
                    <div style={paddingStyle}>
                        <Tooltip
                            enterDelay={ToolTipDelay}
                            title="Working holiday makers who are in Australia on a 417 or 462 visa."
                            placement="top">
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={disableBackpacker ? false : backpacker}
                                        disabled={disableBackpacker}
                                        onChange={disableBackpacker ? null : (event) => handleUpdate({ backpacker: (event.target.checked), nonResident: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label="Working Holiday Maker"
                            />
                        </Tooltip>
                        <FormHelperText className={classes.switchHelperText}>{`Visa 417, 462 (Backpacker)`} </FormHelperText>
                        {backpacker && (
                            <Box className={classes.insetInput}>
                                <CustomFormControlLabel style={{ paddingRight: 10 }}
                                    control={
                                        <CustomSwitch
                                            checked={haveTFN}
                                            onChange={(event) => handleUpdate({ haveTFN: (event.target.checked) })}
                                            color="primary"
                                        />
                                    }
                                    label="Tax File Number"
                                />
                                {/* <FormHelperText className={classes.switchHelperText}>{`Do you have a Tax File Number?`} </FormHelperText> */}
                            </Box>
                        )}
                    </div>
                    <div style={paddingStyle}>
                        <Tooltip
                            enterDelay={ToolTipDelay}
                            title="You only need to check this option if you are calculating a second salary. If you are a non-resident this will be excluded automatically."
                            placement="top">
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={disableTaxFree ? false : noTaxFree}
                                        disabled={disableTaxFree}
                                        onChange={disableTaxFree ? null : (event) => handleUpdate({ noTaxFree: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label="No tax-free threshold"
                            />
                        </Tooltip>
                        <FormHelperText className={classes.switchHelperText}>{`Apply for tax on a second job`} </FormHelperText>
                    </div>

                    {/* <div style={paddingStyle}>
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={notForProfit}
                                        onChange={(event) => handleUpdate({ notForProfit: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label="Not for profit organisation (NFP)"
                            />
                        <FormHelperText className={classes.switchHelperText}>{`Exempt from income tax`} </FormHelperText>
                    </div> */}



                    <Hidden smDown>
                        <div className={classes.seperator} />

                        <div style={paddingStyle}>
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={adjustPayDay}
                                        onChange={(event) => handleUpdate({ adjustPayDay: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label="Set date of first payment"
                            />
                            <FormHelperText className={classes.switchHelperText}>{`Use this if you did not work a full year or if you need to check for 53 week or 27 fortnight years`} </FormHelperText>
                        </div>
                        {adjustPayDay && (
                            <div style={paddingStyle}>
                                <Box className={classes.insetInput}>
                                    <StyledFormControl variant="filled" fullWidth>
                                        <DatePicker
                                            // autoOk
                                            format="dddd Do MMMM"
                                            label={"Date of first pay"}
                                            // disableFuture
                                            value={payDay}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            onChange={date => handleUpdate({ payDay: date.format('YYYY-MM-DD') })}
                                        />
                                    </StyledFormControl>
                                </Box>
                            </div>
                        )}

                    </Hidden>
                </Grid>

                <Grid item sm={12} md={6} style={{ paddingLeft: small ? 0 : 12, paddingBottom: 0, paddingTop: 0 }} >

                    <Hidden mdUp>
                        <div className={classes.seperator} />
                    </Hidden>

                    <div style={paddingStyle}>
                        <Tooltip
                            enterDelay={ToolTipDelay}
                            title="Student Funancial Suppliment Scheme (SFSS) loan"
                            placement="top">
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={disableSFSS ? false : SFSS}
                                        disabled={disableSFSS}
                                        onChange={disableSFSS ? null : (event) => handleUpdate({ SFSS: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label={`SFSS`}
                            />
                        </Tooltip>
                        <FormHelperText className={classes.switchHelperText}>
                            <StyledLink href="/student-loans">Student Financial Suppliment Scheme</StyledLink>
                        </FormHelperText>
                    </div>

                    <div className={classes.seperator} />


                    <div style={paddingStyle}>
                        <Grid container style={{ display: "flex" }}>


                            <Grid item xs={12}>
                                <CustomFormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={hasPrivateHealthcare}
                                            disabled={medicareSurcharge === 0}
                                            onChange={(event) => handleUpdate({ hasPrivateHealthcare: (event.target.checked) })}
                                            color="primary"
                                        />
                                    }
                                    label="Private healthcare"
                                />
                                <FormHelperText className={classes.switchHelperText}>
                                    You may require private healthcare to avoid the Medicare surcharge
                                </FormHelperText>
                            </Grid>


                            <Grid item >
                                <Tooltip
                                    enterDelay={ToolTipDelay}
                                    title="If you are not entitled to Medicare, you will not pay the levy."
                                    placement="top">
                                    <CustomFormControlLabel
                                        control={
                                            <CustomSwitch
                                                checked={medicareExemption}
                                                disabled={disableMEDICARE}
                                                onChange={(event) => handleUpdate({ medicareExemption: (event.target.checked) })}
                                                color="primary"
                                            />
                                        }
                                        label="Medicare Exemption"
                                    />

                                </Tooltip>
                                <FormHelperText className={classes.switchHelperText}>
                                    <StyledLink href="https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-exemption/" aria-label="Check eligibility">Check your eligibility</StyledLink>

                                </FormHelperText>
                            </Grid>
                            {medicareExemption && !disableMEDICARE && (
                                <Grid item style={{ flex: 1, paddingRight: 20, visibility: medicareExemption && !disableMEDICARE ? "visible" : "hidden" }}>
                                    <StyledSmallSelectControl disabled={!medicareExemption} fullWidth style={{ paddingBottom: 0 }}>
                                        <Select
                                            native
                                            id="exemption"
                                            // onChange={handleUpdate()}
                                            onChange={(event) => handleUpdate({ medicareExemptionValue: (event.target.value) })}
                                            value={medicareExemptionValue}
                                        >
                                            <option value={1}>Full</option>
                                            <option value={0.5}>Half</option>
                                        </Select>
                                    </StyledSmallSelectControl>
                                </Grid>
                            )}
                        </Grid>
                    </div>

                    <div style={paddingStyle}>
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    disabled={hideMedicareOptions}
                                    checked={dependants}
                                    onChange={(event) => handleUpdate({ dependants: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Dependant Children"
                        />
                        <FormHelperText className={classes.switchHelperText}>
                            <StyledLink href="https://www.ato.gov.au/individuals/medicare-levy/medicare-levy-exemption/dependants-for-medicare-levy-exemption/" aria-label="Check dependant eligibility">Check the eligibility of dependant children</StyledLink>

                        </FormHelperText>
                    </div>
                    {!hideMedicareOptions && dependants && (
                        <Box className={classes.insetInput} style={{ paddingTop: 10 }}>
                            <div style={paddingStyle}>
                                <StyledTextField
                                    label="Number of dependants"
                                    fullWidth
                                    value={dependantsCount}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: IntegerFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ dependantsCount: event.target.value })}
                                    id="deductions"
                                />
                            </div>
                        </Box>
                    )}

                    <div style={paddingStyle}>
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    disabled={hideMedicareOptions}
                                    checked={spouse}
                                    onChange={(event) => handleUpdate({ spouse: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Spouse (married or de-facto)"
                        />
                    </div>

                    {!hideMedicareOptions && spouse && (
                        <Box className={classes.insetInput}>
                            <div style={paddingStyle}>
                                <StyledTextField
                                    label="Spouse's Weekly Income"
                                    fullWidth
                                    value={spouseIncome}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: PriceIntegerFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ spouseIncome: event.target.value })}
                                    id="deductions"
                                />
                            </div>
                        </Box>
                    )}
                    {medicareAdjustment.a > 0 && (
                        <Box className={classes.insetInput} style={{ marginLeft: -30, paddingTop: 5, display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
                            <CheckIcon style={{ marginRight: 20, fontSize: 12 }} />
                            <Box flexDirection="column">
                                <Typography variation="body2" style={{ fontSize: 12 }} >You have a medicare adjustment. You need to complete the
                                <StyledLink href="https://www.ato.gov.au/Forms/Medicare-levy-variation-declaration/" style={{ opacity: 0.5, color: theme.palette.text.link }} aria-label="Medicare levy variation declaration"> Medicare levy variation declaration</StyledLink>
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <div style={paddingStyle}>
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    checked={SAPTO}
                                    onChange={(event) => handleUpdate({ SAPTO: (event.target.checked) })}
                                    color="primary"
                                />
                            }
                            label="Senior Australian Offset (SAPTO)"
                        />
                        <FormHelperText className={classes.switchHelperText}>
                            Refer to <StyledLink href="https://www.ato.gov.au/calculators-and-tools/beneficiary-tax-offset-and-seniors-and-pensioner-tax-offset-calculator/" aria-label="Check eligibility">eligibility criteria</StyledLink>

                        </FormHelperText>


                    </div>

                    {SAPTO && spouse && (
                        <Box className={classes.insetInput} style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={spouseSeparated}
                                        onChange={(event) => handleUpdate({ spouseSeparated: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label="Seperated due to illness"
                            />
                        </Box>
                    )}



                    <div className={classes.seperator} />

                    <div style={paddingStyle}>
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    checked={adjustDeductions}
                                    onChange={(event) => handleUpdate({ adjustDeductions: event.target.checked })}
                                    color="primary"
                                />
                            }
                            label="Other income, deductions or benefits"
                        />
                    </div>

                    {adjustDeductions && (

                        <Box className={classes.insetInput}>
                            <div style={paddingStyle}>
                                <StyledTextField
                                    label="Annual tax deductions"
                                    fullWidth
                                    value={taxableDeductions}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: PriceIntegerFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ taxableDeductions: Number(event.target.value) })}
                                    id="taxableDeductions"
                                />
                                <FormHelperText className={classes.helperText}>All charitable and <StyledLink href='https://www.ato.gov.au/Individuals/Income-and-deductions/Deductions-you-can-claim/'>work related deductions</StyledLink></FormHelperText>

                            </div>

                            <div style={paddingStyle}>
                                <StyledTextField
                                    label="Additional taxable income"
                                    fullWidth
                                    value={otherTaxableIncome}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: PriceIntegerFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ otherTaxableIncome: Number(event.target.value) })}
                                    id="otherTaxableIncome"
                                />
                                <FormHelperText className={classes.helperText}>Other income such as interest or invesment income</FormHelperText>

                            </div>

                            <div style={paddingStyle}>
                                <StyledTextField
                                    label="Fringe benefits"
                                    fullWidth
                                    value={fringeBenefits}
                                    variant="filled"
                                    InputProps={{
                                        inputComponent: PriceIntegerFormat,
                                    }}
                                    onChange={(event) => handleUpdate({ fringeBenefits: Number(event.target.value) })}
                                    id="fringeBenefits"
                                />
                                <FormHelperText className={classes.helperText}>Not including Salary sacrificed superannuation (use Superannaution adjustment)</FormHelperText>
                            </div>

                        </Box>
                    )}
                    <Hidden mdUp>
                        <div className={classes.seperator} />
                        <div style={paddingStyle}>
                            <StyledFormControl variant="filled" style={{ paddingBottom: 10, paddingLeft: 5, width: '100%' }}>
                                <InputLabel htmlFor="year">Tax Year</InputLabel>
                                <Select
                                    native
                                    variant="filled"
                                    id="year"
                                    onChange={handleChangeYear()}
                                    value={yearIndex}
                                >
                                    {taxData.map((option, index) => (
                                        <option
                                            key={option.year + " " + index}
                                            data={option.year}
                                            value={index}
                                        >
                                            {`${option.year} - ${Number(option.year) + 1 - 2000}`}
                                        </option>
                                    ))}
                                </Select>
                            </StyledFormControl>
                        </div>
                        <div style={paddingStyle}>
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={adjustPayDay}
                                        onChange={(event) => handleUpdate({ adjustPayDay: (event.target.checked) })}
                                        color="primary"
                                    />
                                }
                                label="Set date of first payment"
                            />
                            <FormHelperText className={classes.switchHelperText}>{`Use this if you did not work a full year or if you need to check for 53 week or 27 fortnight years`} </FormHelperText>
                        </div>
                        {adjustPayDay && (
                            <div style={paddingStyle}>
                                <Box className={classes.insetInput}>
                                    <StyledFormControl variant="filled" fullWidth>
                                        <DatePicker
                                            // autoOk
                                            label={"Date of first pay"}
                                            // disableFuture
                                            value={payDay}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            onChange={date => handleUpdate({ payDay: date.format('YYYY-MM-DD') })}
                                        />
                                    </StyledFormControl>
                                </Box>
                            </div>
                        )}
                    </Hidden>
                </Grid>


            </Grid>
        </Box>
    );

}
