import React, { useState, useEffect } from 'react'
import {
    Grid,
    makeStyles,
    useTheme,
    useMediaQuery,
    fade,
} from '@material-ui/core';

import ComponentWrapper from '../../common/ComponentWrapper';
import { SummaryChart } from './SummaryChart';
import { SummaryTaxBand } from './SummaryTaxBand';
import { SuperannuationBand } from './SummarySuperannuationBand';
import { SummaryTable } from './SummaryTable';
import { SummaryNotes } from './SummaryNotes';
import { SummaryOptions } from './SummaryOptions';
import { useLayout } from '../../../state/layoutState';
import { useCalculator, payOptions } from '../../../state/calculatorState';
import { spreadAnnualAmounts } from '../../../core/Calculator';

const useStyles = makeStyles(theme => {
    return {
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },

        contentWrapper: {
            padding: 20,
        }
    }
});


export const SummaryContext = React.createContext({});


export const SummaryComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [{ resultsRange, ytd },] = useLayout();
    const [calculator,] = useCalculator();

    const range = payOptions[resultsRange].range;


    const paddingStyle = { paddingTop:20, paddingBottom: 0, paddingLeft: small ? 10 : 10, paddingRight: small ? 10 : 10 };

    // perform a rerender on this component to ensure the fonts are rendered properly
    const [ , setDelayed] = useState(true);
    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 1000);
        return () => clearTimeout(timeout);
    }, []);


    const summaryData = computeSummaryData(calculator, theme, range, ytd);

    return (
        <SummaryContext.Provider value={summaryData}>
            <ComponentWrapper title="Summary">
                <div className={classes.contentWrapper} style={paddingStyle} >
                        <Grid container >
                            <Grid item xs={12}>
                                <SummaryOptions />
                            </Grid>
                            <Grid item xs={12} xl={8}>
                                <SummaryTable />
                                <SummaryNotes />
                            </Grid>
                            <Grid item xs={12} xl={4}>
                            <Grid container >
                                <Grid item sm={12} md={6} xl={12}>
                                    <SummaryChart />
                                    </Grid>
                                <Grid item sm={12} md={6} xl={12} >
                                    <SummaryTaxBand />
                                    <SuperannuationBand />
                                </Grid>
                                </Grid>
                                </Grid>
                        </Grid>
                </div>
            </ComponentWrapper>
        </SummaryContext.Provider>
    )
}


const computeSummaryData = (calculator, theme, range, ytd) => {
    let {
        net,
        income,
        baseSalary,
        adjustDeductions,
        deductions,
        superannuationSacrafice,
        adjustSalaryScaracficeAdditional,
        adjustSalaryScaracfice,
        incomeTax,
        extraWithholdingTax,
        grossTax,
        medicare,
        medicareSurchargeLiability,
        medicareAdjustment,
        medicareDescription,
        HELP, SFSS, help, sfss,
        otherTaxesAndLevies,
        division293,
        offsets,
        totalSuperannuation,
        includesSuperannuation,
        otherTaxableIncome,
        superannuation,
        superannuationCoContribution,

        superannuationConcessional,
        superannationNonConcessional,


        adjustSuperannuation,
        additionalSuper,
        listo,
        lito,
        lamito,
        sapto,
        superannuationTax,
        payments,
        YTD,
        warnings,
    } = calculator;

    // colors
    const primary = theme.palette.primary;
    const secondary = theme.palette.secondary;
    const tertiary = theme.palette.tertiary;
    const nc = fade(theme.palette.background.overlay, 0.5);


    const taxReturn =  grossTax.m*12 - grossTax.a;


    // data manipulations
    const studentLoan = HELP || SFSS;
    const studentLoanAmount = combine(help, sfss);

    const superComment = includesSuperannuation ? "Included in your salary" : "On top of your salary";
    const superSacraficeComment = adjustSalaryScaracfice && adjustSalaryScaracficeAdditional ? "On top of your salary":'';

    const superTaxComment = listo && range === "a" ? `Including offset. Paid from your superannuation fund` : "Taxed from your super fund";

    const offsetsComment =[]; 
   if( lamito > 0 )  offsetsComment.push("LAMITO");
   if( lito > 0 )  offsetsComment.push("LITO");
   if( sapto > 0 )  offsetsComment.push("SAPTO");

   const annual = range === 'a';
   const monthly = range === 'm';
   const isYTD = ytd;
   const neutralYTD = {a:1, m:1, f:1, w:1}


   // simulate super classes
   const concessional = spreadAnnualAmounts({a:superannuationConcessional});
   const nonConcessional = spreadAnnualAmounts({a:superannationNonConcessional});


    // Summary data

    const summaryData = {};
    summaryData.summary = { label: "Pay", comment: "", data: createData(net, range, YTD), color: primary.main, colorB: primary.light }
    summaryData.return = { label: "Tax Return", comment: "", data: createData(taxReturn, range, neutralYTD), color: fade(theme.palette.background.overlay, 0.25) }
    summaryData.extraPayment = annual || monthly ? false : warnings.extraPayment;
    summaryData.payments = annual ? 1 : payments[range];
    summaryData.ytd = isYTD;
    summaryData.YTD = YTD[range];

    summaryData.data = [
        // INCOME
        {
            label: "Taxable Income", shortLabel:"Income", comment: "", id: "expandIncome", data: createData(income, range, YTD), color: primary.main,
            sub: [
                { label: "Base salary", comment: "", data: createData(baseSalary, range, YTD), color: primary[300] },
                { label: "Deductions", comment: "", data: createData(deductions, range, YTD, !adjustDeductions), negative: true, hidden: !adjustDeductions, color: nc },
                { label: "Other Income", comment: "", data: createData(otherTaxableIncome, range, YTD, !adjustDeductions), hidden: otherTaxableIncome === 0, color: primary[600] },
                { label: "Salary sacrifice", comment: "", data: createData(superannuationSacrafice, range, YTD, !adjustSalaryScaracfice), squareBrackets:adjustSalaryScaracficeAdditional, negative: !adjustSalaryScaracficeAdditional, hidden: !adjustSalaryScaracfice, color: nc }
            ]
        },
         // SUPER
         {
            label: "Superannuation", shortLabel:"Super", comment: "", id: "expandSuper", data: createData(totalSuperannuation, range, YTD), color: tertiary.main,
            sub: [
                // { label: "Super guarantee", comment: superComment, data: createData(superannuation, range, YTD), squareBrackets: !includesSuperannuation, color: tertiary[100] },

                { label: "Concessional", comment: '', data: createData(concessional, range, YTD), squareBrackets: !includesSuperannuation, color: tertiary[100] },
                { label: "Non-concessional", comment: '', data: createData(nonConcessional, range, YTD), hidden:(superannationNonConcessional <= 0), squareBrackets: !includesSuperannuation, color: tertiary[100] },

                // { label: "Salary sacrificed", comment: superSacraficeComment, data: createData(superannuationSacrafice, range, YTD), squareBrackets:adjustSalaryScaracficeAdditional, hidden: !adjustSalaryScaracfice, color: tertiary[200] },
                { label: "Co-Contribution", comment: "Government contribution", data: createData(superannuationCoContribution, range, YTD), hidden: superannuationCoContribution === 0, color: tertiary[300] },
                // { label: "Other Contributions", comment: "", data: createData(additionalSuper, range, YTD), hidden: !adjustSuperannuation, color: tertiary[400] },
                { label: "Offset", comment: "Low income super tax offset ", data: createData(listo, range, YTD), negative: true, roundBrackets: true, hidden: (listo === 0), color: tertiary[600] },
                { label: "Tax", comment: superTaxComment, data: createData(superannuationTax, range, YTD), roundBrackets: true, color: tertiary[500] },

            ]
        },
        // TAX
        {
            label: "Tax", shortLabel:"Tax", comment: "", id: "expandTax", data: createData(grossTax, range, YTD), color: secondary.main,
            sub: [
                { label: "Income tax", comment: "", data: createData(incomeTax, range, YTD), color: secondary[300] },
                { label: "Extra withholding", comment: "Due to additional payments", data: createData(extraWithholdingTax, range, YTD), color: secondary[300], hidden: extraWithholdingTax.w === 0},
                
                { label: "Student loan", comment: "HELP, VET, SSL, TSL, SFSS ", data: createData(studentLoanAmount, range, YTD), hidden: !studentLoan, color: secondary[200] },
                { label: "Offsets", comment: offsetsComment.join(', '), data: createData(offsets, range, YTD), negative: true, hidden: offsets.a === 0, color: nc },
                { label: "Division 293", comment: "Additional tax on super", data: createData(division293, range, YTD), hidden: division293 === 0, color: (division293 === 0 ? nc : secondary[400]) },
                { label: "Medicare", comment: medicareDescription, data: createData(medicare, range, YTD), color: secondary[500] },
                { label: "medicareSurcharge", comment: "No Private Healthcare", data: createData(medicareSurchargeLiability, range, YTD), hidden:(medicareSurchargeLiability === 0 ), color: secondary[500] },
                
                { label: "Medicare Adjustment", comment: "Medicare levy reduction", data: createData(medicareAdjustment, range, YTD), negative: true, hidden: medicareAdjustment.a === 0, color: nc },
                { label: "Other", comment: "Other taxes (see note)", data: createData(otherTaxesAndLevies, range, YTD), hidden: otherTaxesAndLevies === 0, color: (otherTaxesAndLevies === 0 ? nc : secondary[700]) },
            ]
        },
       
    ];
    return summaryData;
}

// create fields used to represent data wrt the current range and annual range
const createData = (obj, range, nYTD, skip) => {
    let data = { ...obj };
    if (typeof (obj) !== "object") {
        // if the obj is not an object is is presumably a single annual figure
        // convert to a data object
        data = { w: 0, f: 0, m: 0, a: Number(obj) };
    }
    if (skip) {
        return { current: 0, complete: 0, annual: 0 }
    }

    const ytd = { w: data.w*nYTD.w , f:data.f*nYTD.f, m:data.m*nYTD.m, a:data.a*nYTD.a };

    return { current: data[range], currentYTD: ytd[range], complete: data, ytd, annual: data.a }
}

// combine two data objects
const combine = (a, b) => {
    return {
        a: Number(a.a) + Number(b.a),
        m: Number(a.m) + Number(b.m),
        f: Number(a.f) + Number(b.f),
        w: Number(a.w) + Number(b.w),
    }
}
