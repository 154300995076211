import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
} from '@material-ui/core'
import {
    Menu as MenuIcon,
    Keyboard as KeyboardIcon,
    Help as HelpIcon,
    School as SchoolIcon,
    History as HistoryIcon,
    DonutLarge as DonutLargeIcon,
} from '@material-ui/icons';


import { makeStyles, useTheme } from '@material-ui/core/styles';
import { matchPath } from '../../utils/utils';
import ThemeSwitch from './ThemeSwitch';
import Logo from './Logo';
import history from "../../history";

import {
    NAV_HEIGHT,
    HEADER_HEIGHT,
    CALCULATOR_LINK,
    TAX_INFO_LINK,
    TAX_DISTRIBUTION_LINK,
    STUDENT_LOAN_LINK,
    OLD_CALCULATOR_LINK,
    PRIVACY_POLICY_LINK,
    ACCEPTABLE_USE_LINK,
    COOKIES_LINK,
    EMAIL_ADDRESS,
} from '../../constants';
import MiniInput from './MiniInput'


const useStyles = makeStyles(theme => {
    return {
        list: {
            width: 250,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
            height: '100%'
        },
        drawerPaper: {
        },
        appBar: {
            backgroundColor: theme.palette.primary.main,
            padding: 0,
            margin: 0,
            transition: theme.transitions.create(
                ['all'],
                { duration: theme.transitions.duration.complex }
            ),
        },
        nav: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 0,
            transition: theme.transitions.create(
                ['all'],
                { duration: theme.transitions.duration.complex }
            ),
        },
        logo: {
            pointerEvents: 'none',
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            right: 0,
            top: -2,
        },
        switch: {
        },
    }
});


const NavBar = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    let location = useLocation();

    const theme = useTheme();
    const classes = useStyles(offset);
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const isCalculatorPage = matchPath(location.pathname, CALCULATOR_LINK);

    const handleScroll = () => {
        setOffset(window.pageYOffset)
    }

    const handleDrawerToggle = (e, open) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setMobileOpen(open);
    }

    const redirect = (url) => {
        history.push(url);
        window.location.replace(url);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll)
    }, []);


    // const tint = offset =>  fade(theme.palette.primary.main, (1 - 1/(offset/20)));
    let tint = offset > (500 - NAV_HEIGHT) ? theme.palette.primary.main : "rgba(0,0,0,0.1)";
    // tint = location.pathname !== CALCULATOR_LINK ? theme.palette.primary.main : tint;
    tint = isCalculatorPage ? tint : theme.palette.primary.main;

    tint = theme.palette.primary.main;

    const style = {
        // transition: theme.transitions.create("all", {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.standard,
        // })
        // position:'relative',
        backgroundColor: tint,
    };

    const menu = [{
        label: "CALCULATOR",
        icon: <KeyboardIcon />,
        link: CALCULATOR_LINK,
    },
    {
        label: "TAX INFO",
        icon: <HelpIcon />,
        link: TAX_INFO_LINK,
    },

    {
        label: "TAX DISTRIBUTION",
        icon: <DonutLargeIcon />,
        link: TAX_DISTRIBUTION_LINK,
    },
    {
        label: "STUDENT LOANS",
        icon: <SchoolIcon />,
        link: STUDENT_LOAN_LINK,
    },
    {
        label: "ORIGINAL CALCULATOR",
        icon: <HistoryIcon />,
        link: OLD_CALCULATOR_LINK,
    },
    ]

    const auxilleryItems = [{
        label: "Privacy Policy",
        link: PRIVACY_POLICY_LINK,
    },
    {
        label: "Acceptable Use",
        link: ACCEPTABLE_USE_LINK,
    },
    {
        label: "Cookie Policy",
        link: COOKIES_LINK,
    },
    {
        label: "Get in touch",
        link: EMAIL_ADDRESS,
    },
    ]

    const drawer = (
        <div
            className={classes.list}
            role="presentation"
            onClick={(e) => handleDrawerToggle(e, false)}
            onKeyDown={(e) => handleDrawerToggle(e, false)}
        >
            <List>
                {menu.map((item) => (
                    <React.Fragment key={item.label} >
                        <ListItem button onClick={() => redirect(item.link)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                )
                )}
            </List>

            <List>
                {auxilleryItems.map((item) => (
                    <React.Fragment key={item.label} >
                        <ListItem button onClick={() => redirect(item.link)}>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    </React.Fragment>
                )
                )}
            </List>
        </div>
    );


    // active when the page has scrolled
    let active = offset > (HEADER_HEIGHT + (small ? 300 : 140));
    active = isCalculatorPage ? active : false;

    const ToolbarPadding = small ? active ? 0 : 20 : 20;
    const logoPadding = small ? active ? 0 : 16 : 16;
    const logoScale = small ? 'scale(0.75)' : 'scale(1.0)';


    return (
        <AppBar position="absolute" elevation={0} className={classes.appBar} style={style}  >
            <Toolbar className={classes.nav} style={{ cursor: 'pointer', padding: 0, paddingTop: ToolbarPadding, paddingBottom: ToolbarPadding, minHeight: 0 }} onClick={() => redirect(CALCULATOR_LINK)} >

                <div style={{ paddingLeft: 20 }}>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={(e) => handleDrawerToggle(e, true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>

                <div className={classes.logo} style={{ paddingTop: logoPadding, transform: logoScale }} >
                    <Logo />
                </div>

                <div style={{ flexGrow: '1' }} />
                <Box style={{ position: "absolute", top: 25, right: 20 }}>
                    <ThemeSwitch className={classes.switch} />
                </Box>

            </Toolbar>
            {/* <MiniInput active={active} /> */}

            <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={(e) => handleDrawerToggle(e, false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {drawer}
            </Drawer>



        </AppBar>
    )

}


export default NavBar;