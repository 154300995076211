import React from 'react';
import {
    Link,
} from '@material-ui/core';

import { DistributionComponent } from '../components/calculator/distribution/Distribution';
import { Heading, Content, Intro } from '../components/common/LayoutComponents';
import AuxilleryPage from './AuxilleryPageLayout';
import { BUDGET_2020_INFO } from '../constants';

const StudentLoansPage = () => {

    return (
        <AuxilleryPage >
            <Heading>Tax Distribution</Heading>
            <Intro>How is your income tax spent</Intro>
            <Content>
                The Australian Federal Budget sets out how the government raises and spends tax. The treasurer of the government presents this to Parliament on budget night. This is then debated and voted on in the Houses of Parliament propr to becoming enforced by the ATO.

                The table below shows the distribution of tax expenditure. To get an understanding of how your tax is spent, figures are relative to your income.
        </Content>
            <DistributionComponent />
            <Content>
                The information on this table is sourced from the <Link href={BUDGET_2020_INFO} title="Budget 2020">Budget 2019-2020 - Statement 5: Expenses and Net Capital Investment</Link>. These figures are interpolated and assumes that all government revenue is disributed in an equal manner.
            <br /><br />
            </Content>
        </AuxilleryPage>
    )
}

export default StudentLoansPage;
