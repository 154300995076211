import React, { useContext } from 'react'
import {
    Grid,
    InputLabel,
    Select,
} from '@material-ui/core';

import { StyledFormControl } from '../common/CustomComponents'
import { StudentLoanContext } from './StudentLoanComponent'


export const StudentLoanInputOptions = () => {
    const { year, setCurrentYear, dataCategories, category, setCategory } = useContext(StudentLoanContext);
    const currentCategories = dataCategories.find(c => c.year === year);

    const handleChangeYear = () => event => {
        setCurrentYear(event.target.value)
    }

    const handleChangeCategory = () => event => {
        setCategory(event.target.value)
    }

    return (
        <Grid container spacing={4} >
            <Grid item xs={8}>
                <StyledFormControl variant="filled" fullWidth >
                    <InputLabel htmlFor="payCycle">Loan Type</InputLabel>
                    <Select
                        native
                        variant="filled"
                        id="taxCategory"
                        onChange={handleChangeCategory()}
                        value={category}
                    >
                        {currentCategories.categories.map((option, index) => (
                            <option
                                key={`${option} ${index}`}
                                data={option}
                            >
                                {option}
                            </option>
                        ))}
                    </Select>
                </StyledFormControl>


            </Grid>
            <Grid item xs={4}>

                <StyledFormControl variant="filled" fullWidth >
                    <InputLabel htmlFor="taxYear">Tax year</InputLabel>
                    <Select
                        native
                        variant="filled"
                        id="year"
                        onChange={handleChangeYear()}
                        value={year}
                    >
                        {dataCategories.map((option, index) => (
                            <option
                                key={option.year + " " + index}
                                data={option.year}
                                value={option.year}
                            >
                                {`${option.year - 1} - ${Number(option.year) - 2000}`}
                            </option>
                        ))}
                    </Select>
                </StyledFormControl>
            </Grid>
        </Grid>

    )
}


