import React, { useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  fade,
} from '@material-ui/core';

import { IncomeTaxContext } from './IncomeTax';
import { taxData } from '../../core/calculatorData';
import { formatMoney } from '../../utils/utils';


const StyledTableCell = withStyles(theme => ({
  head: {

    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.dark,
    borderBottom: 0,
    //height: PANEL_HEADER_HEIGHT,

    '&:nth-child(2)': {
      textAlign: "center",
    },
    '&:nth-child(3)': {
      whiteSpace: 'nowrap',
    },
  },
  body: {
    // padding:'22px 20px',
    borderBottom: 0,
    fontSize: 12,
    '&:nth-child(2)': {
      textAlign: "center",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => {

  const bg = fade(theme.palette.background.overlay, 0.4);
  const bg_alt = fade(theme.palette.background.overlay, 0.2);
  return {
    root: {

      backgroundColor: bg,
      '&:nth-of-type(odd)': {
        backgroundColor: bg_alt,
      },
    },
  }
})(TableRow);


function createData(bracket, amount, rate) {
  return { bracket, amount, rate };
}

// const rows = [
//   createData('$0 -\u00A0$18,200', '$0', '0%'),
//   createData('$18,201 –\u00A0$37,000', '19¢ for each $1 over $18,200	', '19%'),
//   createData('$37,001 –\u00A0$90,000	', '$3,572 plus 32.5¢ for each $1 over $37,000', '32.5%'),
//   createData('$90,001 -\u00A0$180,000', '$20,797 plus 37¢ for each $1 over $90,000	', '37%'),
//   createData('Over\u00A0$180,000', '$54,097 plus 45¢ for each $1 over $180,000	', '45%'),
// ];

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

export const TaxTable = () => {
  const classes = useStyles();


  const { year, category } = useContext(IncomeTaxContext);

  const currentTaxData = taxData.find(c => c.year === year);
  let taxObject;
  for (const key of Object.keys(currentTaxData)) {
    if (currentTaxData[key].label && currentTaxData[key].label === category) {
      taxObject = currentTaxData[key];
    }
  }
  const taxRates = taxObject.brackets;


  let base = 0;
  const rows = taxRates.map(b => {


    // price formatting!!!
    // label
    let label = `$${formatMoney(b.from, 0, ".", ",")} - $${formatMoney(b.to, 0, ".", ",")}`
    if (b.to === 0) label = `Over $${formatMoney(b.from - 1, 0, ".", ",")}`;

    // value
    let value = '';
    if (b.value === 0) {
      value += "Nil";
    } else {
      if (base > 0) value += `$${formatMoney(base, 0, ".", ",")} plus `;
      value += `${b.value}¢ for every $1`;
      if (b.from > 0) {
        value += ` over $${formatMoney(b.from - 1, 0, ".", ",")}`;
      }

    }

    base += Math.ceil((b.to - b.from) * (b.value / 100));

    // rate
    const rate = `${b.value}%`;

    return createData(label, value, rate);


  })



  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="Tax table">
        <TableHead>
          <TableRow>
            <StyledTableCell><Typography variant="h5">Income</Typography></StyledTableCell>
            <StyledTableCell align="center"><Typography variant="h5">Tax Liability</Typography></StyledTableCell>
            <StyledTableCell align="right"><Typography variant="h5">Tax Rate</Typography></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={row.bracket}>
              <StyledTableCell component="th" scope="row">{row.bracket}</StyledTableCell>
              <StyledTableCell align="right">{row.amount}</StyledTableCell>
              <StyledTableCell align="right">{row.rate}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}