import { useState, useEffect, useRef, useLayoutEffect } from 'react';

export const UseInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}



// Hook
export const useWindowSize = () => {

  function getSize() {
    const isClient = typeof window === 'object';
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    if (typeof window !== 'object') {
      return false;
    }

    function handleResize() {
      const isClient = typeof window === 'object';
      const size = {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      };

      setWindowSize(size);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}




export const useComponentSize = ({ target }) => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    if (typeof window !== 'object') {
      return false;
    }

    function handleResize() {
      const isClient = typeof window === 'object';
      const size = {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      };

      setWindowSize(size);
    }

    if (target) {
      target.addEventListener('resize', handleResize);
      return () => target.removeEventListener('resize', handleResize);
    } else {
      return () => { }
    }
  }, [target]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}



export const useDimensions = () => {
  const ref = useRef();
  const [dimensions, setDimensions] = useState();
  useLayoutEffect(() => {
    setDimensions(ref.current.getBoundingClientRect().toJSON());

  }, [ref, dimensions]
  )
  return dimensions;
}


// image status
export const ImageStatus = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
}


// image cache
const imageCache = new Map();

// Preload image hook
export const useImage = (src) => {

  const cachedImg = imageCache.get(src);
  const initialState = cachedImg ? ImageStatus.LOADED : ImageStatus.LOADING;
  const [status, setStatus] = useState(initialState);
  const mounted = useRef(false);

  useEffect(() => {
    if (!src || status === ImageStatus.LOADED) return;
    mounted.current = false;
    try {
      let image;
      (async () => {
        image = await loadImage(src)
        // if(!mounted.current) return;
        imageCache.set(src, image);
        setStatus(ImageStatus.LOADED);
      })()

    } catch (error) {
      if (!mounted.current) return;
      imageCache.delete(src);
      setStatus(ImageStatus.FAILED);
    }
    return () => {
      mounted.current = false;
    }
  }, [src, status]);
  return [status, cachedImg];
}

// loading image promise
export const loadImage = async (url) => {
  const image = new Image();

  return new Promise((resolve, reject) => {
    const loaded = (event) => {
      image.onload = null
      image.onerror = null
      image.onabort = null
      try { delete image.src }
      catch (e) { }
      resolve(event.target || event.srcElement)

    }
    const errored = (error) => {
      image.onload = null
      image.onerror = null
      image.onabort = null;
      try { delete image.src }
      catch (e) { }
      reject(error);
    }

    image.onload = loaded;
    image.onerror = errored;
    image.onabort = errored;

    image.src = url
  })
}






export const useScrollThreshold = ({ threshold = 0 }) => {

  let [active, setActive] = useState(false);

  useEffect(() => {
    const updateScroll = () => {

      const scrollY = window.pageYOffset;
      const isActive = scrollY > threshold;

      if (active !== isActive) {
        setActive(isActive);
        // why is the setter method not working?
        active = isActive;
      }
    }


    const onScroll = () => {
      console.log("onScroll");
      // window.requestAnimationFrame(updateScroll);
      updateScroll();

    };

    /**
     * Bind the scroll handler if `off` is set to false.
     * If `off` is set to true reset the scroll direction.
     */
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll);
  }, [threshold]);

  return active;
};