import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';

const NEW_ADS = "new_ads" // new ad units
const OLD_ADS = "old_ads"; // old ad units

const mode = Math.random()<0.5 ? NEW_ADS : OLD_ADS;


const useStyles = makeStyles( theme => {
  const bg = theme.palette.background.paper;

    return {
        stickyRight: {
          position: 'sticky',
          top: 60,
          height:600,
          // width:300,
          [theme.breakpoints.down('md')]: {
            top: 280,
            // width:160,
          },
          float: 'right',
          marginRight:'auto',
          marginLeft:'auto',
          zIndex:10,
          // backgroundColor: bg,
        },


        stickyBottom: {
          position: 'sticky',
          bottom: 10,
          marginLeft: 'auto',
          marginRight: 'auto',
          width:320,
          height:50,
          zIndex:10,
          // backgroundColor: bg,
        },


        fuseAd: {
          width:'100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          // padding:20,
          marginBottom:10,
          // backgroundColor: bg,
        },
        oldFuseAd: {
          width:'100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          // padding:20,
          marginBottom:10,
          backgroundColor: bg,
        },
      }

  }
);


// New FUSE ad units
export const PC_header_native = () => {
  // Native header ad
  const classes = useStyles();

  if(mode === OLD_ADS){
    // old PC_home_top
    return <div className={classes.oldFuseAd} dangerouslySetInnerHTML={{ __html: '<div data-fuse="21826643410"></div>'}} />
  }

  // NEW ad unit
  return <div className={classes.fuseAd} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032238564"></div>'}} />

}

export const PC_content_native = () => {
  // multi content
  const classes = useStyles();

  if(mode === OLD_ADS){
    // OLD PC_home_mid_1
    return <div className={classes.oldFuseAd} dangerouslySetInnerHTML={{ __html: '<div data-fuse="21826554203"></div>'}} />
  }

  // NEW ad unit
  return <div className={classes.fuseAd} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032356877"></div>'}} />
}


export const PC_right_sticky = () => {
  // Mobile footer
  const classes = useStyles();
  if( mode === OLD_ADS){
    // PC_home_rhs_1
    return <div className={classes.stickyRight} dangerouslySetInnerHTML={{ __html: '<div data-fuse="21826652118"></div>'}} />
  }

  // NEW ad Unit
  return <div className={classes.stickyRight} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032238567"></div>'}} />
}

export const PC_footer_sticky = () => {
  // Mobile footer
  const classes = useStyles();
  if( mode === OLD_ADS){
    // OLD PC_mob_ros
    return  <div className={classes.stickyBottom} dangerouslySetInnerHTML={{ __html: '<div data-fuse="21826643413"></div>'}} />
  }

  // NEW ad unit
  return <div className={classes.stickyBottom} dangerouslySetInnerHTML={{ __html: '<div data-fuse="22032356874"></div>'}} />
}

