import React from 'react';
import {
    Button,
    TextField,
    Link,
    FormControl,
    Switch,
    FormControlLabel,
    Slider,
    Tooltip,
    TableRow,
    fade
} from '@material-ui/core';
import {
    withStyles,
} from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';

// Demo of a custom button
const style = {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
};

export function CustomInput() {
    return <Button style={style}>inline-style</Button>;
}


export const CustomButton = withStyles(theme => {
    const bgColor = fade(theme.palette.primary.contrastText, 0.08)
    const bgColorHover = fade(theme.palette.primary.contrastText, 0.25)
    return {
        root: {
            boxShadow: '0 2 23 -20 rgba(0,0,0,0.75)',
            backgroundColor: bgColor,
            color: theme.palette.primary.contrastText,

            '&:hover': {
                backgroundColor: bgColorHover,
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:focus': {
            },
        },
    }
})(Button);


export const CustomTinyButton = withStyles((theme) => {
    const bgColor = fade(theme.palette.primary.contrastText, 0.01)
    const bgColorHover = fade(theme.palette.primary.contrastText, 0.15)
    return {
        root: {
            boxShadow: '0px 1px 5px -2px rgba(0,0,0,0.75)',
            backgroundColor: bgColor,
            padding: '6px 10px',
            fontSize: 12,
            lineHeight:1.0,
            color: theme.palette.primary.contrastText,

            '&:hover': {
                backgroundColor: bgColorHover,
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:focus': {
            },
        },
    }
})(Button);



export const PrimaryButton = withStyles(theme => {
    const bgColor = fade(theme.palette.primary.main, 1)
    const bgColorHover = fade(theme.palette.primary.light, 1)
    return {
        root: {
            boxShadow: '0 2 23 -20 rgba(0,0,0,0.75)',
            backgroundColor: bgColor,
            color: theme.palette.primary.contrastText,

            '&:hover': {
                backgroundColor: bgColorHover,
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:focus': {
            },
        },
    }
})(Button);

export const SecondaryButton = withStyles(theme => {
    const bgColor = fade(theme.palette.secondary.main, 1)
    const bgColorHover = fade(theme.palette.primary.light, 1)
    return {
        root: {
            boxShadow: '0 2 23 0 rgba(0,0,0,0.75)',
            backgroundColor: bgColor,
            color: theme.palette.secondary.contrastText,

            '&:hover': {
                backgroundColor: bgColorHover,
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:focus': {
            },
        },
    }
})(Button);



// Custom styling for a form TextField componet
export const StyledTextField = withStyles(theme => {
    return {
        root: {
            '& .MuiInputLabel-formControl': {
                fontWeight: 700,
            },
            '& .MuiInputLabel-shrink': {
                color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                fontWeight: 700,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            '& .MuiInput-underline:before, .MuiFilledInput-underline:before': {
                borderColor: theme.palette.primary.main
            },

            '& .MuiFilledInput-input': {
                fontWeight: 700,
                fontSize: 16,
            },

            '& .MuiFormLabel-filled': {
                lineHeight: 1.4,
            },
        },
    }
})(TextField);



// Custom styling for a form TextField componet
export const StyledPrimaryTextField = withStyles(theme => {
    return {
        root: {
            '& .MuiInputLabel-formControl': {
                fontWeight: 700,

            },
            '& .MuiInputLabel-shrink': {
                color: theme.palette.text.secondary,
                fontWeight: 700,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            '& .MuiInput-underline:before, .MuiFilledInput-underline:before': {
                borderColor: theme.palette.primary.main
            },

            '& .MuiFilledInput-input': {
                fontWeight: 700,
                fontSize: 16,
                backgroundColor: theme.palette.primary.main,
            },

            '& .MuiFormLabel-filled': {
                lineHeight: 1.4,
            },
        },
    }
})(TextField);



export const StyledTextInput = withStyles(theme => {
    return {
        root: {
            '& .MuiInputLabel-formControl': {
                visibility: "none",
            },

            '& .MuiInputLabel-shrink': {
                visibility: "none",
            },
            '& label': {
                visibility: "none",
                fontSize: 14,
            },
            '& .MuiInput-underline:before, .MuiFilledInput-underline:before': {
                borderColor: theme.palette.primary.main
            },

            '& .MuiFilledInput-root': {
                backgroundColor: theme.palette.primary.main,
            },

            '& .MuiFilledInput-input': {
                fontWeight: 700,
                paddingTop: 10,
                color: common.white,
            },
        },
    }
})(TextField);



// Custom styling for a form TextField componet
export const StyledFormControl = withStyles(theme => {
    return {
        root: {
            '& .MuiSelect-select': {
                fontWeight: 700,
                textAlign: "top",
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                fontWeight: 700,
            },
            '& .MuiSelect-underline:before, .MuiFilledInput-underline:before': {
                borderColor: theme.palette.primary.main
            },
            '& label': {
                lineHeight: 1.4,
            },
        },
    }
})(FormControl);

// Custom styling for a form TextField componet
export const StyledFormControlMobile = withStyles(theme => {
    return {
        root: {
            '& .MuiSelect-select': {
                fontWeight: 700,
                textAlign: "top",
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                fontWeight: 700,
            },
            '& .MuiSelect-underline:before, .MuiFilledInput-underline:before': {
                borderColor: theme.palette.primary.main
            },
        },
    }
})(FormControl);


// Custom styling for a form TextField componet
export const StyledSmallSelectControl = withStyles(theme => {
    return {
        root: {
            '& .MuiSelect-select': {
                fontWeight: 700,
                fontSize: 15,
                textAlign: "top",
                lineHeight: '17px',
                paddingLeft: 10,
                marginTop: 4,
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                fontWeight: 700,
                fontSize: 14,
            },
            '& .MuiSelect-underline:before, .MuiFilledInput-underline:before': {
                borderColor: theme.palette.primary.main
            },
        },
    }
})(FormControl);



export const CustomSwitch = withStyles({
    switchBase: {
    },
    root: {
        transform: 'scale(1.0)',
        userSelect: 'none',
    },
    checked: {},
    track: {},
})(Switch);

export const CustomFormControlLabel = withStyles(theme => {
    return {
        root: {
            paddingBottom: 1,
        },
        label: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.h2.fontWeight,
            // fontSize: theme.typography.h2.fontSize,
            paddingLeft: 0,
            paddingBottom: 0,
            // lineHeight:'0.95em',
            letterSpacing: "-0.25px"
        },
    }
})(FormControlLabel);


export const SalarySlider = withStyles(theme => {
    return {
        root: {
            color: theme.palette.primary.main,
            height: 8,
        },
        thumb: {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: `2px solid ${theme.palette.primary.dark}`,
            marginTop: -8,
            marginLeft: -12,
            '&:focus,&:hover,&$active': {
                boxShadow: 'inherit',
            },
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
            borderRadius: 4,
        },
        rail: {
            height: 8,
            borderRadius: 4,
        },
    }
})(Slider);



export const AdjustSalarySlider = withStyles(theme => {

    const gradient = `linear-gradient(to right, ${theme.palette.tertiary.main}, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`
    //'linear-gradient(to right, red , yellow)',

    return {
        root: {
            color: theme.palette.primary.main,
            height: 10,
        },
        thumb: {
            height: 30,
            width: 30,
            backgroundColor: theme.palette.grey[800],
            border: `2px solid ${theme.palette.background.default}`,
            marginTop: -8,
            marginLeft: -12,
            '&:focus,&:hover,&$active': {
                boxShadow: 'inherit',
            },
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
            top: 60,
            color: theme.palette.secondary.main,
            // '& span': {
            //     transform: 'rotate(-225deg)',
            //     '& span': {
            //         color: "red",
            //         transform: 'rotate(225deg);',
            //     }
            // }

        },

        track: {
            height: 15,
            borderRadius: 7,
        },
        rail: {
            height: 15,
            borderRadius: 7,
            backgroundImage: gradient,
            opacity: 1,
        },
        mark: {
            //backgroundColor: theme.palette.grey[300],
            backgroundColor: theme.palette.background.default,
            //opacity:0.75,
            height: 18,
            width: 0.25,
            marginTop: -3,
        },
        markActive: {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    }
})(Slider);



const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const TaxChartSlider = withStyles(theme => {
    return {
        root: {
            color: theme.palette.primary.main,
            height: 2,
            padding: '15px 0',
        },
        thumb: {
            height: 28,
            width: 28,
            backgroundColor: '#fff',
            boxShadow: iOSBoxShadow,
            marginTop: -14,
            marginLeft: -14,
            '&:focus, &:hover, &$active': {
                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    boxShadow: iOSBoxShadow,
                },
            },
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 11px)',
            bottom: 0,
            paddingTop: 70,
            '& *': {
                background: 'transparent',
                color: theme.palette.primary.contrastText,
            },
        },
        track: {
            height: 2,
        },
        rail: {
            height: 2,
            opacity: 0.5,
            backgroundColor: theme.palette.primary.contrastText,
        },
    }
})(Slider);


export function SliderThumbComponent(props) {
    const style = {
        // display: inline-block !important;
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
    };

    return (
        <span {...props}>
            <span style={style} />
            <span style={style} />
            <span style={style} />
        </span>
    );
}





export const SliderTooltip = withStyles(theme => ({
    tooltip: {
        // backgroundColor: theme.palette.grey[300],
        backgroundColor: 'rgba(0,0,0,0)',
        color: theme.palette.primary.contrastText,
        marginTop: 32,
        maxWidth: 220,
        // fontSize: theme.typography.pxToRem(12),
        // border: '1px solid #dadde9',
    },
    tooltipPlacementTop: {
        marginBottom: 0,
    },
    popper: {
        zIndex: 0
    }
}))(Tooltip);



export const StyledTableRow = withStyles(theme => {

    const bg = fade(theme.palette.background.overlay, 0.4);
    const bg_alt = fade(theme.palette.background.overlay, 0.2);
    return {
        root: {
            borderBottom: 0,
            backgroundColor: bg,

            '&:nth-of-type(odd)': {
                backgroundColor: bg_alt,
            },
            ' & td': {
                fontSize: 14,
                borderBottom: 0,
            }
        },
    }
})(TableRow);


export const StyledLink = withStyles(theme => {
    return {
        root: {
            color: theme.palette.text.link,
            fontWeight: 'bold',
            '& a': {
                // color:"#ffffff",
            }
        },
    }
})(Link);



export const ButtonLink = withStyles(theme => {

    return {
        root: {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',

            background: theme.palette.background.paper,
            padding: '6px 12px',
            fontSize: 12,
            boxShadow: '0px 1px 5px -2px rgba(0,0,0,0.75)',
            display:`block`,
            cursor: `pointer`,
            border: `solid ${theme.palette.background.overlay} 0px`,
            borderRadius: 4,
            '&:hover': {
                backgroundColor: theme.palette.background.overlay,
                boxShadow: 'none',
                textDecoration:'none',
            },
            '&:active': {
                boxShadow: 'none',
            },
            '&:focus': {
            },

        },
    }
})(Link);
