import React, { useContext } from 'react'
import {
    Grid,
    Typography,
    Collapse,
    makeStyles,
    useTheme,
    useMediaQuery,
    fade,
    Box,
} from '@material-ui/core';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PriceFormat, PriceIntegerFormat } from '../../common/customtFormatters';
import { CustomButton } from '../../common/CustomComponents';
import { DistributionContext } from './Distribution';
import { useLayout, EXPAND_DISTRIBUTION, UPDATE_DISTRIBUTION } from '../../../state/layoutState';
import { taxDistribution } from '../../../core/taxDistribution';
import { colorSequence } from './DistributionChart';

const useStyles = makeStyles(() => {
    return {
        mainNavBar: {
            cursor: "pointer",
        },
        navMarker: {
            borderRadius: 10,
            width: 20,
            height: 20,
        },

        subNavBar: {
            marginTop: 1, marginBottom: 10, marginLeft: 15, width: 4, borderRadius: 2.4, zIndex: 1
        },
        subNavInfo: {
            marginLeft: -20
        },
        fadeText: {
            opacity: 0.7
        },
    }
});


export const DistributionTable = () => {
    const theme = useTheme();
    const classes = useStyles();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const [{ expandDistribution }, dispatchLayout] = useLayout();
    const expanded = expandDistribution && !xs && !sm;

    const handleToggleExpand = () => {
        return (
            dispatchLayout({
                type: EXPAND_DISTRIBUTION,
                expanded: !expandDistribution,
            })
        )
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >

            {expanded ? (
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end" style={{ paddingBottom: 5, paddingRight: 10 }}>
                    <Box display="flex" justifyContent="flex-end" style={{ width: 120 }}>
                        <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 14, textAlign: "right" }}> Your Contribution </Typography>
                    </Box>

                    <Box display="flex" justifyContent="flex-end" style={{ width: 80 }}>
                        <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 14, textAlign: "right" }}> Total </Typography>
                    </Box>

                    <Box display="flex" justifyContent="flex-end" style={{ width: 70 }}>
                        <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 14, textAlign: "right" }}> Trend </Typography>
                    </Box>
                </Box>
            ) : (

                    <Grid container style={{ paddingBottom: 5, paddingRight: 10 }}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                                <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 14 }}> Your Contribution </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )}

            <DistributionTableComponent />
            <Box display='flex' flexDirection="row" justifyContent="space-between">
                {!xs && !sm && (
                    <CustomButton variant="contained" onClick={handleToggleExpand} >
                        {expandDistribution ? 'Show Less' : 'Show More'}
                    </CustomButton>
                )
                }
            </Box>
        </Box>
    )
}


const DistributionTableComponent = () => {
    const { tax } = useContext(DistributionContext);
    const theme = useTheme();
    const classes = useStyles();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const [{ expandDistribution, distributionTable }, dispatchLayout] = useLayout();
    const expanded = expandDistribution && !xs && !sm;

    const toggleCollapsed = (label) => {
        const collapsed = { ...distributionTable };
        collapsed[label] = !collapsed[label];
        dispatchLayout({
            type: UPDATE_DISTRIBUTION,
            data: collapsed,
        })
    }
    // const bgColor = fade(theme.palette.grey[300], 1)
    const bgColor = fade(theme.palette.background.overlay, 1)

    return (
        <div style={{ paddingBottom: 5 }}>
            {taxDistribution.functions.map((fn, index) => {
                const fractionOfAllocation = fn.allocation / taxDistribution.total;
                const allocation = tax * fractionOfAllocation;
                const change = (fn.change - fn.allocation) / fn.allocation;
                const projection = (fn.projection - fn.allocation) / fn.allocation;

                let value = fn.allocation;
                let valueAbbrev = "";
                if (expanded) {
                    if (Number(value) >= 100000) {
                        valueAbbrev = "B";
                        value = Number(fn.allocation) / 1000;
                    } else {
                        valueAbbrev = "M";
                    }
                }

                const hasSubFunctions = fn.subFunctions !== undefined
                const c = colorSequence[index % colorSequence.length].split(".");
                const color = theme[c[0]][c[1]][c[2]];
                const isOpen = distributionTable[fn.label];

                return (
                    <React.Fragment key={fn.label} >
                        <Box
                            display="flex"
                            flexGrow={1}
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.mainNavBar}
                            style={{ backgroundColor: bgColor, marginBottom: 5, padding: 10, position: "relative" }}
                            onClick={(hasSubFunctions ? (() => toggleCollapsed(fn.label)) : () => { })}>
                            <Box
                                display="flex"
                                flexDirection="row"
                            >
                                <Box className={classes.navMarker} style={{ backgroundColor: color, position: "absolute", top: 10, left: 8 }} />
                                <Box style={{ paddingLeft: 20 }}>
                                    {hasSubFunctions ? (
                                        (isOpen ? < ExpandLessIcon /> : < ExpandMoreIcon />)
                                    ) : (
                                            <Box display="flex" style={{ width: 20 }} />
                                        )
                                    }
                                </Box>
                                <Box style={{ paddingRight: 10 }}>
                                    <Typography variant="h5" style={{ fontSize: 15, pointerEvents: "none" }}>
                                        {fn.label}
                                    </Typography>
                                </Box>

                            </Box>
                            {expanded ? (
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                >
                                    <Box display="flex" justifyContent="flex-end" style={{ width: 90 }}>
                                        <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 15 }}>
                                            {PriceFormat({ value: allocation, displayType: "text" })}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" justifyContent="flex-end" style={{ width: 90 }}>
                                        <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 15 }}>
                                            {PriceIntegerFormat({ value: value, displayType: "text" })}{valueAbbrev}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" justifyContent="flex-end" style={{ width: 60 }}>

                                        {renderChange(change, projection, classes)}
                                    </Box>

                                </Box>
                            ) : (
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end" style={{ width: 100 }}>
                                        <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 15 }}>
                                            {PriceFormat({ value: allocation, displayType: "text" })}
                                        </Typography>
                                    </Box>

                                )}
                        </Box>
                        <Collapsable data={hasSubFunctions ? fn : null} depth={1} toggleCollapsed={toggleCollapsed} grossTax={tax} color={color} />
                    </React.Fragment>
                )

            })
            }
        </div >
    )
}


const Collapsable = ({ data, depth, grossTax, toggleCollapsed, color }) => {
    const theme = useTheme();
    const classes = useStyles();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const [{ expandDistribution, distributionTable },] = useLayout();
    const expanded = expandDistribution && !xs && !sm;
    const childDepth = depth + 1;

    if (!data || data === undefined) return false;
    if (data.subFunctions == null) return false;
    const isComponentOpen = distributionTable[data.label] ? true : false;

    return (
        <Collapse in={isComponentOpen} unmountOnExit>
            <Box display="flex" flexDirection="row">
                <Box className={classes.subNavBar} flexShrink={0} style={{ backgroundColor: color }} />
                <Box display="flex" flexDirection="column" flexGrow={100} style={{ marginLeft: -20 }}>
                    {data.subFunctions.map(fn => {
                        const fractionOfAllocation = fn.allocation / taxDistribution.total;
                        const allocation = grossTax * fractionOfAllocation;
                        const change = (fn.change - fn.allocation) / fn.allocation;
                        const projection = (fn.projection - fn.allocation) / fn.allocation;
                        const hasSubFunctions = fn.subFunctions !== undefined
                        const bgColor = fade(theme.palette.grey['300'], (hasSubFunctions ? 0.5 : 0.0))

                        const isOpen = distributionTable[fn.label];

                        return (
                            <React.Fragment key={fn.label} >
                                <Box
                                    display="flex"
                                    flexGrow={1}
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{ backgroundColor: bgColor, marginBottom: xs ? 2 : 5, padding: 5, marginLeft: (20 * (depth)), position: "relative" }}
                                    onClick={(hasSubFunctions ? (() => toggleCollapsed(fn.label)) : () => { })}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        style={{ marginLeft: (-20 * (depth)) }}
                                    >
                                        <Box className={classes.navMarker} style={{ paddingLeft: 20 }} />
                                        {hasSubFunctions ? (
                                            (isOpen ? < ExpandLessIcon /> : < ExpandMoreIcon />)
                                        ) : (
                                                <Box display="flex" style={{ paddingLeft: 20 }} />
                                            )
                                        }
                                        <Box display="flex" flexShrink={1} style={{}}>
                                            <Typography variant="body1" style={{ fontSize: xs ? 12 : 14, letterSpacing: -0.25, lineHeight: "16px" }}>
                                                {fn.label}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    {expanded ? (
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                        >
                                            <Box display="flex" justifyContent="flex-end" style={{ width: 90 }}>
                                                <Typography className={classes.fadeText} variant="body1" style={{ fontSize: 12 }}>
                                                    {PriceFormat({ value: allocation, displayType: "text" })}
                                                </Typography>
                                            </Box>

                                            <Box display="flex" justifyContent="flex-end" style={{ width: 90 }}>
                                                <Typography className={classes.fadeText} variant="body1" style={{ fontSize: 12 }}>
                                                    {PriceIntegerFormat({ value: fn.allocation, displayType: "text" })}M
                                </Typography>
                                            </Box>

                                            <Box display="flex" justifyContent="flex-end" style={{ width: 60 }}>

                                                {renderChange(change, projection, classes)}
                                            </Box>

                                        </Box>
                                    ) : (
                                            <Box display="flex" flexDirection="row" justifyContent="flex-end" style={{ width: 100 }}>
                                                <Typography className={classes.fadeText} variant="body1" style={{ fontSize: 12 }}>
                                                    {PriceFormat({ value: allocation, displayType: "text" })}
                                                </Typography>
                                            </Box>

                                        )}
                                </Box>
                                <Collapsable data={hasSubFunctions ? fn : null} depth={childDepth} toggleCollapsed={toggleCollapsed} grossTax={grossTax} />
                            </React.Fragment>
                        )
                    })}
                </Box>
            </Box>
        </Collapse>
    )
}


const renderChange = (change, _, classes) => {

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center">
                {/* {changeIcon} */}
                <Typography className={classes.fadeText} variant="body2" style={{ paddingLeft: 5, fontSize: 10, color: (change > 0 ? "green" : "red") }}> {`${Math.round(change * 100) / 100}%`} </Typography>
                {/* <Box style={{width:10}}/>
                {projectionIcon}
                <Typography className={classes.fadeText} variant="body2" style={{ fontSize: 10, color: (projection > 0 ? "green" : "red") }}> {`${Math.round(projection * 100) / 100}% `} </Typography> */}
            </Box>
        </>
    )

}