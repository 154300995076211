import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useTheme } from '@material-ui/core/styles';

import { CalculatorProvider, readData } from './state/calculatorState';
import { ChangeThemeProvider, readTheme } from './state/themeState';
import { LayoutProvider, readLayout } from './state/layoutState';

import CalculatorPage from './pages/CalculatorPage';
import TaxInfoPage from './pages/TaxInfoPage';
import DistributionPage from './pages/DistributionPage';
import StudentLoansPage from './pages/StudentLoans';
import NavBar from './components/nav/Navbar';

import './styles/App.css';
import { CALCULATOR_LINK, TAX_INFO_LINK, STUDENT_LOAN_LINK, PRIVACY_POLICY_LINK, ACCEPTABLE_USE_LINK, COOKIES_LINK, TAX_DISTRIBUTION_LINK } from './constants';
import PrivacyPolicyPage from './pages/privacyPolicyPage';
import AcceptableUsePage from './pages/AcceptableUsePage';
import CookiesPage from './pages/CookiesPage';

const App = () => {

  const theme = useTheme();

  const style = {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  };


  return (
    <ChangeThemeProvider initialState={readTheme()} >
      <CalculatorProvider initialState={readData()}>
        <LayoutProvider initialState={readLayout()}>
          <CssBaseline />
          <NavBar />
          <Switch>
            <Route exact path={CALCULATOR_LINK} render={() => {
              return <CalculatorPage style={style} />
            }}
            />
            <Route exact path={TAX_INFO_LINK} render={() => {
              return <TaxInfoPage style={style} />
            }}
            />
            <Route exact path={STUDENT_LOAN_LINK} render={() => {
              return <StudentLoansPage style={style} />
            }}
            />

<Route exact path={TAX_DISTRIBUTION_LINK} render={() => {
              return <DistributionPage style={style} />
            }}
            />


             <Route exact path={PRIVACY_POLICY_LINK} render={() => {
              return <PrivacyPolicyPage style={style} />
            }}
            />

            <Route exact path={ACCEPTABLE_USE_LINK} render={() => {
              return <AcceptableUsePage style={style} />
            }}
            />

            <Route exact path={COOKIES_LINK} render={() => {
              return <CookiesPage style={style} />
            }}
            />
          </Switch>
        </LayoutProvider>
      </CalculatorProvider>
    </ChangeThemeProvider>
  )
}

export default (App);
