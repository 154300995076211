import React, { } from 'react'
import {
    Box,
    Grid,
    Card,
    Link,
    CardActions,
    CardMedia,
    CardActionArea,
    CardContent,
    Typography,
    makeStyles,
    useTheme,
    useMediaQuery,
    fade,
} from '@material-ui/core';

import { StyledLink } from '../../common/CustomComponents';
import ComponentWrapper from '../../../components/common/ComponentWrapper';
import {
    EMAIL_ADDRESS,
    STUDENT_LOAN_LINK,
    TAX_INFO_LINK,
    TAX_DISTRIBUTION_LINK,
    OLD_CALCULATOR_LINK,
    ATO_FORMULAS,
    ATO_WEEKLY_TABLES,
    ATO_FORTNIGHTLY_TABLES,
    ATO_MONTHLY_TABLES,
    EXTRA_PAYMENTS,
} from '../../../constants';

import history from '../../../history';
import { InIcon, OutIcon, EmailIcon } from '../../../utils/icons';

import headerImage2 from '../../../assets/articles/2.png';
import headerImage3 from '../../../assets/articles/3.png';
import headerImage4 from '../../../assets/articles/4.png';
import headerDistribution from '../../../assets/articles/distribution.png';
import headerContact from '../../../assets/articles/contact.png';
import headerHow from '../../../assets/articles/howitsmade.png';
import ClassicImage from '../../../assets/articles/classic.png';


const useStyles = makeStyles((theme) => {

    return {
        contentWrapper: {
            padding: 20,
        },

        media: {
            height: 140,
            position:"relative",
            backgroundColor:"transaprent",
        },

        media_bg: {
            height: 140,
            width:'100%',
            background: `linear-gradient(${theme.palette.tertiary.light}, ${theme.palette.primary.main})`,
            opacity:0.5,
            position:"absolute",
            top:0,
            left:0,
        },

        contentHeader: {
            height: 400,
        },

        underline: {
            height: 1,
            backgroundColor: theme.palette.primary.contrastText,
            opacity: 0.25,
            margin: '5px 0px 0 0',
        },


        infoCard: {
            position: 'relative',
            borderRadius: 0,
            height:'100%',
            //backgroundColor: theme.palette.background.default,
            //background: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxShadow: 'none',
            
          },


        card: {
            position: 'relative',
            borderRadius: 8,
            height:'100%',
            //backgroundColor: theme.palette.background.default,
            //background: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,
            backgroundColor: fade(theme.palette.background.default, 0.5),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },

          cardContent :{
            paddingBottom:0,
          }
    }
});


export const FAQ = () => {
    const classes = useStyles();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const paddingStyle = { paddingTop: 20, paddingBottom: 40, paddingLeft: small ? 10 : 15, paddingRight: small ? 10 : 20 };

    const info = [
        {
            image: headerImage2,
            title: 'Is this calculator accurate?',
            body() { return (
                <>This calculator conforms to the Australian Tax Office's Pay As You Go (PAYG) schedules for withholding tax on income. The latest PAYG rates are available from the ATO website in <StyledLink href={ATO_WEEKLY_TABLES}>weekly</StyledLink>, <StyledLink href={ATO_FORTNIGHTLY_TABLES}>fortnightly</StyledLink> and <StyledLink href={ATO_MONTHLY_TABLES}>monthly</StyledLink> tax tables.</>
            )},
            linkTitle: 'ATO Tax tables',
            link: ATO_WEEKLY_TABLES,
            linkBlankOption: true,
            linkIcon: <OutIcon />,
        },

        {
            image: headerImage4,
            title: 'Why do Annual amounts differ from Monthly and Weekly amounts',
            body() { return (
                <>This calculator honours the ATO tax withholding formulas. This method of calculating withholding PAYG income tax instalments can vary from the annual tax amounts. Differences will always be in favour of the ATO, however these will be refunded when the annual year tax return is processed.< br/>
                Annual calculations will also differ as tax offsets, benefits and deductions are only applied at the end of the year.</>
            )},
            linkTitle: 'ATO formulas',
            link: ATO_FORMULAS,
            linkBlankOption: true,
            linkIcon: <OutIcon />,
        },

        {
            image: headerImage4,
            title: 'What if there are 26 fortnights or 53 weeks in a year?',
            body() { return (
                <>If you are paid weekly or fortnightly, there will be some years where you have an additional payment. This is due to there being a little over 52 weeks in a year. You can check this by setting the date of the first pay day in the financial year in the options. If you input your salary as a weekly or fortnightly income, a little more tax will be withheld. Otherwise, your weekly or fortnightly payments will be divided by the exact number of payments in the year.</>
            )},
            linkTitle: 'Additional pay periods in a year',
            link: EXTRA_PAYMENTS,
            linkBlankOption: true,
            linkIcon: <OutIcon />,
        },

        // {
        //     image: headerImage4,
        //     title: 'Want to provide feedback?',
        //     body() { return (
        //         <>We are committed to being the best and most accurate income tax calculator in Australia. This site has been updated in order to create an interface with more features, and a more maintainable codebase. The redesign will also provide a foundation for new iOS and Android apps. Please let us know via our survey to help us create better software.</>
        //     )},
        //     linkTitle: 'Survey',
        //     link: SURVEY_LINK,
        //     linkBlankOption: true,
        //     linkIcon: <OutIcon />,
        // },
    ]
        const articles = [
            {
                image: ClassicImage,
                title: 'Prefer the old site?',
                body() { return (
                    <>This site has been updated in order to create an interface with more features, and a more maintainable codebase. The redesign will also provide a foundation for new iOS and Android apps.<br/>If you prefer the original site, that's fine, it's still available.</>
                )},
                linkTitle: 'Original Site',
                link: OLD_CALCULATOR_LINK,
                linkIcon: <InIcon />,
            },
        {
            image: headerHow,
            title: 'What are the tax rates?',
            body() { return (
                <>Take a deep dive into Australian income tax rates and see how they vary over time. Rates are also based on your residential or visa status.</>
            )},
            linkTitle: 'Tax rates and info',
            link: TAX_INFO_LINK,
            linkIcon: <InIcon />,
        },
        {
            image: headerImage3,
            title: 'When will I repay my student loan?',
            body() { return (
                <>Use this calculator to find out how long you will need to repay your loan. Also, get more information on different types of loans available to students and check the different repayment brackets.</>
            )},
            link: STUDENT_LOAN_LINK,
            linkTitle: 'Student Loan calculator',
            linkIcon: <InIcon />,
        },
        {
            image: headerDistribution,
            title: 'How is your tax spent?',
            body() { return (
                <>See how tax expenditure is distributed and get an understanding of how your tax is spent.</>
            )},
            link: TAX_DISTRIBUTION_LINK,
            linkTitle: 'Tax distribution',
            linkIcon: <InIcon />,
        },
        {
            image: headerContact,
            title: 'How can I contact the developer of paycalculator?',
            body() { return (
                <>If you have a question about the calculations, if you have found a bug or if you have a useful suggestion please get in touch. Use the link below to send me an email.</>
            )},
            link: EMAIL_ADDRESS,
            linkTitle: 'Contact us',
            linkBlankOption: true,
            linkIcon: <EmailIcon />,
        },





    ]

    return (
        <ComponentWrapper title='More Information' >
            <div className={classes.contentWrapper} style={paddingStyle} >

            <Grid container spacing={4} style={{paddingBottom:20}}>
                    {info.map(data => {
                        return (
                            <Grid item xs={12}  xl={6} key={data.title}>
                                <InfoComponent {...data} />
                            </Grid>
                        )
                    })}

                </Grid>


                <Grid container spacing={4}>
                    {articles.map(data => {
                        return (
                            <Grid item sm={12} md={6} lg={4} xl={3} key={data.title}>
                                <ArticleComponent {...data} />
                            </Grid>
                        )
                    })}

                </Grid>
            </div>
        </ComponentWrapper>
    )
}

const InfoComponent = (props) => {
    const classes = useStyles();


    return (
    <Card className={classes.infoCard} >
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h4" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="body1">
                {props.body()}
                </Typography>
            </CardContent>
        <CardActions>
            <Link href={props.link} target={props.linkBlankOption ? "_blank" : "_self"}>
            <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3"> {props.linkIcon}</Typography>
                <div style = {{ width:5}}/>
                <Typography variant="h3" style={{paddingBottom:5}}> {props.linkTitle.toUpperCase()}</Typography>
                </Box>
                </Link>
        </CardActions>
    </Card>
    )
}


const ArticleComponent = (props) => {
    const classes = useStyles();
    

    const redirect = (url, blank) => {

        if (blank) {
            window.open(url, "_blank");
        } else {
            history.push(url);
            window.location.replace(url);
        }
    }


    return (
    <Card className={classes.card} onClick={() => redirect(props.link, props.linkBlankOption )}>
        <CardActionArea>
            <Box
                className={classes.media_bg}
            />
            <CardMedia
                className={classes.media}
                image={props.image}
                title={props.title}
            />
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="body1">
                {props.body()}
                </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions>
        <Link href={props.link} target={props.linkBlankOption ? "_blank" : "_self"}>
            <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h3"> {props.linkIcon}</Typography>
                <div style = {{ width:5}}/>
                <Typography variant="h3" style={{paddingBottom:5}}> {props.linkTitle.toUpperCase()}</Typography>
                </Box>
                </Link>
        </CardActions>
    </Card>
    )
}

