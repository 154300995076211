export const taxDistribution = {
        year: 2020,
        total: 520046,// 500872 + 4749 + 14425,
        change: 509475, //487343 + 6826 + 15306,
        projection: 591029,// 559874 + 10780 + 20375,
        functions: [
            {
                label: "General public services", allocation: 23614, change: 26048, projection: 23802,
                subFunctions: [
                    { label: "Legislative and executive affairs", allocation: 1196, change: 1533, projection: 1199 },
                    { label: "Financial and fiscal affairs", allocation: 7344, change: 6922, projection: 7495 },
                    {
                        label: "Foreign affairs and economic aid", allocation: 6253, change: 5750, projection: 5918,
                        subFunctions: [
                            { label: "Foreign aid", allocation: 3947, change: 3517, projection: 3657 },
                            { label: "Diplomacy", allocation: 1050, change: 1019, projection: 959 },
                            { label: "Payments to international organisations", allocation: 503, change: 445, projection: 580 },
                            { label: "Passport services", allocation: 238, change: 242, projection: 246 },
                            { label: "International police assistance", allocation: 196, change: 204, projection: 143 },
                            { label: "Intl. agriculture research & dev", allocation: 121, change: 128, projection: 117 },
                            { label: "Consular services", allocation: 104, change: 97, projection: 104 },
                            { label: "Finance & insurance for exporters & investors", allocation: 7, change: 11, projection: 5 },
                            { label: "Other", allocation: 88, change: 87, projection: 107 },
                        ]
                    },
                    {
                        label: "General research", allocation: 3159, change: 3012, projection: 3476,
                        subFunctions: [
                            { label: "Research - science services & innovation fund", allocation: 1142, change: 1153, projection: 1214 },
                            { label: "Discovery - research and research training", allocation: 508, change: 495, projection: 538 },
                            { label: "Science & technology solutions", allocation: 392, change: 354, projection: 399 },
                            { label: "Linkage - cross sector research partnerships", allocation: 286, change: 272, projection: 303 },
                            { label: "Supporting science and commercialisation", allocation: 283, change: 234, projection: 282 },
                            { label: "Research capacity", allocation: 191, change: 168, projection: 401},
                            { label: "Other", allocation: 357, change: 337, projection: 338 },
                        ]
                    },
                    { label: "General services", allocation: 651, change: 665, projection: 581 },
                    { label: "Government superannuation benefits", allocation: 5011, change: 8166, projection: 5134 },
                ]
            },
            {
                label: "Defence", allocation: 32243, change: 31014, projection: 37629,
            },
            {
                label: "Public order and safety", allocation: 5919, change: 5760, projection: 5613,
                subFunctions: [
                    { label: "Courts and legal services", allocation: 1396, change: 1266, projection: 1316 },
                    { label: "Policing and law enforcement", allocation: 3060, change: 3094, projection: 2993 },
                    { label: "Border protection", allocation: 1463, change: 1399, projection: 1304 },
                ]
            },
            {
                label: "Education", allocation: 36350, change: 34773, projection: 40893,
                subFunctions: [
                    { label: "Higher education", allocation: 9856, change: 9704, projection: 10372 },
                    { label: "Vocational and other education", allocation: 1699, change: 1675, projection: 1620 },
                    { label: "Non-government schools", allocation: 12554, change: 19641, projection: 25365 },
                    { label: "Government schools", allocation: 8326, change: 7684, projection: 10385 },
                    { label: "School education - specific funding", allocation: 742, change: 691, projection: 151 },
                    { label: "Student assistance", allocation: 2753, change: 2675, projection: 3011 },
                    { label: "General administration", allocation: 420, change: 388, projection: 374 },
                ]
            },
            {
                label: "Health", allocation: 81777, change: 80569, projection: 89544,
                subFunctions: [
                    {
                        label: "Medical services and benefits", allocation: 33687, change: 32197, projection: 38814,
                        subFunctions: [
                            { label: "Medical benefits", allocation: 25526, change: 24228, projection: 30085 },
                            { label: "Private health insurance", allocation: 6574, change: 6393, projection: 7103 },
                            { label: "General medical consultations and services", allocation: 740, change: 764, projection: 730 },
                            { label: "Dental services", allocation: 346, change: 346, projection: 364 },
                            { label: "Other", allocation: 500, change: 468, projection: 532 },
                        ]
                    },
                    {
                        label: "Pharmaceutical benefits and services", allocation: 12688, change: 13457, projection: 11085,
                        subFunctions: [
                            { label: "Pharmaceutical benefits, services and supply ", allocation: 11971, change: 12731, projection: 10384 },
                            { label: "Immunisation", allocation: 395, change: 393, projection: 391 },
                            { label: "Veterans' pharmaceutical benefits", allocation: 322, change: 333, projection: 309 },
                        ]
                    },
                    { label: "Assistance to the States for public hospitals", allocation: 22535, change: 21708, projection: 26183 },
                    { label: "Hospital services", allocation: 1298, change: 1385, projection: 1149 },
                    { label: "Health services", allocation: 7371, change: 7505, projection: 8021 },
                    { label: "General administration", allocation: 3236, change: 3395, projection: 3228 },
                    { label: "Aboriginal and Torres Strait Islander health", allocation: 962, change: 922, projection: 1065 },
                ]
            },
            {
                label: "Social security and welfare", allocation: 180125, change: 172749, projection: 200217,
                subFunctions: [
                    {
                        label: "Assistance to the aged", allocation: 70151, change: 67449, projection: 80215,
                        subFunctions: [
                            { label: "Income Support for Seniors", allocation: 48301, change: 46741, projection: 54768 },
                            { label: "Aged Care Services", allocation: 20027, change: 18764, projection: 23903 },
                            { label: "Veterans' Community Care and Support", allocation: 1130, change: 1202, projection: 1025 },
                            { label: "Access and information", allocation: 245, change: 234, projection: 228 },
                            { label: "Mature Age Income Support", allocation: 131, change: 171, projection: 0 },
                            { label: "Aged Care Quality", allocation: 221, change: 242, projection: 210 },
                            { label: "Allowances, concessions & services for seniors", allocation: 83, change: 92, projection: 61 },
                            { label: "National Partnership Payments - Assistance to the Aged", allocation: 13, change: 3, projection: 21 },
                        ]
                    },
                    { label: "Assistance to veterans and dependants", allocation: 6707, change: 6717, projection: 6309 },
                    { label: "Assistance to people with disabilities", allocation: 47005, change: 44079, projection: 55499,
                        subFunctions:
                            [
                                { label: "Income Support for People with Disability", allocation: 17057, change: 16699, projection: 17889 },
                                { label: "National Disability Insurance Scheme", allocation: 17524, change: 12989, projection: 24806 },
                                { label: "Income Support for Carers", allocation: 9207, change: 8770, projection: 10552 },
                                { label: "Assistance to the States for Disability Services", allocation: 173, change: 958, projection: 0 },
                                { label: "Disability and Carers", allocation: 1084, change: 1012, projection: 1112 },
                                { label: "National Disability Insurance Scheme Transition Programme", allocation: 219, change: 500, projection: 37 },
                                { label: "National Partnership Payments - Assistance to People with Disabilities", allocation: 1742, change: 3151, projection: 1104 },
                            ]
                    },

                    { label: "Assistance to families with children", allocation: 37412, change: 35754, projection: 39471,
                        subFunctions: [
                            { label: "Family tax benefit", allocation: 18013, change: 17630, projection: 1833 },
                            { label: "Child Care Subsidy", allocation: 8267, change: 7725, projection: 9646 },
                            { label: "Parents income support", allocation: 5190, change: 5171, projection: 5487 },
                            { label: "Paid Parental Leave", allocation: 2326, change: 2250, projection: 2551 },
                            { label: "Child support", allocation: 2002, change: 1983, projection: 2118 },
                            { label: "Support for the child care system", allocation: 342, change: 329, projection: 350 },
                            { label: "Families and Children", allocation: 979, change: 361, projection: 679 },
                            { label: "Family relationship services", allocation: 182, change: 168, projection: 188 },
                            { label: "Child Payments", allocation: 96, change: 103, projection: 103 },
                            { label: "Other", allocation: 14, change: 15, projection: 15 },
                        ]
                    },
                    { label: "Assistance to the unemployed and the sick", allocation: 10834, change: 0, projection: 0 },
                    { label: "Other welfare programs", allocation: 1729, change: 0, projection: 0 },
                    { label: "Assistance for Indigenous Australians nec", allocation: 2269, change: 0, projection: 0 },
                    { label: "General administration", allocation: 4016, change: 0, projection: 0 },
                ]
            },
            {
                label: "Housing and community amenities", allocation: 5907, change: 5278, projection: 4767,
                subFunctions: [
                    { label: "Housing", allocation: 3141, change: 3051, projection: 3096 },
                    { label: "Urban and regional development", allocation: 1773, change: 1288, projection: 570 },
                    { label: "Environment protection", allocation: 992, change: 939, projection: 1101 },
                ]
            },
            {
                label: "Recreation and culture", allocation: 3849, change: 3988, projection: 3696,
                subFunctions: [
                    {
                        label: "Broadcasting", allocation: 1476, change: 1490, projection: 1500,
                        subFunction: [
                            { label: "ABC general operational activities", allocation: 900, change: 916, projection: 902 },
                            { label: "SBS general operational activities", allocation: 319, change: 319, projection: 326 },
                            { label: "ABC transmission and distribution services", allocation: 184, change: 181, projection: 194 },
                            { label: "SBS transmission and distribution services", allocation: 73, change: 73, projection: 77 },
                        ]
                    },
                    { label: "Arts and cultural heritage", allocation: 1437, change: 1455, projection: 1380 },
                    { label: "Sport and recreation", allocation: 489, change: 584, projection: 329 },
                    { label: "National estate and parks", allocation: 448, change: 459, projection: 487 },
                ]
            },
            {
                label: "Fuel and energy", allocation: 8171, change: 7956, projection: 9285,
                subFunctions: [
                    { label: "Fuel Tax Credits Scheme", allocation: 7504, change: 7168, projection: 8966 },
                    { label: "Resources and Energy", allocation: 126, change: 141, projection: 70 },
                    { label: "Renewable Energy", allocation: 352, change: 405, projection: 102 },
                    { label: "Other", allocation: 189, change: 242, projection: 147 },
                ]
            },
            {
                label: "Agriculture, forestry and fishing", allocation: 2871, change: 3149, projection: 3014,
                subFunctions: [
                    { label: "Wool industry", allocation: 70, change: 87, projection: 77 },
                    { label: "Grains industry", allocation: 202, change: 187, projection: 210 },
                    { label: "Dairy industry", allocation: 48, change: 49, projection: 49 },
                    { label: "Cattle, sheep and pig industry", allocation: 242, change: 244, projection: 258 },
                    { label: "Fishing, horticulture and other agriculture", allocation: 381, change: 404, projection: 350 },
                    { label: "General assistance not allocated to specific industries", allocation: 34, change: 55, projection: 31 },
                    { label: "Rural assistance", allocation: 299, change: 336, projection: 249 },
                    {
                        label: "Natural resources development", allocation: 776, change: 973, projection: 955,
                        subFunctions: [
                            { label: "Water reform", allocation: 525, change: 699, projection: 750 },
                            { label: "Sustainable management - natural resources", allocation: 9, change: 10, projection: 16 },
                            { label: "Other", allocation: 243, change: 263, projection: 189 },
                        ]
                    },
                    { label: "General administration", allocation: 823, change: 815, projection: 835 },
                ]
            },
            {
                label: "Mining, manufacturing and construction", allocation: 3422, change: 2592, projection: 2926, subFunctions:
                    [
                        { label: "Research and Development Tax Incentive", allocation: 2237, change: 1967, projection: 2410 },
                        { label: "Growing Business Investment", allocation: 279, change: 317, projection: 172 },
                        { label: "Northern Australia Infrastructure Facility", allocation: 639, change: 45, projection: 116 },
                        { label: "Other", allocation: 267, change: 262, projection: 227 },
                    ]
            },
            {
                label: "Transport and communication", allocation: 9038, change: 8105, projection: 11149,
                subFunctions: [
                    { label: "Communication", allocation: 1466, change: 964, projection: 1341 },
                    { label: "Rail transport", allocation: 1001, change: 1134, projection: 1818 },
                    { label: "Air transport", allocation: 350, change: 355, projection: 277 },
                    { label: "Road transport", allocation: 5587, change: 5016, projection: 7033 },
                    { label: "Sea transport", allocation: 444, change: 438, projection: 475 },
                    { label: "Other transport and communication", allocation: 190, change: 198, projection: 205 }
                ]
            },
            {
                label: "Other economic affairs", allocation: 9297, change: 10281, projection: 8996,
                subFunctions: [
                    { label: "Tourism and area promotion", allocation: 169, change: 163, projection: 185 },
                    { label: "Vocational and industry training", allocation: 1173, change: 1172, projection: 1134 },
                    { label: "Labour market assistance to job seekers & industry", allocation: 1831, change: 2005, projection: 1818 },
                    { label: "Industrial relations", allocation: 703, change: 687, projection: 715 },
                    {
                        label: "Immigration", allocation: 2854, change: 3761, projection: 2542,
                        subFunction: [
                            { label: "Management of unlawful non-citizens", allocation: 1624, change: 2458, projection: 1444 },
                            { label: "Citizenship, visas & migration", allocation: 782, change: 841, projection: 695 },
                            { label: "Regional co-operation, refugee & humanitarian assistance", allocation: 389, change: 463, projection: 402 },
                        ]
                    },
                    {
                        label: "Other economic affairs", allocation: 2567, change: 2492, projection: 2394,
                        subFunction: [
                            { label: "Promotion of Australia's export & other international economic interests", allocation: 389, change: 360, projection: 360 },
                            { label: "Department of Industry, Innovation and Science", allocation: 487, change: 507, projection: 465 },
                            { label: "Australian Securities & Investments Commission", allocation: 512, change: 477, projection: 537 },
                            { label: "Bureau of Meteorology", allocation: 392, change: 381, projection: 348 },
                            { label: "IP Australia ", allocation: 206, change: 201, projection: 226 },
                            { label: "Australian Competition & Consumer Commission", allocation: 168, change: 166, projection: 135 },
                            { label: "Australian Prudential Regulation Authority", allocation: 184, change: 156, projection: 201 },
                            { label: "National Partnership Payments - Competition & Productivity Enhancing Reform", allocation: 107, change: 129, projection: 0 },
                            { label: "Other", allocation: 122, change: 115, projection: 120 },
                        ]
                    },
                ]
            },
            {
                label: "Other purposes", allocation: 98287, change: 95081, projection: 118343,
                subFunctions: [
                    { label: "Public debt interest", allocation: 17037, change: 17154, projection: 15747 },
                    { label: "Nominal superannuation interest", allocation: 11127, change: 9447, projection: 12122 },
                    { label: "General revenue assistance - States & Territories", allocation: 69053, change: 67134, projection: 78608 },
                    { label: "Local government assistance", allocation: 2564, change: 1275, projection: 2883 },
                    { label: "Natural disaster relief", allocation: 11, change: 775, projection: 0 },
                    { label: "Contingency reserve", allocation: -216, change: -1993, projection: 8982 },
                ]
            },



            {
                label: "Capital Investment", allocation: 4749, change: 6490, projection: 10780,
                subFunctions: [
                    { label: "General public services", allocation: -1, change: 113, projection: -189 },
                    { label: "Defence", allocation: 5202, change: 4727, projection: 11294 },
                    { label: "Public order and safety", allocation: 91, change: 190, projection: -293 },
                    { label: "Education", allocation: 16, change: 4, projection: -3 },
                    { label: "Health", allocation: 58, change: 62, projection: -27 },
                    { label: "Social security and welfare", allocation: -38, change: 114, projection: -104 },
                    { label: "Housing and community amenities", allocation: -12, change: 91, projection: -3 },
                    { label: "Recreation and culture", allocation: 138, change: 213, projection: 50 },
                    { label: "Fuel and energy", allocation: 1, change: 1, projection: -1 },
                    { label: "Agriculture, forestry and fishing", allocation: 161, change: 904, projection: 143 },
                    { label: "Mining, manufacturing and construction", allocation: 15, change: 14, projection: -3 },
                    { label: "Transport and communication", allocation: -849, change: 35, projection: -15 },
                    { label: "Other economic affairs", allocation: -41, change: 25, projection: -192 },
                    { label: "Other purposes", allocation: 9, change: -3, projection: 68 },
                ]
            },


            {
                label: "Purchase of Assets", allocation: 14425, change: 15306, projection: 20375,
                subFunctions: [
                    { label: "General public services", allocation: 977, change: 1098, projection: 690 },
                    { label: "Defence", allocation: 10863, change: 10536, projection: 17914 },
                    { label: "Public order and safety", allocation: 594, change: 647, projection: 322 },
                    { label: "Education", allocation: 31, change: 43, projection: 26 },
                    { label: "Health", allocation: 134, change: 140, projection: 63 },
                    { label: "Social security and welfare", allocation: 373, change: 558, projection: 225 },
                    { label: "Housing and community amenities", allocation: 123, change: 104, projection: 95 },
                    { label: "Recreation and culture", allocation: 478, change: 549, projection: 403 },
                    { label: "Fuel and energy", allocation: 5, change: 6, projection: 5 },
                    { label: "Agriculture, forestry and fishing", allocation: 208, change: 952, projection: 190 },
                    { label: "Mining, manufacturing and construction", allocation: 24, change: 22, projection: 8 },
                    { label: "Transport and communication", allocation: 71, change: 97, projection: 59 },
                    { label: "Other economic affairs", allocation: 507, change: 580, projection: 306 },
                    { label: "Other purposes", allocation: 24, change: -3, projection: 68 },
                ]
            },

        ]
    };
    