import React, { useState } from 'react'
import {
    Grid,
    Box,
    useTheme,
    Typography,
} from '@material-ui/core';

import { taxData } from '../../core/calculatorData';
import { StudentLoanInputOptions } from './StudentLoanInputOptions'
import { StudentLoanChart } from './StudentLoanChart';
import { StudentLoanTable } from './StudentLoanTable';

export const StudentLoanContext = React.createContext();

export const StudentLoanComponent = () => {
    const theme = useTheme();

    const currentYearIndex = taxData.findIndex(t => t.current === "true");
    const currentYear = taxData[currentYearIndex].year;


    const dataCategories = taxData.map(obj => {
        // pick the available student loan data sets
        const categories = [];
        if (obj.help) categories.push(obj.help.label)
        if (obj.sfss) categories.push(obj.sfss.label)

        return { year: obj.year, categories };
    })

    const currentYearCategories = dataCategories.find(cat => cat.year === currentYear);
    const [year, setYear] = useState(currentYear);
    const [category, setCategory] = useState(currentYearCategories.categories[0]);


    const setCurrentYear = (year) => {
        setYear(year);
        // is the current Category valid?
        const currentYearCategories = dataCategories.find(cat => cat.year === year);
        if (!currentYearCategories.categories.find(c => c === category)) {
            // default back to first category
            setCategory(currentYearCategories.categories[0])
        }
    }

    return (
        <Grid container >
            <Grid item xs={12} >
                <StudentLoanContext.Provider value={{ year, setCurrentYear, dataCategories, category, setCategory }}>
                    <Box style={{ paddingTop: 0 }}>
                        <Box style={{ paddingBottom: 20 }} >
                            <StudentLoanInputOptions />
                        </Box>
                        <Box style={{ paddingBottom: 30 }} >
                            <StudentLoanChart />
                        </Box>
                        <Box style={{ paddingBottom: 20 }} >
                            <Typography variant="h3" style={{ color: theme.palette.primary.main }}>Repayment thresholds and rates</Typography>
                        </Box>
                        <Box style={{ paddingBottom: 5 }} >
                            <StudentLoanTable />
                        </Box>
                    </Box>
                </StudentLoanContext.Provider>
            </Grid>
        </Grid>
    )
}
