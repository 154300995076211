import React from 'react';
import { OLD_CALCULATOR_LINK, EMAIL_ADDRESS } from '../constants';



class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error:{} };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error:error.toString()};
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <p>{this.state.error}</p>
          {/* <p>{JSON.stringify(info)}</p> */}
          <p>Please <a href={EMAIL_ADDRESS}>report</a> this error</p>
          <p>Continue with the <a href={OLD_CALCULATOR_LINK}>Original website</a></p>
        </>
      );
    }

    return this.props.children;
  }
}


const logErrorToMyService = (error, info) => {
  console.log("logErrorToMyService ", error, info)
}

export default ErrorBoundary;