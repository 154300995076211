import { taxData } from './calculatorData'
import { payOptions, FREQUENCY_DAILY, FREQUENCY_HOURLY } from '../state/calculatorState'
import { calculateMedicareLevyAdjustment } from './MedicareLevyAdjustments';

import {
    roundToNearestCent,
    roundToNearestDollar,
    formatMoney,
} from '../utils/utils';

//-----------------------------------------------------
// Constants
//-----------------------------------------------------

const state = () => Calculator.state;
// const day2Year = 365;
let week2Year = 52;
// const week2YearExtra = 53;
let fortnight2Year = 26;
// const fortnight2YearExtra = 27;
const month2Year = 12;
const week2Month = week2Year / month2Year; //4.33
const week2Fortnight = 2;
// const ANNUALLY = 'annually';
const WEELKY = 'weekly';
const FORTNIGHTLY = 'fortnightly';
const MONTHLY = 'monthly';


//-----------------------------------------------------
// Tax Data
//-----------------------------------------------------

const TaxData = () => {
    return taxData[Calculator.state.yearIndex];
}



//-----------------------------------------------------
// Calculator
//-----------------------------------------------------
export const Calculator = {
    state: {},

    setState: (obj) => {
        Calculator.state = obj;
        return Calculator;
    },

    isDebug: false,


    ///////////////////////////////////////////
    // RESET
    ///////////////////////////////////////////
    reset: () => {
        zero(state().baseSalary);
        zero(state().deductions);
        zero(state().help);
        zero(state().sfss);
        zero(state().medicare);
        zero(state().medicareAdjustment);
        zero(state().offsets)
        zero(state().extraWithholdingTax);
        state().otherTaxesAndLevies = 0;
        state().lito = 0;
        state().lamito = 0;
        state().mito = 0;
        state().mawto = 0;
        state().sapto = 0;
        state().superannuationConcessional = 0;
        state().superannuationConcessionalTax = 0;
        state().superannationNonConcessional = 0;
        state().superannationExcess = 0;
        state().superannationExcessTax = 0;
        // not being used:

        return Calculator;
    },


    ///////////////////////////////////////////
    // Income
    ///////////////////////////////////////////
    getIncome: () => {

        const payCycle = payOptions[state().payOption].title.toLowerCase();


        // check if there are additional weekly or fortnightly payments
        if (payCycle === "fortnightly" && state().payments.f > 26) {
            fortnight2Year = state().payments.f;
        } else {
            fortnight2Year = 26;
        }

        if (payCycle === "weekly" && state().payments.w > 52) {
            week2Year = state().payments.w;
        } else {
            week2Year = 52;
        }




        // capture data in form field    
        switch (payCycle) {
            case "annually":
                state().baseSalary.a = getInputIncome();
                //derived
                state().baseSalary.m = state().baseSalary.a / month2Year;
                state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                state().baseSalary.w = state().baseSalary.a / week2Year;
                break;

            case "monthly":
                state().baseSalary.m = getInputIncome();
                //derived
                state().baseSalary.a = state().baseSalary.m * month2Year;
                state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                state().baseSalary.w = state().baseSalary.a / week2Year;
                break;
            case "fortnightly":

                state().baseSalary.f = getInputIncome();
                state().baseSalary.w = state().baseSalary.f / week2Fortnight;
                state().baseSalary.m = state().baseSalary.w * week2Month;
                //derived
                state().baseSalary.a = state().baseSalary.f * fortnight2Year;


                break;
            case "weekly":
                state().baseSalary.w = getInputIncome();
                state().baseSalary.f = state().baseSalary.w * week2Fortnight;
                state().baseSalary.m = state().baseSalary.w * week2Month;
                // derived
                state().baseSalary.a = state().baseSalary.w * week2Year;



                break;
            case "daily":
                //casualDaily
                if (state().casualDaily.frequency === FREQUENCY_DAILY[0]) {
                    // Days per week
                    state().baseSalary.w = getInputIncome() * state().casualDaily.days;
                    state().baseSalary.f = state().baseSalary.w * week2Fortnight;
                    state().baseSalary.m = state().baseSalary.w * week2Month;

                    state().baseSalary.a = state().baseSalary.w * week2Year;


                    // adjust annual amount
                    state().baseSalary.a = Number(state().casualDaily.annual) * state().baseSalary.w;
                }

                if (state().casualDaily.frequency === FREQUENCY_DAILY[1]) {
                    // Days per fortnight
                    state().baseSalary.f = getInputIncome() * state().casualDaily.days;
                    state().baseSalary.w = state().baseSalary.f / week2Fortnight;
                    state().baseSalary.m = state().baseSalary.w * week2Month;

                    state().baseSalary.a = state().baseSalary.f * fortnight2Year;


                    // adjust annual amount
                    state().baseSalary.a = Number(state().casualDaily.annual) * state().baseSalary.f;
                }

                if (state().casualDaily.frequency === FREQUENCY_DAILY[2]) {
                    // Days per month
                    state().baseSalary.m = getInputIncome() * state().casualDaily.days;
                    state().baseSalary.a = state().baseSalary.a * month2Year;
                    state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                    state().baseSalary.w = state().baseSalary.a / week2Year;

                    // adjust annual amount
                    state().baseSalary.a = Number(state().casualDaily.annual) * state().baseSalary.m;
                }

                if (state().casualDaily.frequency === FREQUENCY_DAILY[3]) {
                    // Days per year
                    state().baseSalary.a = getInputIncome() * state().casualDaily.days;
                    state().baseSalary.m = state().baseSalary.a / month2Year;
                    state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                    state().baseSalary.w = state().baseSalary.a / week2Year;
                }
                //


                // add overtime
                calculateOvertime();

                break;

            case "hourly":
                //casualHourly

                if (state().casualHourly.frequency === FREQUENCY_HOURLY[0]) {
                    // Hours per week
                    state().baseSalary.w = getInputIncome() * Number(state().casualHourly.hours);
                    state().baseSalary.f = state().baseSalary.w * week2Fortnight;
                    state().baseSalary.m = state().baseSalary.w * week2Month;
                    state().baseSalary.a = state().baseSalary.w * week2Year;

                    // adjust annual amount
                    state().baseSalary.a = Number(state().casualHourly.annual) * state().baseSalary.w;
                }

                if (state().casualHourly.frequency === FREQUENCY_HOURLY[1]) {
                    // Hours per fortnight
                    state().baseSalary.f = getInputIncome() * state().casualHourly.hours
                    state().baseSalary.w = state().baseSalary.f / week2Fortnight;
                    state().baseSalary.m = state().baseSalary.w * week2Month;

                    state().baseSalary.a = state().baseSalary.f * fortnight2Year;
                    // adjust annual amount
                    state().baseSalary.a = Number(state().casualHourly.annual) * state().baseSalary.f;
                }

                if (state().casualHourly.frequency === FREQUENCY_HOURLY[2]) {
                    // Hours per month
                    state().baseSalary.m = getInputIncome() * state().casualHourly.hours
                    state().baseSalary.a = state().baseSalary.a * month2Year;
                    state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                    state().baseSalary.w = state().baseSalary.a / week2Year;

                    // adjust annual amount
                    state().baseSalary.a = Number(state().casualHourly.annual) * state().baseSalary.m;
                }

                if (state().casualHourly.frequency === FREQUENCY_HOURLY[3]) {
                    // Days per year
                    state().baseSalary.a = getInputIncome() * state().casualHourly.hours
                    state().baseSalary.m = state().baseSalary.a / month2Year;
                    state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                    state().baseSalary.w = state().baseSalary.a / week2Year;
                }
                calculateOvertime();
                break;

            default:
                break;
        }

        if (Calculator.isDebug) console.log("Get Income -> Base Salary: ", state().baseSalary)
        return Calculator;

    },



    ///////////////////////////////////////////
    // Superannuation (Superannuation guarantee)
    ///////////////////////////////////////////
    calculateSuperannuationGuarantee: () => {

        // adjusted rate
        const options = {
            adjusted: state().adjustSuperannuation,
            rate: state().adjustSuperannuationRate ? Number(state().superannuationRate) : Number(TaxData().superannuation.rate),
        } // define superannaution calcualtion options



        if (state().includesSuperannuation) {
            // calculate the superannuation annually
            state().superannuationGuarantee = getSuperannuation(state().baseSalary.a, true, options);
            state().superannuation.a = state().superannuationGuarantee;

            // calcualte the individual rates ( as factors of annual values)
            state().superannuation.m = getSuperannuation(state().baseSalary.m * month2Year, true, options) / month2Year;
            state().superannuation.f = getSuperannuation(state().baseSalary.f * fortnight2Year, true, options) / fortnight2Year;
            state().superannuation.w = getSuperannuation(state().baseSalary.w * week2Year, true, options) / week2Year;

            // subtract assessable Income
            // state().income.a -= state().superannuation.a;
            // state().income.m -= state().superannuation.m;
            // state().income.f -= state().superannuation.f;
            // state().income.w -= state().superannuation.w;
            state().baseSalary.a -= state().superannuation.a;
            state().baseSalary.m -= state().superannuation.m;
            state().baseSalary.f -= state().superannuation.f;
            state().baseSalary.w -= state().superannuation.w;

        } else {
            state().superannuationGuarantee = getSuperannuation(state().baseSalary.a, false, options);
            state().superannuation.a = state().superannuationGuarantee;

            // calcualte the individual rates ( by bumping up to annual values)
            state().superannuation.m = getSuperannuation(state().baseSalary.m * month2Year, false, options) / month2Year;
            state().superannuation.f = getSuperannuation(state().baseSalary.f * fortnight2Year, false, options) / fortnight2Year;
            state().superannuation.w = getSuperannuation(state().baseSalary.w * week2Year, false, options) / week2Year;
        }

        return Calculator;
    },


    ///////////////////////////////////////////
    // Superannuation Salary sacrafice
    ///////////////////////////////////////////
    calculateSalarySacrafice: () => {
        //reset 
        // state().salaryScaracficeAmount = 0;
        let limit = Number.MAX_SAFE_INTEGER;
        if (TaxData().superannuation && TaxData().superannuation.concessionalCap !== undefined) {
            limit = TaxData().superannuation.concessionalCap - state().superannuation.a;
        } else {
            console.log("No 'superannuation.concessionalCap' data")
        }

        if (limit < 0) limit = 0;

        // inline check to limit sacrafice super to concessional limit
        const limitSalarySacrafice = false;
        if (!limitSalarySacrafice) {
            limit = Number.MAX_SAFE_INTEGER;
        }

        // ensure salary sacrafice values are within the income range
        if (state().adjustSalaryScaracfice) {

            if (state().salaryScaracficeAmount < 0) { state().salaryScaracficeAmount = 0 }
            switch (Number(state().salarySacraficeOption)) {
                case 0:
                    // year
                    state().salaryScaracficeAmount = Math.min(state().salaryScaracficeAmount, state().baseSalary.a);
                    state().salaryScaracficeAmount = Math.min(limit, state().salaryScaracficeAmount);

                    state().superannuationSacrafice.a = state().salaryScaracficeAmount;
                    break;
                case 1:
                    // month
                    state().salaryScaracficeAmount = Math.min(state().salaryScaracficeAmount, state().baseSalary.m);
                    state().superannuationSacrafice.a = Math.min(limit, state().salaryScaracficeAmount * month2Year);
                    state().salaryScaracficeAmount = state().superannuationSacrafice.a / month2Year;
                    break;
                case 2:
                    // fortnight
                    state().salaryScaracficeAmount = Math.min(state().salaryScaracficeAmount, state().baseSalary.f);
                    state().superannuationSacrafice.a = Math.min(limit, state().salaryScaracficeAmount * fortnight2Year);
                    state().salaryScaracficeAmount = state().superannuationSacrafice.a / fortnight2Year;
                    break;
                case 3:
                    // week
                    state().salaryScaracficeAmount = Math.min(state().salaryScaracficeAmount, state().baseSalary.w);
                    state().superannuationSacrafice.a = Math.min(limit, state().salaryScaracficeAmount * week2Year);
                    state().salaryScaracficeAmount = state().superannuationSacrafice.a / week2Year;
                    break;
                case 4:
                    // percent
                    // reset if the value is > 100
                    state().salaryScaracficeAmount = state().salaryScaracficeAmount > 100 ? 0 : state().salaryScaracficeAmount;

                    state().superannuationSacrafice.a = state().baseSalary.a * (state().salaryScaracficeAmount / 100);
                    state().superannuationSacrafice.a = Math.min(limit, state().superannuationSacrafice.a);
                    state().salaryScaracficeAmount = state().superannuationSacrafice.a * 100 / state().baseSalary.a
                    break;
                default:
                    break;

            }

            // spread the amounts
            spreadAnnualAmounts(state().superannuationSacrafice);
        } else {
            // reset
            state().superannuationSacrafice.a = 0;
            spreadAnnualAmounts(state().superannuationSacrafice);
        }

        // if this is in addition to income, deduct it from baseIncome


        state().warnings.maxSalarySacrafice = (state().salaryScaracficeAmount >= state().baseSalary.a);

        if (Calculator.isDebug) console.log("calculateSalarySacrafice:", state().superannuationSacrafice)


        return Calculator;
    },



    ///////////////////////////////////////////
    // Superannuationm Co-Contribution
    ///////////////////////////////////////////
    // ref: https://www.ato.gov.au/Calculators-and-tools/Host/?anchor=SuperCoContributions&anchor=SuperCoContributions#SuperCoContributions/questions

    // Additional super incentive
    calculateSuperannuationCoContribution: () => {

        if (TaxData().superannuationCocontribution === undefined) {
            state().superannuationCoContribution = 0;
            console.log("No 'superannuationCoContribution' data")
            return Calculator;
        }

        const min = TaxData().superannuationCocontribution.minIncome;
        const max = TaxData().superannuationCocontribution.maxIncome;
        const contribution = state().adjustSuperannuation ? state().additionalSuper : 0;

        // Eligibility
        if (state().adjustedTaxableIncome < max && contribution > 0) {

            const reductionFactor = TaxData().superannuationCocontribution.reductionFactor;
            const maxEntitlement = TaxData().superannuationCocontribution.maxEntitlement;
            const contributionRate = TaxData().superannuationCocontribution.contributionRate;
            const minContribution = TaxData().superannuationCocontribution.minContribution;

            // Co contribution calculation
            let coContribution = maxEntitlement - (reductionFactor * (state().income.a - min));

            // Less than maximum contribution amount
            coContribution = Math.min(maxEntitlement, coContribution);

            // lesset of 50% contribution or co contribution
            coContribution = Math.min(coContribution, contribution * contributionRate);

            // minContribution amount
            coContribution = Math.max(minContribution, coContribution);

            state().superannuationCoContribution = roundToNearestDollar(coContribution);


        } else {
            //reset
            state().superannuationCoContribution = 0;
        }

        return Calculator;
    },


    calculateTotalSuperannuation: () => {
        // add up all the superannuation benefits and guarentees and contributions
        state().totalSuperannuation = { ...state().superannuation }

        state().totalSuperannuation.a += Number(state().superannuationCoContribution);

        if (state().adjustSalaryScaracfice) {
            // employer contributions
            state().totalSuperannuation.a += Number(state().superannuationSacrafice.a);
            state().totalSuperannuation.m += Number(state().superannuationSacrafice.m);
            state().totalSuperannuation.f += Number(state().superannuationSacrafice.f);
            state().totalSuperannuation.w += Number(state().superannuationSacrafice.w);
        }

        if (state().adjustSuperannuation) {
            // employee contributions
            state().totalSuperannuation.a += Number(state().additionalSuper);
        }


        // Prepare the values used to split concessional and non concessional
        const fundContributionCapMultiplier = state().adjustSuperannuation && state().over65 ? 1 : 3;
        let nonConcesssionalCap = 0;
        let concessionalCap = 0;
        let concessionalTax = 0;
        let nonConcessionalExcessTax = 0;
        if (TaxData().superannuation &&
            TaxData().superannuation.nonConcesssionalCap !== undefined &&
            TaxData().superannuation.concessionalCap !== undefined &&
            TaxData().superannuation.concessionalTax !== undefined &&
            TaxData().superannuation.nonConcessionalExcessTax !== undefined
        ) {
            nonConcesssionalCap = TaxData().superannuation.nonConcesssionalCap;
            concessionalCap = TaxData().superannuation.concessionalCap;
            concessionalTax = TaxData().superannuation.concessionalTax;
            nonConcessionalExcessTax = TaxData().superannuation.nonConcessionalExcessTax;

        } else {
            console.log("No 'superannuation.cap...' data", TaxData().superannuation.cap)
        }
        const fundContributionCap = nonConcesssionalCap * fundContributionCapMultiplier
        const nonConcessionalCap = fundContributionCap;//TaxData().superannuation.nonConcesssionalCap;
        // reset all categories
        state().superannuationConcessional = 0;
        state().superannuationConcessionalTax = 0;
        state().superannationNonConcessional = 0;
        state().superannationExcess = 0;
        state().superannationExcessTax = 0;
        state().superannationConcessionalRemaining = concessionalCap;
        state().superannationNonConcessionalRemaining = nonConcessionalCap;
        state().superannationTax = 0;


        // Split the super into concessinal and non concesisonal buckets

        // Super base concessionalCap Warning
        if (state().superannuation.a >= concessionalCap) {
            // Super guarntee has been capped
            state().warnings.superannuation += "Your base superannuation guarantee exceeds the limit of $" + concessionalCap + "</br>";
        }


        if (state().totalSuperannuation.a <= concessionalCap) {
            // all super is concessional
            state().superannuationConcessional = state().totalSuperannuation.a;

        } else if (state().totalSuperannuation.a <= (nonConcessionalCap + concessionalCap)) {
            // max out concessional and add remainder to non concessional
            state().superannuationConcessional = concessionalCap
            state().superannationNonConcessional = state().totalSuperannuation.a - concessionalCap;
            state().warnings.superannuation += "Your have non-concessional superannuation of $" + state().superannationNonConcessional + "</br>";
        } else {

            // excess super
            state().superannuationConcessional = concessionalCap
            state().superannationNonConcessional = nonConcessionalCap;
            state().superannationExcess = state().totalSuperannuation.a - concessionalCap - nonConcessionalCap;
            state().warnings.superannuation += "Your have an excess of non-concessional superannuation of $" + state().superannationExcess + "</br>";
        }

        // remaining limits
        state().superannationConcessionalRemaining = concessionalCap - state().superannuationConcessional;
        state().superannationNonConcessionalRemaining = nonConcessionalCap - state().superannationNonConcessional;

        // tax paid by super fund
        const taxableSuper = Math.min(concessionalCap, state().superannuation.a);
        state().superannuationConcessionalTax = taxableSuper * concessionalTax

        // Excess superannuation taxed at fixed rate (47%);
        state().superannationExcessTax = state().superannationExcess * nonConcessionalExcessTax;

        state().superannuationTax.a = state().superannuationConcessionalTax;
        state().superannuationTax.a = Math.max(0, state().superannuationTax.a);
        spreadAnnualAmounts(state().superannuationTax);

        if (Calculator.isDebug) console.log("calculateTotalSuperannuation -> superannationConcessionalRemaining:", state().superannationConcessionalRemaining, concessionalCap, state().superannuationConcessional)

        return Calculator;
    },


    calculateSuperannuationLISTO: () => {

        if (TaxData().superannuationLISTO === undefined) {
            state().listo = 0;
            console.log("No 'listo' data")
            return Calculator;
        }

        const limit = TaxData().superannuationLISTO.maxIncome;

        let adjustedTaxableIncome = state().baseSalary.a + state().superannuationConcessional;

        // Eligibility
        if (adjustedTaxableIncome <= limit) {
            const rate = TaxData().superannuationLISTO.contributionRate;
            const maxEntitlement = TaxData().superannuationLISTO.maxEntitlement;
            const minContribution = TaxData().superannuationLISTO.minContribution;

            let LISTO = state().superannuation.a * rate;
            LISTO = Math.min(maxEntitlement, LISTO);
            LISTO = Math.max(minContribution, LISTO);

            // LISTO = Math.min( state().superannuationTax.a, LISTO);
            // offset can't be greater than the tax applied
            if ((state().superannuationTax.a - LISTO) < 0) { LISTO = state().superannuationTax.a } // offset cannot be less than incomeTax

            state().listo = LISTO;

        } else {
            // reset
            state().listo = 0;
        }

        return Calculator;
    },




    calculateTotalSuperannuationTax: () => {

        // Deduct LISTO from super tax
        state().superannuationTax.a = state().superannuationTax.a - state().listo;
        state().superannuationTax.a = Math.max(0, state().superannuationTax.a);

        return Calculator;
    },


    calculateTaxableIncome: () => {
        // taxable income
        // subtract deductions from income and save as taxableIncome
        state().income = { a: state().baseSalary.a, m: state().baseSalary.m, f: state().baseSalary.f, w: state().baseSalary.w };

        if (state().adjustSalaryScaracfice && !state().adjustSalaryScaracficeAdditional) {
            state().income.a -= Number(state().superannuationSacrafice.a);
            state().income.m -= Number(state().superannuationSacrafice.m);
            state().income.f -= Number(state().superannuationSacrafice.f);
            state().income.w -= Number(state().superannuationSacrafice.w);
        }

        // add nonconcessional superannaution
        //state().income.a += Number(state().superannationNonConcessional);


        if (state().adjustDeductions === true) {
            state().deductions.a = Number(state().taxableDeductions);
            state().income.a += Number(state().otherTaxableIncome);
        } else {
            state().deductions.a = 0;
        }

        // subtract deductions from income
        state().income.a -= Number(state().deductions.a);
        state().income.m -= Number(state().deductions.m);
        state().income.f -= Number(state().deductions.f);
        state().income.w -= Number(state().deductions.w);

        // ---------------- Adjusted taxable income ------------
        //https://www.ato.gov.au/Individuals/Tax-return/2019/Tax-return/Adjusted-taxable-income-(ATI)-for-you-and-your-dependants-2019/

        state().adjustedTaxableIncome = state().baseSalary.a;

        // reportable super contributions are the contributions made by you or your employee on top of the super guarentee
        //state().adjustedTaxableIncome += Number(state().superannuationConcessional) - Number(state().superannuationGuarantee)
        if (state().adjustSalaryScaracfice && state().adjustSalaryScaracficeAdditional) {
            state().adjustedTaxableIncome += Number(state().superannuationSacrafice.a);
        }

        if (state().adjustDeductions === true) {
            state().adjustedTaxableIncome += Number(state().fringeBenefits * 0.53);
            state().adjustedTaxableIncome += Number(state().fringeBenefitsExempt);
            state().adjustedTaxableIncome += Number(state().otherTaxableIncome);
            state().adjustedTaxableIncome -= Number(state().taxableDeductions);
        }

        state().rebateIncome = state().adjustedTaxableIncome;

        if (Calculator.isDebug) console.log("Calculate Taxable income -> income: ", state().income, "  adjustedTaxableIncome:", state().adjustedTaxableIncome);

        return Calculator;
    },


    ///////////////////////////////////////////
    // Divisioin 293
    ///////////////////////////////////////////
    // Calcualtion of division293 based on annual gross income
    // If your income and concessional contributions (CCs) are more than $250,000 in 2020/21, you may have to pay an additional 15% tax on some or all of your CCs
    calculateDivision293: () => {
        const currentTax = taxData[state().yearIndex]
        // Division 293 was introduced in 2012
        if (!currentTax.division293) {
            state().division293 = 0;
            return Calculator;
        }

        // if you income plus your super is over the 293 threshold
        // Calculate 293 on 15% of the taxable income or concessional superannuation (will invariably be the latter)
        const division293Income = state().adjustedTaxableIncome + state().superannuationConcessional;
        const threshold = currentTax.division293.threshold;
        const rate = currentTax.division293.rate;

        let division293Tax = 0;

        if (division293Income > threshold) {
            // You're liable to pay Division 293 tax if you exceed the income threshold and you have taxable contributions for an income year.
            // If your Division 293 income plus your Division 293 super contributions are greater than the Division 293 threshold, the taxable contributions will be the lesser of the Division 293 super contributions and the amount above the threshold.

            let divisionLiability = division293Income - currentTax.division293.threshold;
            divisionLiability = Math.min(state().superannuationConcessional, divisionLiability);
            division293Tax = (divisionLiability * (rate / 100));
        }

        state().division293 = division293Tax;
        // this should be added to annual tax - do this in the income tax calculation
        return Calculator;
    },


    ///////////////////////////////////////////
    // Income tax
    ///////////////////////////////////////////
    calculateIncomeTax: () => {

        // determine the correct tax function
        let taxFunction = {};
        let taxFunctionPAYG = {};
        if (state().backpacker) {
            if (state().haveTFN) {
                taxFunction = getBackpackerTax;
                taxFunctionPAYG = getPAYGBackpackerTax;
            } else {
                taxFunction = getNonResidentTax;
                taxFunctionPAYG = getPAYGNonResidentTax;
            }
        } else if (state().nonResident) {
            taxFunction = getNonResidentTax;
            taxFunctionPAYG = getPAYGNonResidentTax;
        } else if (state().noTaxFree) {
            taxFunction = getNoTaxFreeThresholdTax;
            taxFunctionPAYG = getPAYGNoTaxFreeThresholdTax;
        } else {
            taxFunction = getIncomeTax;
            taxFunctionPAYG = getPAYGIncomeTax;
        }

        // calculate tax
        state().incomeTax.a = Math.round(taxFunction());

        state().incomeTax.m = taxFunctionPAYG(state().income.m, MONTHLY)
        state().incomeTax.f = taxFunctionPAYG(state().income.f, FORTNIGHTLY)
        state().incomeTax.w = taxFunctionPAYG(state().income.w, WEELKY)

        // if (state().notForProfit) {
        //     zero(state().incomeTax)
        // }

        if (Calculator.isDebug) console.log("Calculate IncomeTax -> incomeTax: ", state().taxableIncome, state().incomeTax);

        return Calculator;
    },


    ///////////////////////////////////////////
    // Income tax - Extrsa witholding
    ///////////////////////////////////////////
    // Only for weeks or fortnight with 53 or 26 payments
    calculateIncomeTaxExtraWitholding: () => {

        // if (TaxData().extraWitholding) {

        if (state().payments.w === 53) {
            let bracket = TaxData().extraWitholding.weekly.brackets;
            state().extraWithholdingTax.w = calculateBracket(state().income.w, bracket, false);
        }

        if (state().payments.f === 27) {
            let bracket = TaxData().extraWitholding.fortnightly.brackets;
            state().extraWithholdingTax.f = calculateBracket(state().income.f, bracket, false);

        }

        // } else {
        //     console.log("No 'extraWitholding' data");
        // }

        return Calculator;
    },



    ///////////////////////////////////////////
    ///////////////////////////////////////////
    calculateStudentLoans: () => {

        if (!state().HELP && !state().SFSS) return Calculator;

        // HELP (HECS)
        let taxFunction = {};
        if (state().HELP || (state().HELP && state().SFSS)) {
            if (state().nonResident || state().noTaxFree) {
                taxFunction = getHELP_noTaxFree
            } else {
                taxFunction = getHELP;
            }

            state().help.a = taxFunction(state().adjustedTaxableIncome);
            //state().help.a = taxFunction(state().income.a);


            state().help.m = taxFunction(state().income.m * month2Year, true, true) / month2Year;
            state().help.f = taxFunction(state().income.f * fortnight2Year, true, true) / fortnight2Year;
            state().help.w = taxFunction(state().income.w * week2Year, true, true) / week2Year;
        }

        // SFSS only
        if (state().SFSS && !state().HELP) {
            if (state().nonResident || state().noTaxFree) {
                taxFunction = getSFSS_noTaxFree
            } else {
                taxFunction = getSFSS;
            }

            state().sfss.a = taxFunction(state().adjustedTaxableIncome);
            // state().help.a = taxFunction(state().assessableTaxableIncome);

            state().sfss.m = taxFunction(state().income.m * month2Year) / month2Year;
            state().sfss.f = taxFunction(state().income.f * fortnight2Year) / fortnight2Year;
            state().sfss.w = taxFunction(state().income.w * week2Year) / week2Year;

        }
        return Calculator;
    },



    ///////////////////////////////////////////
    // Medicare
    ///////////////////////////////////////////
    calculateMedicare: () => {

        // Medicare
        if (state().nonResident || state().backpacker) {
            state().medicareSurcharge = 0;
            return Calculator;
        }

        const family = state().spouse || state().dependants;
        const SAPTO = state().SAPTO;
        const dependantsCount = state().dependants ? Number(state().dependantsCount) : 0;
        const spouseIncome = Number(state().spouseIncome);


        if (SAPTO) {
            state().medicare.a = getMedicareSenior(state().income.a, dependantsCount, state().spouse, spouseIncome);
        } else if (family) {
            state().medicare.a = getMedicareFamaly(state().income.a, dependantsCount, spouseIncome);
        } else {
            state().medicare.a = getMedicare(state().income.a);
        }

        // don't go below 0
        state().medicare.a = Math.max(0, state().medicare.a);
        spreadAnnualAmounts(state().medicare)

        // Medicare Surcharge

        if (family) {
            state().medicareSurcharge = getMedicareSurchargeFamily(state().adjustedTaxableIncome);
        } else {
            state().medicareSurcharge = getMedicareSurchargeSingle(state().adjustedTaxableIncome);
        }


        if (state().hasPrivateHealthcare) {
            state().medicareSurchargeLiability = 0;
        }else{
            state().medicareSurchargeLiability = state().medicareSurcharge;
        }

        let medicareDescription = "";
        if (SAPTO) {
            medicareDescription = "Senior, "
        }

        if (family) {
            medicareDescription += "Family, "
        } else {
            medicareDescription += "Single, "
        }

        if (dependantsCount === 0) {
            medicareDescription += "no dependants. "
        } else if (dependantsCount === 1) {
            medicareDescription += "1 dependant. "
        } else {
            medicareDescription += dependantsCount + " dependants. ";
        }

        // if (!state().hasPrivateHealthcare && state().medicareSurcharge > 0) {
        //     medicareDescription += `Includes surcharge of $${formatMoney(state().medicareSurcharge, 0)}`;
        // }

        state().medicareDescription = medicareDescription;

        return Calculator;

    },

    calculateMedicareAdjustment: () => {
        if (state().nonResident) {
            return Calculator;
        }
        let WLA = calculateMedicareLevyAdjustment(state().income.w, state().spouse, state().spouseIncome, state().dependants, state().dependantsCount);

        if (state().medicareExemption) {
            // exemption is only applied annually
            // state().medicare.w -= state().medicare.w * state().medicareExemptionValue;
            // state().medicare.f -= state().medicare.f * state().medicareExemptionValue;
            // state().medicare.m -= state().medicare.m * state().medicareExemptionValue;
            state().medicare.a -= state().medicare.a * state().medicareExemptionValue;
        }

        state().medicareAdjustment.w = WLA;
        state().medicareAdjustment.f = WLA * 2;
        state().medicareAdjustment.m = (WLA * 13) / 3;
        state().medicareAdjustment.a = WLA * 52;

        // remove silly -0 and +0 
        cleanZeros(state().medicareAdjustment);

        if (state().medicare.a - state().medicareAdjustment.a < 0) state().medicareAdjustment.a = state().medicare.a;
        if (state().medicare.m - state().medicareAdjustment.m < 0) state().medicareAdjustment.m = state().medicare.m;
        if (state().medicare.f - state().medicareAdjustment.f < 0) state().medicareAdjustment.f = state().medicare.f;
        if (state().medicare.w - state().medicareAdjustment.w < 0) state().medicareAdjustment.w = state().medicare.w;

        return Calculator;

    },



    ///////////////////////////////////////////
    // Offsets
    ///////////////////////////////////////////
    calculateOffsets: () => {

        if (state().nonResident || state().noTaxFree || state().backpacker) return Calculator;

        // state().lito = getLITO(state().adjustedTaxableIncome);
        // state().lamito = getLAMITO(state().adjustedTaxableIncome);
        // state().mito = getMITO(state().adjustedTaxableIncome);


        state().lito = getLITO(state().income.a);
        state().lamito = getLAMITO(state().income.a);
        //state().mito = getMITO(state().income.a);

        // if (mature) {
        // 	mawto = getMAWTO(taxableIncome);
        // }


        if (state().SAPTO) {
            //const getSAPTO = (rebateIncome, single, married, separated, combinedIncome) 
            // spouseIncome is weekly income
            state().sapto = getSAPTO(state().rebateIncome, state().spouse, state().spouseSeparated, state().spouseIncome * 52);
        }

        state().offsets.a = state().lito + state().mawto + state().mito + state().lamito + state().sapto;

        // state().offsets.a *= -1;

        // if (state().withhold) {
        //     // apply offset loading to offsets
        //     state().offsets.w = getLITOPAYG(state().offsets.a, state().incomeTax.m * 12, 0) / week2Year;
        //     state().offsets.f = getLITOPAYG(state().offsets.a, state().incomeTax.m * 12, 1) / fortnight2Year;
        //     state().offsets.m = getLITOPAYG(state().offsets.a, state().incomeTax.m * 12, 2) / month2Year;
        // }


        // prevent any annual tax offests from exceeding income tax
        // This is income tax and not gross tax
        // console.log("tax overflow: ", state().incomeTax.a + state().offsets.a)
        if ((state().incomeTax.a - state().offsets.a) < 0) {
            state().offsets.a = state().incomeTax.a;
        }
        return Calculator;
    },

    ///////////////////////////////////////////
    // Gross Tax
    ///////////////////////////////////////////

    calculateGrossTax: () => {

        state().otherTaxesAndLevies = getOther(state().income.a); // this should be a negative amount

        state().otherTax.a = state().help.a + state().sfss.a + state().levies.a + state().otherTaxesAndLevies + state().superannationExcessTax + state().division293;
        state().otherTax.m = state().help.m + state().sfss.m + state().levies.m;
        state().otherTax.f = state().help.f + state().sfss.f + state().levies.f;
        state().otherTax.w = state().help.w + state().sfss.w + state().levies.w;

        // NORMALISE TAX TABLE DATA
        // if the payg figure was using the ATO calculations - deduct medicare and other taxes
        if (state().PAYG) {
            // this needs to be taken from the taxPAYG value as the ATO tax tables include it
            // also includes levies
            if (state().incomeTax.w > 0) state().incomeTax.w -= (state().medicare.w + state().levies.w);
            if (state().incomeTax.f > 0) state().incomeTax.f -= (state().medicare.f + state().levies.f);
            if (state().incomeTax.m > 0) state().incomeTax.m -= (state().medicare.m + state().levies.m);




        }

        state().grossTax.a = state().incomeTax.a + state().extraWithholdingTax.a + state().medicare.a + state().medicareSurchargeLiability - state().medicareAdjustment.a + state().otherTax.a - state().offsets.a;
        state().grossTax.m = state().incomeTax.m + state().extraWithholdingTax.m + state().medicare.m - state().medicareAdjustment.m + state().otherTax.m - state().offsets.m;
        state().grossTax.f = state().incomeTax.f + state().extraWithholdingTax.f + state().medicare.f - state().medicareAdjustment.f + state().otherTax.f - state().offsets.f;
        state().grossTax.w = state().incomeTax.w + state().extraWithholdingTax.w + state().medicare.w - state().medicareAdjustment.w + state().otherTax.w - state().offsets.w;


        // rounding?
        const rounding = true;
        if (rounding) {
            state().grossTax.a = Math.round(state().grossTax.a);
            state().grossTax.m = Math.round(state().grossTax.m);
            state().grossTax.f = Math.round(state().grossTax.f);
            state().grossTax.w = Math.round(state().grossTax.w);
        }

        if (Calculator.isDebug) console.log("Calculate Gross Tax -> grossTax: ", state().grossTax);


        return Calculator;
    },

    ///////////////////////////////////////////
    // Net income
    ///////////////////////////////////////////
    calculateNetIncome: () => {
        state().net.a = state().income.a - state().grossTax.a;
        state().net.m = state().income.m - state().grossTax.m;
        state().net.f = state().income.f - state().grossTax.f;
        state().net.w = state().income.w - state().grossTax.w;

        return Calculator;
    },


}





///////////////////////////////////////////
// Income Aux
///////////////////////////////////////////

const getInputIncome = () => {
    // append any adjustment value to the input income
    return Number(state().salary) + Number(state().adjustment);
}

const calculateOvertime = () => {

    let overtimePerWeek = 0;

    state().overtime.map(ot => {
        if (!ot) return false;
        let hourlyRate = 0;
        if (ot.type === "Hourly") {
            hourlyRate = ot.hourlyRate;
        } else {
            // refer back to the primary hourly rate 
            hourlyRate = state().salary * ot.loadingRate;
        }

        // 
        if (ot.frequency === FREQUENCY_HOURLY[0]) {
            // hours per week
            overtimePerWeek += ot.hours * hourlyRate;
        } else if (ot.frequency === FREQUENCY_HOURLY[1]) {
            // hours per ft
            overtimePerWeek += (ot.hours * hourlyRate) / week2Fortnight
        } else if (ot.frequency === FREQUENCY_HOURLY[2]) {
            // hours per month
            overtimePerWeek += (ot.hours * hourlyRate) / week2Month
        } else if (ot.frequency === FREQUENCY_HOURLY[3]) {
            // hours per year
            overtimePerWeek += (ot.hours * hourlyRate) / week2Year
        }
        return true;
    })

    // Add overtime per week to income
    state().baseSalary.w += overtimePerWeek;
    state().baseSalary.f += overtimePerWeek * week2Fortnight;
    state().baseSalary.a += overtimePerWeek * week2Year;
    state().baseSalary.m += (overtimePerWeek * week2Year) / month2Year;
    return true;

}



///////////////////////////////////////////
// Superannuation Aux
///////////////////////////////////////////

// getSuperannuation(state().baseSalary.a, true, options);
const getSuperannuation = (taxableIncome, subtractive, options) => {
    let superannuation = 0;

    if (TaxData().superannuation) {
        // Use the bracket calculator
        let superBracket = TaxData().superannuation.brackets;

        const inc = TaxData().superannuation.incremental;
        let cap = TaxData().superannuation.cap ? TaxData().superannuation.cap : 0;
        cap = 0; // <--------------------------------------------------------------------------------------- Fix this
        //let income = Math.min(cap, taxableIncome);
        //let superannuation = calculateBracket(taxableIncome, customBracket, inc, subtractive, 0);

        superBracket = [{ from: 0, to: 0, type: "percent", nearest: 1, value: options.rate }];
        superannuation = calculateBracket(taxableIncome, superBracket, inc, subtractive, cap);

    } else {
        console.log("No 'superannuation' data");
    }

    return superannuation;
}



///////////////////////////////////////////
// Income Tax Aux
///////////////////////////////////////////

const getIncomeTax = (overrideIncome) => {

    let tax = 0;

    if (TaxData().tax) {
        const bracket = TaxData().tax.brackets;
        const inc = TaxData().tax.incremental;
        const taxableIncome = overrideIncome ? overrideIncome : state().income.a;
        tax = calculateBracket(taxableIncome, bracket, inc);
    } else {
        console.log("No 'tax' data")
    }
    return tax;
}

const getPAYGIncomeTax = (income, cycle) => {

    if (TaxData().tax.payg === undefined) {
        state().PAYG = false;
        return divideTaxByCycle(getIncomeTax(), cycle);
    }
    state().PAYG = true;
    let tax = calculatePAYG(income, TaxData().tax.payg, cycle);


    return tax
}

const getNoTaxFreeThresholdTax = () => {
    let tax = 0;

    if (TaxData().taxNoFreeThreshold) {
        const bracket = TaxData().taxNoFreeThreshold.brackets;
        const inc = TaxData().taxNoFreeThreshold.incremental;
        tax = calculateBracket(state().income.a, bracket, inc);
    } else {
        console.log("No 'taxNoFreeThreshold' data")
    }
    return tax;
}

const getPAYGNoTaxFreeThresholdTax = (income, cycle) => {
    if (TaxData().taxNoFreeThreshold.payg === undefined) {
        state().PAYG = false;
        return divideTaxByCycle(getNoTaxFreeThresholdTax(), cycle);
    }
    state().PAYG = true;
    return calculatePAYG(income, TaxData().taxNoFreeThreshold.payg, cycle);
}

const getNonResidentTax = () => {
    let tax = 0;
    if (TaxData().taxNonResident) {
        const bracket = TaxData().taxNonResident.brackets;
        const inc = TaxData().taxNonResident.incremental;
        tax = calculateBracket(state().income.a, bracket, inc);
    } else {
        console.log("No 'taxNonResident' data")
    }
    return tax;
}

const getPAYGNonResidentTax = (income, cycle) => {
    if (TaxData().taxNonResident.payg === undefined) {
        state().PAYG = false;
        return divideTaxByCycle(getNonResidentTax(), cycle);
    }
    state().PAYG = true;
    return calculatePAYG(income, TaxData().taxNonResident.payg, cycle);
}

const getBackpackerTax = () => {
    let tax = 0;
    if (TaxData().taxBackpacker) {
        const bracket = TaxData().taxBackpacker.brackets;
        const inc = TaxData().taxBackpacker.incremental;
        tax = calculateBracket(state().income.a, bracket, inc);
    } else {
        console.log("No 'taxBackpacker' data")
    }
    return tax;
}

const getPAYGBackpackerTax = (income, cycle) => {

    // backpacker PAY can be paid as if this is the only payment per year.
    // add comment: If you have paid the Working Holiday Maker more than $37,000 in this income year the above calculation is incorrect. Please refer to the Tax Table Link opens in new window for Working Holiday Makers for instructions.
    if (TaxData().taxBackpacker.payg === undefined) {
        state().PAYG = false;
        // SPECIAL CASE !!
        const bracket = TaxData().taxBackpacker.brackets;
        const inc = TaxData().taxBackpacker.incremental;
        return calculateBracket(income, bracket, inc);

        //return divideTaxByCycle(getBackpackerTax(), cycle);
    }
    state().PAYG = true;
    return calculatePAYG(income, TaxData().taxBackpacker.payg, cycle);
}



///////////////////////////////////////////
///////////////////////////////////////////

const getHELP = (taxableComponent, include, rounding) => {
    let help = 0;
    if (TaxData().help) {
        let bracket = TaxData().help.brackets;
        let inc = TaxData().help.incremental;
        if (rounding) {
            help = calculateBracketATORounding(taxableComponent, bracket, inc);
        } else {
            help = calculateBracket(taxableComponent, bracket, inc);
        }
    } else {
        console.log("No 'help' data")
    }
    return help;
}

const getHELP_noTaxFree = (taxableComponent, include, rounding) => {
    // if(!include) return 0;
    let help = 0;
    if (TaxData().help_noTaxFree) {
        let bracket = TaxData().help_noTaxFree.brackets;
        let inc = TaxData().help_noTaxFree.incremental;
        if (rounding) {
            help = calculateBracketATORounding(taxableComponent, bracket, inc);
        } else {
            help = calculateBracket(taxableComponent, bracket, inc);
        }
    } else {
        help = getHELP(taxableComponent, include, rounding);
    }
    return help;
}

const getSFSS = (taxableComponent, include, rounding) => {
    let sfss = 0;
    if (TaxData().sfss) {
        let bracket = TaxData().sfss.brackets;
        let inc = TaxData().sfss.incremental;
        if (rounding) {
            sfss = calculateBracketATORounding(taxableComponent, bracket, inc);
        } else {
            sfss = calculateBracket(taxableComponent, bracket, inc);
        }
    } else {
        console.log("No 'sfss' data")
    }
    return sfss;
}

const getSFSS_noTaxFree = (taxableComponent, include, rounding) => {
    // if(!include) return 0;
    let sfss = 0;
    if (TaxData().sfss_noTaxFree) {
        let bracket = TaxData().sfss_noTaxFree.brackets;
        let inc = TaxData().sfss_noTaxFree.incremental;
        if (rounding) {
            sfss = calculateBracketATORounding(taxableComponent, bracket, inc);
        } else {
            sfss = calculateBracket(taxableComponent, bracket, inc);
        }
    } else {
        sfss = getSFSS(taxableComponent, include, rounding);
    }
    return sfss;
}



///////////////////////////////////////////
// Medicare Aux
///////////////////////////////////////////
export const getMedicare = (income) => {
    let medicare = 0;
    if (TaxData().medicare) {

        let bracket = TaxData().medicare.brackets;
        let inc = TaxData().medicare.incremental;

        // Use family benefit
        medicare = calculateBracket(income, bracket, inc, false, 0);
    } else {
        console.log("No 'medicare' data")
    }
    return medicare;
}


export const getMedicareFamaly = (taxableComponent, dependantsCount, spouseIncome) => {
    let medicare = 0;
    if (TaxData().medicareFamily) {

        let familyBracket = TaxData().medicareFamily.brackets
        let inc = TaxData().medicareFamily.incremental;

        const debug = false;

        // bracket compensation ( solving the slope of the current bracket with the slop of the next bracket !!)
        let adjustment = 0;
        if (TaxData().medicareFamily.dependants && dependantsCount > 1) {
            const dependantOffset = Number(TaxData().medicareFamily.dependants);
            adjustment = (dependantsCount - 1) * dependantOffset;

            let prevThreshold = 0;
            familyBracket = familyBracket.map((b, index) => {
                const adjustedBracket = { ...b };
                // is there a previous bracket
                adjustedBracket.from = prevThreshold;
                if (index > 0) {
                    // is there another bracket
                    if (index < familyBracket.length - 1) {
                        const nextBracket = familyBracket[index + 1];
                        // solving for the intersection with the next slope
                        const scale = (b.value / nextBracket.value) / ((b.value / nextBracket.value) - 1);
                        adjustedBracket.to = Math.ceil(prevThreshold * scale);
                    }
                } else {
                    // first bracket MUST start on 0
                    adjustedBracket.to = Number(b.to) + adjustment;
                }
                prevThreshold = adjustedBracket.to;
                return adjustedBracket
            });
        }

        let endThreshold = Number(familyBracket[familyBracket.length - 1].from);
        let startThreshold = Number(familyBracket[1].from);
        let assesableIncome = taxableComponent + spouseIncome * 0.8;

        if (debug) {
            console.log(taxableComponent, spouseIncome, startThreshold, (spouseIncome + taxableComponent), endThreshold)
        }


        if ((spouseIncome + taxableComponent) <= startThreshold) {
            if (debug) {
                console.log("Zero")
            }
            return 0
        }

        if ((spouseIncome + taxableComponent) >= endThreshold) {
            // Revert to regular bracket
            if (debug) {
                console.log("Regular medicare bracket")
            }
            medicare = calculateBracket(taxableComponent, TaxData().medicare.brackets, inc, false, 0);


        } else {
            // Use family benefit
            if (debug) {
                console.log("Family medicare bracket", assesableIncome)
            }

            let ShadeOutValue = 27998;

            if (taxableComponent < ShadeOutValue) {
                // Something very odd occurst when the income falls below 28k. It sort of fades out at a faster rate
                // This junk is a way of replicating this

                // // 1 - 7
                // let dependantsSlope = dependantsCount*0.293 + 0.737;
                // let dependantsOffset = dependantsCount*6610 + 15447;

                // // 1 - 5
                // let dependantsSlope = dependantsCount*0.36 + 0.667;
                // let dependantsOffset = dependantsCount*8199 + 13857;

                // 1 - 3
                let dependantsSlope = dependantsCount * 0.32 + 0.71;
                let dependantsOffset = dependantsCount * 7223 + 14835;

                if (dependantsCount === 0) {
                    dependantsSlope = 1.098;
                    dependantsOffset = 23896;
                }

                let spouseRange = taxableComponent * dependantsSlope - dependantsOffset;
                let maxSpouseIncome = endThreshold - taxableComponent - (dependantsCount === 0 ? 4340 : 0);
                let m = 1 / spouseRange;
                let c = m * maxSpouseIncome - 1.0;
                let rate = m * (spouseIncome) - c;
                medicare = calculateBracket(taxableComponent, TaxData().medicare.brackets, inc, false, 0);
                medicare *= rate;

            } else {
                // shadeout = 1;
                medicare = calculateBracketEquation(familyBracket, spouseIncome, taxableComponent);
            }

        }
        medicare = roundToNearestCent(medicare);

    } else {
        console.log("No 'medicareFamily' data")
    }

    return medicare;
}

const calculateBracketEquation = (bracket, spouseIncome, income) => {
    const thresholdIncome = Number(bracket[1].from);
    const slope = Number(bracket[1].value) / 100;
    const value = slope * (income - thresholdIncome + spouseIncome * 0.8)
    // const value = slope * ( income - thresholdIncome + spouseIncome*0.6465 )
    return Math.round(value * 100) / 100;
}


export const getMedicareSenior = (taxableComponent, dependantsCount, spouse, spouseIncome) => {

    let medicare = 0;
    if (TaxData().medicareSenior) {

        let seniorBracket = TaxData().medicareSenior.brackets
        let inc = TaxData().medicareSenior.incremental

        // bracket compensation ( solving the slope of the current bracket with the slop of the next bracket !!)
        let adjustment = 0;
        if (TaxData().medicareSenior.dependants && (dependantsCount > 0 || spouse)) {
            const dependantOffset = Number(TaxData().medicareSenior.dependants);
            const dependantOffsetOne = Number(TaxData().medicareSenior.dependantOne);
            const spouseOnly = Number(TaxData().medicareSenior.spouseOnly);

            if (spouse && dependantsCount === 0) {
                // Spouse only special case
                adjustment = spouseOnly;
            } else {
                // add dependant adjustment
                adjustment = dependantOffsetOne + (dependantsCount - 1) * dependantOffset;
            }

            let prevThreshold = 0;
            seniorBracket = seniorBracket.map((b, index) => {
                const adjustedBracket = { ...b };
                // is there a previous bracket
                adjustedBracket.from = prevThreshold;
                if (index > 0) {
                    // is there another bracket
                    if (index < seniorBracket.length - 1) {
                        const nextBracket = seniorBracket[index + 1];
                        // solving for the intersection with the next slope
                        const scale = (b.value / nextBracket.value) / ((b.value / nextBracket.value) - 1);
                        adjustedBracket.to = Math.ceil(prevThreshold * scale);
                    }
                } else {
                    // first bracket MUST start on 0
                    adjustedBracket.to = Number(b.to) + adjustment;
                }
                prevThreshold = adjustedBracket.to;
                return adjustedBracket
            });
        }

        let assesableIncome = spouseIncome * 0.8 + taxableComponent;
        let topThreshold = Number(seniorBracket[seniorBracket.length - 1].from);
        if ((spouseIncome + taxableComponent) >= topThreshold) {

            medicare = calculateBracket(taxableComponent, TaxData().medicareSenior.brackets, inc, false, 0);
        } else {
            medicare = calculateBracket(assesableIncome, seniorBracket, inc, false, 0);
        }

    } else {
        console.log("No 'medicareSenior' data")
    }

    return medicare;
}




const getMedicareSurchargeSingle = (taxableComponent) => {
    let medicare_surcharge = 0;
    if (TaxData().medicareSurcharge) {
        let bracket = TaxData().medicareSurcharge.brackets;
        let inc = TaxData().medicareSurcharge.incremental;
        medicare_surcharge = calculateBracket(taxableComponent, bracket, inc, false);
    } else {
        console.log("No 'medicareSurcharge' data")
    }
    return medicare_surcharge;
}

const getMedicareSurchargeFamily = (taxableComponent) => {
    let medicare_surcharge = 0;
    if (TaxData().medicareSurchargeFamily) {
        let bracket = TaxData().medicareSurchargeFamily.brackets;
        let inc = TaxData().medicareSurchargeFamily.incremental;
        medicare_surcharge = calculateBracket(taxableComponent, bracket, inc, false);
    } else {
        console.log("No 'medicareSurchargeFamily' data")
    }
    return medicare_surcharge;
}




///////////////////////////////////////////
// Offsets (Aux)
///////////////////////////////////////////
const getLITO = (taxableComponent) => {
    let offset = 0;
    if (TaxData().lito) {
        let bracket = TaxData().lito.brackets;
        let inc = TaxData().lito.incremental;
        offset = calculateBracket(taxableComponent, bracket, inc, false);

        if (offset < 0) offset = 0;
        if ((taxableComponent - offset) < 0) { offset = 1 * taxableComponent } // offset cannot be less than incomeTax
        //offset  = offset > 0 ? -1*offset : 0;
    } else {
        console.log("No 'lito' data")
    }
    return offset;
}

const getLAMITO = (taxableComponent) => {
    // does it apply?
    let offset = 0;
    if (TaxData().lamito) {
        let bracket = TaxData().lamito.brackets;
        let inc = TaxData().lamito.incremental;
        //function calculateBracket(v, b, incremental, subtractive, cap, debug){
        offset = calculateBracket(taxableComponent, bracket, inc, false);

        if (offset < 0) offset = 0;
        if ((taxableComponent - offset) < 0) { offset = 1 * taxableComponent } // offset cannot be less than incomeTax
        //offset  = offset > 0 ? -1*offset : 0;
    } else {
        console.log("No 'lamito' data")
    }
    return offset;
}

// const getMITO = (incomeTax) => {
//     let offset = 0;
//     if (TaxData().mito) {
//         let bracket = TaxData().mito.brackets;
//         let inc = TaxData().mito.incremental;
//         offset = -1 * calculateBracket(incomeTax, bracket, inc, false);
//         if (offset > 0) offset = 0;
//         if ((incomeTax + offset) < 0) { offset = -1 * incomeTax } // offset cannot be less than incomeTax
//         return offset;
//     } else {
//         console.log("No 'mito' data")
//     }
//     return 0;
// }



const getSAPTO = (rebateIncome, married, separated, spouseIncome) => {
    let offset = 0;

    if (TaxData().sapto) {

        let bracket = TaxData().sapto.brackets ? TaxData().sapto.brackets : 0;
        let inc = TaxData().sapto.incremental;
        let income = rebateIncome;

        if (!married && TaxData().sapto.single) {
            bracket = TaxData().sapto.single.brackets;

        }
        if (married && !separated && TaxData().sapto.married) {
            bracket = TaxData().sapto.married.brackets;
        }

        if (married && separated && TaxData().sapto.illness) {
            bracket = TaxData().sapto.illness.brackets;
        }

        offset = 1 * calculateBracket(income, bracket, inc, false, false);
        if (offset < 0) offset = 0;
        if ((income - offset) < 0) { offset = 1 * income } // offset cannot be less than incomeTax
        return offset;
    } else {
        console.log("NO 'sapto'' data!!")
    }

    return 0;
}




// const getMAWTO = (incomeTax) => {
//     let offset = 0;
//     if (TaxData().mawto) {
//         let bracket = TaxData().mawto.brackets;
//         let inc = TaxData().mawto.incremental;

//         offset = calculateBracket(incomeTax, bracket, inc, false, 0);
//         if (offset > 0) offset = 0;
//         if ((incomeTax + offset) < 0) { offset = -1 * incomeTax } // offset cannot be less than incomeTax
//     } else {
//         console.log("No 'mawto' data")
//     }
//     return offset;
// }

const getOther = (incomeTax) => {
    let offset = 0;
    if (TaxData().other) {
        for (let i = 0; i < TaxData().other.length; i++) {
            let bracket = TaxData().other[i].brackets;
            let inc = TaxData().other[i].incremental;
            offset += calculateBracket(incomeTax, bracket, inc, false);
        }
    } else {
        console.log("No 'other' data")
    }
    return offset;
}

// function getLITOPAYG(offset, tax, schedule) {
//     let lito = 0;
//     if (TaxData().lito) {
//         if (Array.isArray(TaxData().lito.paygLoading)) {
//             lito = offset * TaxData().lito.paygLoading[schedule];
//         } else {
//             lito = offset * TaxData().lito.paygLoading;
//         }
//         if (lito > 0) lito = 0;
//         if ((tax + lito) < 0) { lito = -1 * tax } // offset cannot be less than incomeTax
//     } else {
//         console.log("No 'lito' data")
//     }

//     return lito;
// }




///////////////////////////////////////////
// Auxillery
///////////////////////////////////////////

const zero = (obj) => {
    obj.a = obj.m = obj.f = obj.w = 0
}

// get rid of negaive zeros
// -0 and +0 are stupid. Let 0 be 0
const cleanZeros = (obj) => {
    Object.keys(obj).map(k => {
        if (obj[k] === -0) obj[k] = 0;
        return true;
    })
}

const divideTaxByCycle = (tax, cycle) => {
    switch (cycle) {
        case WEELKY:
            return tax / week2Year;
        case FORTNIGHTLY:
            return tax / fortnight2Year;
        case MONTHLY:
            return tax / month2Year;
        default:
            break;
    }
    return tax;
}


export const spreadAnnualAmounts = (obj) => {
    obj.m = obj.a / month2Year;
    obj.f = obj.a / fortnight2Year;
    obj.w = obj.a / week2Year;
    return obj;
}




///////////////////////////////////////////
// PAYG
///////////////////////////////////////////
const calculatePAYG = (income, paygBrackets, cycle) => {

    // reduce income to weekly
    let paygIncome;
    switch (cycle) {
        case MONTHLY:
            // "if the result is an amount ending in 33 cents, add one cent"
            let cents = Math.round(100 * (income - Math.floor(income)));
            if (cents === 33) income += 0.01;
            paygIncome = Math.floor((income * 3) / 13);
            paygIncome += 0.99;
            break;
        case FORTNIGHTLY:
            paygIncome = income / 2;
            paygIncome = Math.floor(paygIncome);
            paygIncome += 0.99;
            break;
        case WEELKY:
        default:
            paygIncome = Math.floor(income);
            paygIncome += 0.99;
            break;

    }

    let a = 0;
    let b = 0;
    // find bracket
    for (let i = 0; i < paygBrackets.length; i++) {
        if (paygIncome < paygBrackets[i].income || paygBrackets[i].income === 0) {
            a = paygBrackets[i].a;
            b = paygBrackets[i].b;
            break;
        }
    }

    let tax = paygIncome * a - b;
    tax = Math.round(tax);

    //convert back to cycle
    switch (cycle) {
        case MONTHLY:
            tax = (tax * 13) / 3
            break;
        case FORTNIGHTLY:
            tax = tax * 2;
            break;
        default:
            break;

    }
    return tax;
}



///////////////////////////////////////////
// Bracket calculations
///////////////////////////////////////////

export const getBracket = (v, b) => {
    if (!v || !b) return false;
    // round to the nearest week
    for (let i = 0; i < b.length; i++) {
        if (v >= b[i].from) {
            if (v < b[i].to || b[i].to === 0) {
                return b[i];
            }
        }
    }
    return b[0];
}


export const calculateBracket = (v, b, incremental, subtractive, cap, debug) => {
    // round to the nearest cent
    return calculateBracketWithRounding(v, b, incremental, subtractive, cap, debug, 0.01);
}

const calculateBracketATORounding = (v, b, incremental, subtractive, cap, debug) => {
    // round to the nearest week
    return calculateBracketWithRounding(v, b, incremental, subtractive, cap, debug, 0.52);
}

const calculateBracketWithRounding = (v, b, incremental, subtractive, cap, debug, rounding) => {
    let r = 0;
    let inc = (incremental === 1) ? true : false;

    rounding = rounding || 0.01;
    // v for value
    // b for brackets
    for (let i = 0; i < b.length; i++) {
        // for each of the brackets
        let from = Number(b[i].from) || 0;
        let to = Number(b[i].to) || 0;
        let nearest = Number(b[i].nearest) || 1;
        let val = Number(b[i].value) || 0;
        let start = Number(b[i].start) || 0;
        let end = Number(b[i].end) || 0;
        let bracketAmount;
        let type = b[i].type;

        if (debug) console.log(" ");
        if (debug) console.log("bracket: from:" + from + " to: " + to + " amount: " + val + " bracket value: " + v);

        if (b[i].incremental !== undefined) {
            // this bracket has an incremental override (medicare)
            if (debug) console.log("incremental bracket! ");
            inc = (b[i].incremental === 1 || b[i].incremental === "true") ? true : false;
        }

        // trigger on active bracket
        if (v >= from) {

            // part bracket > from and < to, otherwise it is complete bracket
            let partBracket = (v <= to || to === 0);

            // calculate the value within this bracket (check cap)
            if (partBracket) {
                bracketAmount = (Math.ceil((v - from) / nearest)) * nearest;
            } else {
                bracketAmount = (Math.ceil((to - from) / nearest)) * nearest;
            }


            if (debug) console.log("Current bracket... type: " + b[i].type + " i:" + i + " from:" + from + " to:" + to + " val: " + v, "  part: ", partBracket, "  inc: ", inc, " -> r:", r);


            // if not incremental only concern is the final brackets
            if (!inc && !partBracket) continue;

            switch (type) {
                case "fixed":
                    r = inc ? r + val : val; // add value of fixed component
                    if (debug) console.log("fixed bracket: ", r);
                    break;

                case "rate":
                    let rateValue = start + (bracketAmount * val / 100);
                    if (rateValue > end && val > 0) rateValue = end; // upper limit on improving rate
                    if (rateValue < end && val < 0) rateValue = end; // lower limit on decending rate
                    r = inc ? r + rateValue : rateValue;
                    if (debug) console.log("rate bracket: ", r);
                    break;

                case "percent":
                    if (partBracket) {
                        if (debug) console.log("part bracket: ", partBracket);

                        // part bracket
                        // if the brackets are incremental take the percentage from the individual bracket
                        // otherwise take a percentage from the total value
                        if (inc) {
                            if (debug) console.log("include bracket... ");
                            // apply cap - (superannuation)
                            if (bracketAmount > cap && cap > 0) bracketAmount = cap;
                            if (debug) console.log("include bracket... " + bracketAmount + " val: " + val);
                            // ATO rounding
                            // let percentValue = rounding * Math.round((bracketAmount * (val / 100)) / rounding);
                            let percentValue = ((bracketAmount * (val / 100)));
                            // subtractive? cap?
                            r += percentValue;
                            if (debug) console.log("include bracket... = ", percentValue, r);

                        } else {
                            // take the full amount not just the partial bracket value
                            let percentValue = rounding * Math.round((v * (val / 100)) / rounding);
                            // this is a superannuation option
                            if (subtractive) { percentValue /= (1 + (val / 100)) }

                            // check cap (superannuation)
                            if (percentValue > cap && cap > 0) { percentValue = cap }

                            r = percentValue;
                            if (debug) console.log("Bracket amount (non inc) " + r);
                        }
                    } else {
                        if (debug) console.log("Full bracket: ", partBracket, " inc: ", inc);
                        if (inc) {

                            if (bracketAmount > cap && cap > 0) bracketAmount = cap;
                            let percentValue = rounding * Math.round((bracketAmount * (val / 100)) / rounding);

                            if (r > cap && cap > 0) r = cap;
                            if (subtractive) { r /= (1 + (val / 100)); }
                            r += percentValue;
                            if (debug) console.log("add full bracket... bracketAmount: ", bracketAmount, percentValue);
                            if (debug) console.log("add full bracket... ", r);

                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
    if (debug) console.log("final amount: ", r, Math.round(r * 100) / 100);
    return Math.round(r * 100) / 100;
}

/*

const checkPermalink = () => {
	let hash = document.location.hash.substr( 1 );

	if ( hash.length !=== 0 ) {
		let components = hash.split("|");
		if(components.length >= 5){
			$("#income").val(components[0]);
			$("#paycycle")[0].selectedIndex = components[1];

			let taxYear = components[2];
			for(let i=0; i<this.state.length; i++){
				if(this.state[i].year === taxYear) {
					$("#taxYearSelect")[0].selectedIndex = i;
					this.state.yearIndex = i;
				}
			}

			$("#superInput").val( components[3] );

			let paycycleInputs = components[4].split(",");
			if(paycycleInputs.length >=5){
				$("#dpw").val( paycycleInputs[0] );
				$("#dpy").val( paycycleInputs[1] );
				$("#hpd").val( paycycleInputs[2] );
				$("#hpw").val( paycycleInputs[3] );
				$("#wpy").val( paycycleInputs[4] );
			}


			let attributes = components[5].split("");
			if(attributes.length >= 6){
				$("input[name='superannuation']")[0].checked = (attributes[0] == "1") ? true : false;
				$("input[name='nonResident']")[0].checked = (attributes[1] == "1") ? true : false;
				$("input[name='taxfree']")[0].checked = (attributes[2] == "1") ? true : false;
				$("input[name='HELP']")[0].checked = (attributes[3] == "1") ? true : false;
				$("input[name='SFSS']")[0].checked = (attributes[4] == "1") ? true : false;
				$("input[name='withhold']")[0].checked = (attributes[5] == "1") ? true : false;
			}
		}
		// clear hash
		//window.location.hash = "";
		return true;
	}

	return false;
}

const updatePermalink = () => {
	//income|paycycle|taxYear|super|[inputs]|[options]
	let link = "#";
	link += $("#income").val() + "|";
	link += $("#paycycle")[0].selectedIndex + "|";
	link += $("#taxYearSelect").val() + "|";
	link += $("#superInput").val() + "|";

	link += $("#dpw").val() + ",";
	link += ($("#dpy").val() ? $("#dpy").val() : "0") + ","; // depricated
	link += $("#hpd").val() + ",";
	link += ($("#hpw").val() ? $("#hpw").val() : "0") + ","; // depricated
	link += $("#wpy").val() + "|";

	let attributes = "";
	attributes +=  $("input[name='superannuation']").is(":checked")? "1":"0";
	attributes +=  $("input[name='nonResident']").is(":checked")? "1":"0";
	attributes +=  $("input[name='taxfree']").is(":checked")? "1":"0";
	attributes +=  $("input[name='HELP']").is(":checked")? "1":"0";
	attributes += $("input[name='SFSS']").is(":checked")? "1":"0";
	attributes +=  $('input[name="withhold"]').is(':checked')? "1":"0";

	link += attributes;
	//window.location.hash = link;

	$("#permalink")[0].href=link;
}

*/

// Number.prototype.formatMoney = function (c, d, t) {
//     var n = this, c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
//     return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
// };








