import React, { useEffect, useState, useContext } from 'react'
import {
    Grid,
    Typography,
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

import { PriceIntegerFormat } from '../../common/customtFormatters';
import { useCalculator } from '../../../state/calculatorState';
import { Calculator } from '../../../core/Calculator';

import ComponentWrapper from '../../common/ComponentWrapper';
import { StyledTextInput } from '../../common/CustomComponents';
import { DistributionChart } from './DistributionChart';
import { DistributionTable } from './DistributionTable';
import { useLayout } from '../../../state/layoutState';
import { taxDistribution } from '../../../core/taxDistribution';

const useStyles = makeStyles(theme => {
    return {
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },

        image: {
            width: '100%',
            paddingBottom: 20,
        },

        tabs: {
            flexGrow: 1,
            padding: 20,
        },

        tab: {
            padding: 20,
        },

        contentWrapper: {
            padding: 20,
        }
    }
});

export const DistributionContext = React.createContext();

export const DistributionComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    // const [{ baseSalary, grossTax}, ] = useCalculator();
    const [calculator, ] = useCalculator();

    const { income, grossTax } = calculator;

    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [{ expandDistribution },] = useLayout();
    const expanded = expandDistribution && !small;
    const [redrawCount, setRedrawCount] = useState(0);

    const [salary, setSalary] = useState(income.a);
    const [tax, setTax] = useState(grossTax.a);

    const updateSalary = (salary) => {

        // calculate income tax
        const state = { ...calculator}
        state.income.a = Number(salary);

        console.log(state.baseSalary);

        const result = Calculator.setState(state)
          .calculateIncomeTax()
          .calculateGrossTax()

          console.log("tax: ", result.state.grossTax.a)
        setSalary(salary)
        setTax(result.state.grossTax.a)
    }

    
    useEffect(() => {
        const timer = setTimeout(() => {
            setRedrawCount(1)
        }, 100);
        return () => clearTimeout(timer);
    }, []);
    
    const paddingStyle = { paddingTop: 25, paddingBottom: 10, paddingLeft: small ? 10 : 15, paddingRight: small ? 10 : 20 };

    return (
        <ComponentWrapper title="Tax Expenditure">
             <DistributionContext.Provider value={{ salary, tax, updateSalary}}>
                   
            <IncomeInput />
            <div className={classes.contentWrapper} style={paddingStyle} >
                {expanded ? (
                    <Grid container >
                        <Grid item sm={12} md={12}>
                            
                            < DistributionTable key={"dist"} />
                        </Grid>
                    </Grid>
                ) : (
                        <Grid container >

                            <Grid item sm={12} md={6} >
                                <DistributionChart redrawCount={redrawCount} />
                            </Grid>

                            <Grid item sm={12} md={6} style={{ paddingLeft: (small ? 0 : 20) }}>
                                <DistributionTable key={"dist"} />
                            </Grid>

                        </Grid>
                    )}

            </div>
            </DistributionContext.Provider>
        </ComponentWrapper>
    )
}


const IncomeInput = () => {
    const classes = useStyles();
    
    const { salary, tax, updateSalary } = useContext(DistributionContext);

    const handleChangeSalary = () => event => {
        if (document.activeElement.className !== event.target.className) return;
        let newValue = event.target.value;
        updateSalary(newValue);
    }


    let contribution = 100 * (tax/ (taxDistribution.total * 1000000));

    contribution = Math.round(contribution*1000000)/1000000;

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{paddingBottom: 20}}>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" style={{paddingBottom: 20}}>
            <Typography style={{paddingRight:20}} variant="h3">{`Your Salary: `}</Typography>
            <StyledTextInput
                className={classes.input}
                label=" "
                value={Number(salary)}
                variant="filled"
                InputProps={{
                    inputComponent: PriceIntegerFormat,
                }}
                onChange={handleChangeSalary()}
                id="distributionSalary"
            />
            <Box style={{ width: 10 }} />
            <Typography className={classes.label} variant="h3">{`per year`}</Typography>
        </Box>
        <Typography style={{paddingRight:20}} variant="h3">{`You contribute ${contribution}% to the national economy `}</Typography>
        </Box>
    )
}