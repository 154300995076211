import React, { useState, useEffect, useContext } from 'react'
import {
    Grid,
    Select,
    InputLabel,
    makeStyles,
    useTheme,
    Typography,
    Collapse,
    Tooltip,
    Box,
    useMediaQuery,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PriceFormat, PriceIntegerFormat } from '../../common/customtFormatters';
import { useCalculator, payOptions, DISPATCH_UPDATE } from '../../../state/calculatorState';
import { useLayout, UPDATE_LAYOUT } from '../../../state/layoutState';
import { StyledFormControl } from '../../common/CustomComponents';
import { SummaryContext } from './Summary';
import { taxData } from '../../../core/calculatorData';

const useStyles = makeStyles((theme) => {
    return {
        image: {
            width: '100%',
            paddingBottom: 20,
        },
        mainNavBar: {
            cursor: "pointer",
            color: theme.palette.text.primary,
        },

        fadeText: {
            opacity: 0.75,
            // fontWeight:400,
        },
        superscript: {
            opacity: 0.7,
            fontSize: 11,
            paddingLeft: 4,
        },
        subMinor: {
            paddingTop: 0,
            paddingBottom: 0,
            opacity: 0.7,
            fontWeight:400,
            flexDirection: "row"
        },
        subMajor: {
            paddingTop: 0,
            paddingBottom: 0,
            opacity: 1.0,
        },

        subNavBar: {
            marginTop: 2, marginBottom: 2, marginLeft: 15, width: 4, borderRadius: 2.4,
        },

        mainLabel:{
            paddingTop:2,
            fontSize:16,
        },

        subNavInfo: {
            marginLeft: -20,
            color: theme.palette.text.primary,
        }
    }
});


const SummaryTableExpanded = () => {
    let [{ payOption, payments, YTD, warnings },] = useCalculator();
    let [{ ytd },] = useLayout();
    const summaryData = useContext(SummaryContext);

    // default results range to be the same as the income PayOption
    const [, setResultRange] = useState(payOptions[payOption].casual ? 0 : payOption);

    useEffect(() => {
        // Update the document title using the browser API
        if (!payOptions[payOption].casual) {
            setResultRange(payOption)
        }
    }, [payOption]);

    return (
        <Grid container>
            <Grid item xs={12}>
                {SummaryHeading(payments, YTD, ytd, warnings)}
                {SummaryPay(summaryData)}
                {summaryData.data.map(data => Summary(data, summaryData.ytd))}
                {SummaryReturn(summaryData.return, payOptions[payOption].type)}
            </Grid>
        </Grid>
    )
}


const SummaryHeading = (payments, YTD, ytd, warnings) => {
    const classes = useStyles();

    const [{
        yearIndex,
    }, dispatch] = useCalculator();

    let weeklyExtra = ``;
    let fortnighltyExtra = ``;
    let monthlyExtra = ``;
    let annuallyExtra = ``;

    const handleChangeYear = () => event => {
        return (
            dispatch({
                type: DISPATCH_UPDATE,
                year: taxData[event.target.value].year,
                yearIndex: event.target.value,
            })
        )
    }


    if (ytd) {
        let wk = `${YTD.w}/${payments.w}`
        let ft = `${YTD.f}/${payments.f}`
        let mt = `${YTD.m}/${payments.m}`

        if (warnings.extraPayment) {
            wk += '*';
            ft += '*';
        }
        weeklyExtra = <Typography className={classes.superscript} variant="h4">{wk}</Typography>
        fortnighltyExtra = <Typography className={classes.superscript} variant="h4">{ft}</Typography>
        monthlyExtra = <Typography className={classes.superscript} variant="h4">{mt}</Typography>
        annuallyExtra = <Typography className={classes.superscript} variant="h4">Full year</Typography>

    } else if (warnings.extraPayment) {
        weeklyExtra = <Typography className={classes.superscript} variant="h4">{`${payments.w} weeks*`}</Typography>
        fortnighltyExtra = <Typography className={classes.superscript} variant="h4">{`${payments.f} fortnights*`}</Typography>
    }



    return (
        <Grid container style={{ paddingBottom: 5, paddingRight: 10 }} alignItems="flex-end">
            <Grid item xs={4} >
                <div >
                    <StyledFormControl variant="filled" style={{ paddingBottom: 10, paddingLeft: 5, width: '100%' }}>
                        <InputLabel htmlFor="year">Tax Year</InputLabel>
                        <Select
                            native
                            variant="filled"
                            id="year"
                            onChange={handleChangeYear()}
                            value={yearIndex}
                        >
                            {taxData.map((option, index) => (
                                <option
                                    key={option.year + " " + index}
                                    data={option.year}
                                    value={index}
                                >
                                    {`${Number(option.year) - 1} - ${Number(option.year) - 2000}`}
                                </option>
                            ))}
                        </Select>
                    </StyledFormControl>
                </div>
            </Grid>
            <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end" flexDirection='column'>
                    <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 15 }}>Weekly</Typography>
                    {weeklyExtra}
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end" flexDirection='column'>
                    <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 15 }}>Fortnightly</Typography>
                    {fortnighltyExtra}
                </Box>
            </Grid><Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end" flexDirection='column'>
                    <Typography className={classes.fadeText} variant="h4" style={{ fontSize: 15 }}> Monthly </Typography>
                    {monthlyExtra}
                </Box>
            </Grid><Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end" flexDirection='column'>
                    <Typography variant="h4" style={{ fontSize: 15 }}> Annually </Typography>
                    {annuallyExtra}
                </Box>
            </Grid>
        </Grid>
    )
}




const SummaryPay = (summaryData) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));


    const data = summaryData.summary;
    const title = summaryData.ytd ? "Year to date" : data.label;
    const value = summaryData.ytd ? data.data.ytd : data.data.complete;

    const bgStyle = { backgroundImage: `linear-gradient(to bottom right, ${data.colorB}, ${data.color})` };


    return (
        <div style={{ paddingBottom: 2 }} >
            <Box display="flex" justifyContent="flex-end" style={bgStyle} >
                <Grid container style={{ margin: 20, alignItems: "center", color: theme.palette.primary.contrastText }}>
                    <Grid item xs={4}>
                        <Box display="flex" flexDirection="row" flexGrow={1}>
                            <Typography variant="h1" style={{ color: theme.palette.primary.contrastText }} >
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} > <ValueItem value={value.w} integerDollars={small} /> </Grid>
                    <Grid item xs={2} > <ValueItem value={value.f} integerDollars={small} /> </Grid>
                    <Grid item xs={2} > <ValueItem value={value.m} integerDollars={small} /> </Grid>
                    <Grid item xs={2} > <ValueItem value={value.a} integerDollars={small}  /> </Grid>

                </Grid>
            </Box>
        </div>
    )
}


const SummaryReturn = (data, type) => {
    const theme = useTheme();

    if (type !== 'fulltime') return false;

    const bgColor = data.color
    const title = data.label
    const value = data.data.complete;

    return (
        <div style={{ paddingBottom: 2 }} >
            <Box display="flex" justifyContent="flex-end" style={{ backgroundColor: bgColor }} >
                <Grid container style={{ margin: 10, alignItems: "center", color: theme.palette.primary.contrastText }}>
                    <Grid item xs={4}>
                        <Box display="flex" flexDirection="row" flexGrow={1}>
                            <Typography variant="h4" style={{ paddingLeft: 40, fontSize: 14, color: theme.palette.primary.contrastText }} >
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >  </Grid>

                    <Grid item xs={2} > <ValueItem value={value.a} integerDollars showZero/> </Grid>

                </Grid>
            </Box>
        </div>
    )
}


const Summary = (data, ytd) => {
    const classes = useStyles();

    let [layout, layoutDispatch] = useLayout();
    const target = data.id;
    let expanded = layout[target];

    const toggleVisibility = () => {
        data = {};
        data[target] = !expanded
        layoutDispatch({ type: UPDATE_LAYOUT, data })
    }

    const bgColor = data.color

    return (
        <div style={{ paddingBottom: 2 }} key={"Summary_Expanded_" + data.label}>
            {SummaryMainItem(data, ytd, expanded, toggleVisibility)}
            <Collapse in={expanded}>
                <Box display="flex">
                    <Box className={classes.subNavBar} style={{ backgroundColor: bgColor }} />
                    <Box className={classes.subNavInfo} display="flex" flexDirection="column" flexGrow={1} >
                        {data.sub.map(item => SummarySubItem(item, ytd))}
                    </Box>
                </Box>
            </Collapse>
        </div>
    )
}


const SummaryMainItem = (data, ytd, isOpen, toggleVisibility) => {
    const theme = useTheme();
    const classes = useStyles();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const bgColor = theme.palette.background.overlay
    const txtColor = theme.palette.primary.contrastText;
    const value = ytd ? data.data.ytd : data.data.complete;

    const valueStyle = { display: "flex", flexDirection: "row", justifyContent: "flex-end" };

    return (
        <Tooltip title={isOpen ? "" : "Click to show more"} placement="top" arrow key={"Summary_Expanded_Main_" + data.label}>
            <Box className={classes.mainNavBar} display="flex" justifyContent="flex-end" style={{ backgroundColor: bgColor }} onClick={toggleVisibility}>
                <Grid container style={{ margin: 10, marginTop:7, marginBottom:7, alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Box display="flex" flexDirection="row" flexGrow={1}>
                            <div style={{ backgroundColor: data.color, borderRadius: 10, minWidth: 20, height: 20 }} />
                            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            <Typography variant="h3" className={classes.mainLabel}  style={{ color: txtColor }} >
                                {data.label}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} className={classes.fadeText} style={valueStyle}><ValueItem value={value.w} integerDollars={small} addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} /> </Grid>
                    <Grid item xs={2} className={classes.fadeText} style={valueStyle}><ValueItem value={value.f} integerDollars={small} addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} /> </Grid>
                    <Grid item xs={2} className={classes.fadeText} style={valueStyle}><ValueItem value={value.m} integerDollars={small} addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} /> </Grid>
                    <Grid item xs={2} style={valueStyle} > <ValueItem value={value.a} integerDollars={small}  addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} showZero /> </Grid>

                </Grid>
            </Box>
        </Tooltip>
    )
}

export const ValueItem = ({ value, integerDollars, addBrackets, addSquareBrackets, showZero }) => {
    if (!showZero && value === 0) {
        return (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Typography variant="h4" style={{ fontSize: 15 }}>-</Typography>
            </Box>
        );
    }

    let prefix = "";
    let postfix = "";
    prefix = addBrackets ? "(" : prefix;
    prefix = addSquareBrackets ? "[" : prefix;
    postfix = addBrackets ? ")" : postfix;
    postfix = addSquareBrackets ? "]" : postfix;

    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
            {integerDollars ? (
                <Typography variant="h4" style={{ fontSize: 15 }}>{prefix}{PriceIntegerFormat({ value, displayType: "text" })}{postfix}</Typography>
            ) : (
                    <Typography variant="h4" style={{ fontSize: 15 }}>{prefix}{PriceFormat({ value, displayType: "text" })}{postfix}</Typography>
                )}

        </Box>
    )
}



const SummarySubItem = (data, ytd) => {
    const classes = useStyles();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const subNavMarker = {
        alignItems: "center",
        borderRadius: 5,
        marginLeft: 30,
        minWidth: 10,
        height: 10,
        backgroundColor: data.color
    };

    if (data.hidden) return false;
    const value = ytd ? data.data.ytd : data.data.complete;
    let a = value.a ? value.a : Number(value) | 0;
    let m = value.m ? value.m : 0;
    let f = value.f ? value.f : 0;
    let w = value.w ? value.w : 0;

    if (data.negative) {
        a *= -1;
        m *= -1;
        f *= -1;
        w *= -1;
    }

    return (
        <Box display="flex" flexGrow={1} justifyContent="flex-end" style={{}} key={"Summary_Expanded_Item_" + data.label}>

            <Grid container style={{ alignItems: "center", margin: 8, marginBottom: 6, marginTop: 6 }}>
                <Grid item xs={4}>
                    <Box display="flex" style={{ overflow: "hidden", alignItems: "center" }} >
                        <Box style={subNavMarker} />
                        <Box display="flex" flexDirection="column" flexGrow={1} style={{ paddingLeft: 10 }} >
                            <Typography variant="h5" style={{ color: theme.palette.primary.contrastText, fontSize: 16 }} >
                                {data.label}
                            </Typography>
                            <Typography variant="body2" style={{ opacity: 0.8 }}>
                                {data.comment}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2} className={classes.subMinor}><ValueItem value={w} integerDollars={small} addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} /></Grid>
                <Grid item xs={2} className={classes.subMinor}>  <ValueItem value={f} integerDollars={small} addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} /> </Grid>
                <Grid item xs={2} className={classes.subMinor}> <ValueItem value={m} integerDollars={small} addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} /> </Grid>
                <Grid item xs={2} className={classes.subMajor}> <ValueItem value={a} integerDollars={small}  addBrackets={data.roundBrackets} addSquareBrackets={data.squareBrackets} showZero /> </Grid>

            </Grid>
        </Box>
    )
}

export default SummaryTableExpanded;