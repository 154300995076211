import React from "react";
import {
    Box,
} from '@material-ui/core';

import { useCalculator } from '../../../state/calculatorState';
import { compileNotes } from '../../../utils/utils';

export const SummaryNotes = () => {
    const [calculator,] = useCalculator();
    const notes = compileNotes(calculator)
    const htmlNotes = notes.join("<br />");

    return (
        <Box >
            <Box display="flex" flexGrow={1} style={{ flexDirection:"row"}}>
                {/* <Typography variant="body2"> */}
                <div dangerouslySetInnerHTML={{ __html: htmlNotes }} />
                {/* </Typography> */}
            </Box>
        </Box>
    );
}
